import { Debugger, Expander, FileUpload, Footer, Form } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import {
    resetState,
    setIsEditable,
    setIsSubmitted,
    setShouldUpdateNewDocuments,
} from 'modules/LegalEntityCreation/context/actions';

import ActionPanel from './components/ActionPanel/ActionPanel';
import { DocumentSections } from 'modules/LegalEntityCreation/context/model';
import { LegalEntityForeignRegistrationUpdateSectionProps } from './model';
import Registrations from './components/Registrations/Registrations';
import RejectReason from 'modules/LegalEntityCreation/Shared/RejectReason';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { SECTION_NAME_SEPARATOR } from 'components/controls/FileUpload/models';
import { getDocumentTypesForTreeView } from 'modules/LegalEntityCreation/LegalEntityDocumentPreparation/utils';
import { isEditable } from 'modules/LegalEntityCreation/utils';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useDocumentUpload from 'hooks/use-document-upload';
import { useLegalEntityRegistrationUpdate } from 'modules/LegalEntityCreation/context/Provider';

export default function LegalEntityForeignRegistrationUpdate(props: LegalEntityForeignRegistrationUpdateSectionProps) {
    const { data, mode, workItem } = props;
    const context = useLegalEntityRegistrationUpdate();

    const {
        state: {
            documents: { sections },
            canSave,
            shouldUpdateNewDocuments,
        },
        dispatch,
    } = context;

    const {
        state: { legalEntityOId: entityOid },
    } = useCreationViewContext();

    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const { updateDocuments } = useDocumentUpload();

    const treeViewDocumentTypes = getDocumentTypesForTreeView(SECTION_NAMES.FOREIGN_REGISTRATION_UPDATE);
    const showDocumentsInReadOnlyTreeView = mode === 'Readonly' && workItem?.workItemStatus !== 'InProcess';

    useEffect(() => {
        if (data && shouldUpdateNewDocuments) {
            const updated = Object.keys(sections).reduce((result, key) => {
                const section = sections[key];
                if (key.startsWith(SECTION_NAMES.FOREIGN_REGISTRATION_UPDATE)) {
                    const separator = key.lastIndexOf(SECTION_NAME_SEPARATOR);
                    const [, suffix] = [key.slice(0, separator), key.slice(separator + 1)];
                    const index = parseInt(suffix);
                    section.pendingDocuments?.forEach(
                        (document) => (document.taxRegistration = data.RegistrationData![index].key)
                    );
                }
                return { ...result, [key]: section };
            }, {} as DocumentSections);
            updateDocuments(updated, entityOid).then((_) => {
                if (canSave) {
                    if (isSubmitClicked) {
                        setIsSubmitted(dispatch, true);
                        setIsSubmitClicked(false);
                    } else {
                        resetState(dispatch, data);
                        setIsEditable(dispatch, isEditable(mode));
                        setShouldUpdateNewDocuments(dispatch, false);
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Foreign_Registration} />
            <Form>
                <Registrations mode={mode} />
                {showDocumentsInReadOnlyTreeView && (
                    <Expander
                        header='Existing Documents'
                        requestStep={RequestStepsView.Foreign_Registration}
                        content={
                            <FileUpload
                                entityId={entityOid}
                                sectionName={SECTION_NAMES.FOREIGN_REGISTRATION_UPDATE}
                                context={context}
                                keepDocumentTypes
                                showStampedDocuments={false}
                                showDocumentsInReadOnlyTreeView
                                treeViewDocumentTypes={treeViewDocumentTypes}
                                standaloneCreateMode={props.mode == 'Create'}
                            />
                        }
                    />
                )}
                <RejectReason mode={mode} taskType='UpdateRequestTaskType' tasks={workItem?.tasks} />
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel {...props} />
                </Footer>
            )}
        </>
    );
}
