import { validateCompleted, validateRequiredField } from './utils';

import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityDissolutionFinance } from '../context/Provider';

export default function useDissolutionFinanceValidation() {
    const {
        state: {
            data: {
                values: {
                    isAuditRequired,
                    auditFinalBilling,
                    auditFinalPaymentTimeline,
                    cashPositionSettleReceivables,
                    cashPositionDeterminePaydown,
                    cashPositionProjectCash,
                    liabilities,
                    finalPaymentApproved,
                    finalPaymentDistributed,
                    cashPositionProjectCodingType,
                    cashPositionProjectCoding,
                },
            },
            version,
        },
        dispatch,
    } = useLegalEntityDissolutionFinance();

    useEffect(() => {
        updateValidation(dispatch, {
            auditFinalBilling: validateCompleted(auditFinalBilling, { isRequired: isAuditRequired }),
            auditFinalPaymentTimeline: validateCompleted(auditFinalPaymentTimeline, { isRequired: isAuditRequired }),
            cashPositionSettleReceivables: validateCompleted(cashPositionSettleReceivables),
            cashPositionDeterminePaydown: validateCompleted(cashPositionDeterminePaydown),
            cashPositionProjectCash: validateCompleted(cashPositionProjectCash),
            cashPositionProjectCoding: validateRequiredField(cashPositionProjectCoding, {
                isRequired: !!cashPositionProjectCodingType,
            }),
            liabilities: validateCompleted(liabilities),
            finalPaymentApproved: validateCompleted(finalPaymentApproved),
            finalPaymentDistributed: validateCompleted(finalPaymentDistributed),
        });
    }, [
        dispatch,
        version,
        isAuditRequired,
        auditFinalBilling,
        auditFinalPaymentTimeline,
        cashPositionSettleReceivables,
        cashPositionDeterminePaydown,
        cashPositionProjectCash,
        cashPositionProjectCodingType,
        cashPositionProjectCoding,
        liabilities,
        finalPaymentApproved,
        finalPaymentDistributed,
    ]);
}
