import { InterestTypes, LegalTypes } from 'models/LegalEntityRequest/DefaultValues';
import { VALIDATION_MESSAGES, validateNumberField, validateRequiredField } from './utils';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { OwnershipDetails } from '../LegalEntityOwnershipInformation/model';
import { ValidationErrors } from '../context/model';
import { cleanObjectValues } from '../context/utils';
import { isEmpty } from '../../../Utilities/Validations';
import { isNil } from 'lodash';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityOwnerShipInformation } from '../context/Provider';

type Props = {
    legalType?: number;
};

export default function useOwnershipDetailsValidation({ legalType }: Props) {
    const {
        state: {
            data: {
                values: { ownershipDetails = [] },
            },
            version,
        },
        dispatch,
    } = useLegalEntityOwnerShipInformation();

    const [validations, setValidations] = useState<ValidationErrors<OwnershipDetails>[]>([]);

    const validateInterestType = useCallback(
        (index: number) => {
            const interestTypes =
                ownershipDetails?.reduce((result: number[], current) => {
                    const { isDeleted, interestType } = current;
                    return !isDeleted && interestType ? [...result, interestType] : result;
                }, []) ?? [];

            const { interestType } = ownershipDetails[index];

            if (isNil(interestType) && !interestTypes?.includes(SoleMember)) {
                return REQUIRED_FIELD;
            }

            switch (legalType) {
                case SCSp:
                case LimitedPartnership: {
                    return interestTypes.includes(LimitedPartner) && !interestTypes.includes(GeneralPartner)
                        ? GENERAL_TYPE_REQUIRED
                        : undefined;
                }
                case LimitedLiabilityCompany: {
                    const onlySoleMember = interestTypes.length > 1 && interestTypes.includes(SoleMember);
                    return onlySoleMember ? INTEREST_TYPE_SHOULD_BE_ONLY_SOLE_MEMBER : undefined;
                }
            }
        },
        [legalType, ownershipDetails]
    );

    const hasOwnershipDetailsAnyData = useMemo(
        () =>
            ownershipDetails
                ?.filter(({ isDeleted }) => !isDeleted)
                .some(
                    (details) =>
                        Object.keys(cleanObjectValues(details)).filter((key) => !['isDeleted', 'key'].includes(key))
                            .length > 0
                ),
        [ownershipDetails]
    );

    const validateOwnership = useCallback(
        (index) => {
            const { ownerShip } = ownershipDetails[index];
            const error = validateNumberField({ value: ownerShip, isRequired: false });
            if (error) {
                return error;
            }
            const totalOwnership =
                ownershipDetails?.reduce((result, { isDeleted, ownerShip }) => {
                    return !isDeleted ? result + parseFloat(ownerShip ?? '0') : result;
                }, 0) ?? 0;

            if (totalOwnership > 100) {
                return TOTAL_OWNERSHIP_EXCEEDS;
            }
        },
        [ownershipDetails]
    );

    const validateParentEntity = useCallback(
        (index: number) => {
            const { parentEntity } = ownershipDetails[index];
            if (isNil(parentEntity)) {
                return REQUIRED_FIELD;
            }

            const hasOnlyShareholderInterestType = ownershipDetails.every(
                (details) => !details.interestType || details.interestType === InterestTypes.Shareholder
            );

            if (hasOnlyShareholderInterestType) {
                return undefined;
            }

            return ownershipDetails.some(
                (details, detailsIndex) =>
                    details.parentEntity === parentEntity && index !== detailsIndex && !details.isDeleted
            )
                ? INVALID_UNIQUE_OWNERSHIP_PARENT
                : undefined;
        },
        [ownershipDetails]
    );

    const validateOwnershipName = useCallback(
        (index: number) => {
            const { ownershipName } = ownershipDetails[index];
            if (isNil(ownershipName)) {
                return REQUIRED_FIELD;
            }

            return ownershipDetails.some(
                (details, detailsIndex) =>
                    details.ownershipName?.toLocaleLowerCase()?.trim() === ownershipName?.toLocaleLowerCase()?.trim() &&
                    index !== detailsIndex &&
                    !details.isDeleted
            )
                ? INVALID_UNIQUE_OWNERSHIP_NAME
                : undefined;
        },
        [ownershipDetails]
    );

    const hasEmptyElement = useMemo(
        () =>
            validations.some((validation) => {
                isNil(validation.interestUnit) &&
                    isNil(validation.interestClass) &&
                    isNil(validation.interestType) &&
                    isNil(validation.numberOfUnits) &&
                    isNil(validation.ownerShip) &&
                    isNil(validation.parentEntity) &&
                    isNil(validation.ownershipName) &&
                    isNil(validation.reportingType) &&
                    isNil(validation.votingRight);
            }),
        [validations]
    );

    const validationMessage = useMemo(() => {
        if (hasEmptyElement) {
            return VALIDATION_MESSAGES.COMBINED_ERROR;
        }

        const errors = Array.from(
            new Set(
                validations
                    .map((validation) => Object.values(validation))
                    .flat()
                    .filter(Boolean)
            )
        );

        if (isEmpty(errors)) {
            return;
        }
        const result = errors.filter((error) => error !== REQUIRED_FIELD).length > 0 ? COMBINED_ERROR : REQUIRED_FIELD;
        return result;
    }, [hasEmptyElement, validations]);

    useEffect(() => {
        if (isEmpty(ownershipDetails)) {
            setValidations([]);
            return;
        }

        setValidations(
            ownershipDetails.map((details, index) => {
                const { isDeleted, numberOfUnits } = details;

                return isDeleted
                    ? {}
                    : {
                          parentEntity: validateParentEntity(index),
                          ownershipName: validateOwnershipName(index),
                          interestType: validateInterestType(index),
                          numberOfUnits: validateNumberField({ value: numberOfUnits, isRequired: false }),
                          ownerShip: validateOwnership(index),
                      };
            })
        );
    }, [ownershipDetails, version, dispatch, validateInterestType, validateOwnership]);

    useEffect(() => {
        if (isEmpty(ownershipDetails?.filter(({ isDeleted }) => !isDeleted))) {
            updateValidation(dispatch, { ownershipDetails: EMPTY_OWNERSHIP_DETAILS });
            return;
        }

        if (isEmpty(validations)) {
            updateValidation(dispatch, { ownershipDetails: undefined });
            return;
        }

        updateValidation(dispatch, {
            ownershipDetails: !hasOwnershipDetailsAnyData ? COMBINED_ERROR : validationMessage,
        });
    }, [validations, version, dispatch, ownershipDetails, hasOwnershipDetailsAnyData, validationMessage]);

    return { validations };
}

const {
    COMBINED_ERROR,
    TOTAL_OWNERSHIP_EXCEEDS,
    REQUIRED_FIELD,
    GENERAL_TYPE_REQUIRED,
    INTEREST_TYPE_SHOULD_BE_ONLY_SOLE_MEMBER,
    EMPTY_OWNERSHIP_DETAILS,
    INVALID_UNIQUE_OWNERSHIP_NAME,
    INVALID_UNIQUE_OWNERSHIP_PARENT,
} = VALIDATION_MESSAGES;

const { SoleMember, LimitedPartner, GeneralPartner, Shareholder } = InterestTypes;
const { SCSp, LimitedPartnership, LimitedLiabilityCompany } = LegalTypes;
