import { Button, StackPanel } from '@bxgrandcentral/controls';
import { LegalEntityDissolutionTask, LegalEntityDissolutionTaskType } from 'models/LegalEntityRequest/Workflow';
import {
    setEditWorkFlow,
    setIsWorkItemsRefreshing,
    useCreationViewContext,
} from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import { setIsEditable, setIsLoading, setShouldReload } from 'modules/LegalEntityCreation/context/actions';

import { ActionPanelProps } from '../../model';
import { Capabilities } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import React from 'react';
import { SubmitButton } from 'components';
import { TaskActions } from '../../../../Shared/TaskActions';
import { isEmpty } from 'lodash';
import { isOpen } from '../../../../../../models/Workflow';
import { useCallback } from 'react';
import useHasCapability from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-has-capability';
import { useLegalEntityDissolutionDetails } from 'modules/LegalEntityCreation/context/Provider';

export default function ActionPanel(props: ActionPanelProps) {
    const { entityOId } = props;

    const {
        state: {
            data: { changes },
            canSave,
        },
        dispatch,
    } = useLegalEntityDissolutionDetails();

    const { dispatch: creationViewContextDispatch } = useCreationViewContext();

    const hasCapability = useHasCapability();

    const findOpenTask = (tasks: LegalEntityDissolutionTask[] | undefined, taskType: LegalEntityDissolutionTaskType) =>
        tasks?.find((task) => task.taskType === taskType && isOpen(task.status));

    const handleSave = useCallback(
        async (isSubmit = false) => {
            if (props.mode === 'Readonly' || props.mode === 'Withdraw' || !entityOId) {
                return;
            }

            setIsLoading(dispatch, true);
            setIsEditable(dispatch, false);

            try {
                let newWorkItem;
                if (props.mode === 'Create') {
                    newWorkItem = await props.onCreateWorkItem(entityOId, 'DissolutionParentWorkflow', false);
                }
                const workItemId = newWorkItem ? newWorkItem?.workItemId : props.workItem?.workItemId;

                if (workItemId && (props.mode === 'Create' || !isEmpty(changes))) {
                    await props.onSave(changes, workItemId);
                }

                if (props.mode === 'Create') {
                    setIsWorkItemsRefreshing(creationViewContextDispatch, true);
                    setEditWorkFlow(creationViewContextDispatch, false);

                    // workaround because processing the dissolution request creation takes a couple seconds on the API side
                    // during that time it throws 503 for work item related calls
                    await new Promise((resolve) => setTimeout(resolve, 5000));
                    props.setIsWorkItemWasCreated(true);
                }

                if (props.mode !== 'Create' && !isSubmit) {
                    setIsEditable(dispatch, true);
                }

                setIsLoading(dispatch, false);
                setShouldReload(dispatch, true);
            } catch (reason) {
                setIsEditable(dispatch, true);
                setIsLoading(dispatch, false);
                return Promise.reject(reason);
            }
        },
        [dispatch, props, changes, creationViewContextDispatch, entityOId]
    );

    if (!entityOId) {
        return null;
    }

    switch (props.mode) {
        case 'Readonly':
            return null;
        case 'Create': {
            return (
                <SubmitButton
                    isEnabled
                    label='Save'
                    onClick={async () => {
                        await handleSave();
                    }}
                    actionTopic='dissolution'
                />
            );
        }
        case 'Edit': {
            const submitTask = props.workItem?.tasks?.find(
                (task) => task.taskType === 'SubmitRequestType' && isOpen(task.status)
            );

            return (
                <StackPanel
                    orientation='horizontal'
                    horizontalAlignment='right'
                    styleOverrides={{ justifyContent: 'space-between' }}>
                    <Button
                        isEnabled={canSave && hasCapability(Capabilities.createDissolutionRequest)}
                        content='Save'
                        styleName='textOnlyButtonStyle'
                        onClick={async () => {
                            await handleSave();
                        }}
                    />
                    {submitTask ? (
                        <TaskActions
                            isSubmitEnabled={true}
                            task={submitTask}
                            onCompleteTask={async (task, exitCode) => {
                                setIsLoading(dispatch, true);
                                if (exitCode === 'Withdraw') {
                                    props.setIsWorkItemWasCreated(false);
                                }
                                await props.onCompleteTask(task, exitCode as string);
                                setIsLoading(dispatch, false);
                            }}
                            preSubmit={async () => {
                                await handleSave(true);
                            }}
                            actionTopic='dissolution'
                        />
                    ) : null}
                </StackPanel>
            );
        }
        case 'Withdraw': {
            const withdrawTask =
                findOpenTask(props.workItem.tasks, 'BdgSubmitTaskType') ||
                findOpenTask(props.workItem.tasks, 'AwaitingChildApprovalType');

            return withdrawTask && hasCapability(Capabilities.createDissolutionRequest) ? (
                <SubmitButton
                    isEnabled
                    label='Withdraw'
                    onClick={async () => {
                        await props.onCompleteTask(withdrawTask, 'Withdraw');
                    }}
                    actionTopic='dissolution'
                />
            ) : null;
        }
    }
}
