import { Debugger, Expander, FileUpload, Footer, Form, RequestNotes } from 'components';

import ActionPanel from './components/ActionPanel/ActionPanel';
import AuthorizedPersons from './components/AuthorizedPersons/AuthorizedPersons';
import { LegalEntityOwnershipInformationProps } from './model';
import OwnershipDetails from './components/OwnershipDetails/OwnershipDetails';
import React from 'react';
import RejectReason from '../Shared/RejectReason';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import { SECTION_NAMES } from '../models';
import { getDocumentTypesForTreeView } from '../LegalEntityDocumentPreparation/utils';
import { useLegalEntityOwnerShipInformation } from '../context/Provider';
import useOwnershipInformationDocuments from './hooks/use-ownership-information-documents';

export default function LegalEntityOwnershipInformation(props: LegalEntityOwnershipInformationProps) {
    const { state, dispatch } = useLegalEntityOwnerShipInformation();

    const {
        data: {
            values: { id, domesticKey },
        },
    } = state;

    const { documentApproveDate, documentTypes, stampedDocumentTypes } = useOwnershipInformationDocuments({
        legalType: props.data?.legalType,
    });

    const isStandaloneMode =
        !props.workItem ||
        props.workItem?.isStandalone ||
        props.workItem?.workItemState === 'Ownership Setup Complete' ||
        props.workItem?.workItemState === 'Withdrawn';

    const documentSectionName = isStandaloneMode
        ? SECTION_NAMES.OWNERSHIP_INFORMATION_STANDALONE
        : SECTION_NAMES.OWNERSHIP_INFORMATION;

    const treeViewDocumentTypes = getDocumentTypesForTreeView(SECTION_NAMES.OWNERSHIP_INFORMATION);

    const showDocumentsInReadOnlyTreeView = props.mode === 'Readonly' && props.workItem?.workItemStatus !== 'InProcess';

    return (
        <>
            <Debugger inputState={state} requestStep={RequestStepsView.Ownership_Information} />
            <Form>
                <AuthorizedPersons />
                <OwnershipDetails />
                <Expander
                    header={showDocumentsInReadOnlyTreeView ? 'Existing Documents' : 'Documents'}
                    requestStep={RequestStepsView.Ownership_Information}
                    content={
                        <FileUpload
                            entityId={id}
                            documentTypes={documentTypes}
                            stampedDocumentTypes={stampedDocumentTypes}
                            showStampedDocuments={!isStandaloneMode && props.mode !== 'Readonly'}
                            sectionName={documentSectionName}
                            context={{ state, dispatch }}
                            taxRegistrationId={domesticKey}
                            documentApproveDate={documentApproveDate}
                            keepDocumentTypes
                            showDocumentsInReadOnlyTreeView={showDocumentsInReadOnlyTreeView}
                            treeViewDocumentTypes={treeViewDocumentTypes}
                            standaloneCreateMode={props.mode == 'Create'}
                        />
                    }
                />
                {props.mode !== 'Create' && <RequestNotes workItem={props.workItem} mode={props.mode} />}
                <RejectReason mode={props.mode} taskType='UpdateRequestTaskType' tasks={props?.workItem?.tasks} />
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel {...props} />
                </Footer>
            )}
        </>
    );
}
