import { LegalEntityDTO } from '../../../../models';
import { LegalEntityDissolutionTreasuryData } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionTreasury/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityDissolutionTreasuryData => {
    const { entity, dissolutionData } = entityDto;

    return {
        entityOid: entity.entityOId,
        allAccountsClosed: dissolutionData?.dissolutionTreasury?.allAccountsClosed,
        outsideAccountsClosed: dissolutionData?.dissolutionTreasury?.outsideAccountsClosed,
        trialBalanceZero: dissolutionData?.dissolutionTreasury?.trialBalanceZero,
        noOpenIntercompanyBalances: dissolutionData?.dissolutionTreasury?.noOpenIntercompanyBalances,
        closeInWSS: dissolutionData?.dissolutionTreasury?.closeInWSS,
        canCloseOracleCodes: dissolutionData?.dissolutionTreasury?.canCloseOracleCodes,
        closeAllOracleCodes: dissolutionData?.dissolutionTreasury?.closeAllOracleCodes,
        approverNotes: dissolutionData?.dissolutionTreasury?.approverNotes,
        savedBy: dissolutionData?.dissolutionTreasury?.savedBy,
        savedAt: dissolutionData?.dissolutionTreasury?.savedAt,
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityDissolutionTreasuryData>
) => {
    const toReturn = { ...original } as RecursivePartial<LegalEntityDTO>;
    toReturn.dissolutionData = {
        ...original?.dissolutionData,
        dissolutionTreasury: {
            ...original?.dissolutionData?.dissolutionTreasury,
            ...section,
        },
    };

    return toReturn;
};
