import React, { useState } from 'react';

import { Capabilities } from '../RequestStepsView';
import { DISSOLUTION_STATE } from 'models/LegalEntityRequest/Workflow';
import LegalEntityDissolutionDetailsSection from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionDetails/LegalEntityDissolutionDetailsSection';
import { isEmpty } from 'lodash';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';
import useHasCapability from '../hooks/use-has-capability';

export default function DissolutionDetailsStepRenderer() {
    const {
        completeTask,
        createStandalone,
        saveDissolutionSection,
        updateDissolutionSection,
        reloadEntityAndWorkItems,
    } = useApiServices();

    const {
        state: {
            editWorkFlow,
            isWorkItemsRefreshing,
            sections,
            noPendingSections,
            legalEntityDissolutionParentWorkItem: workItem,
            selectedPanel,
        },
    } = useCreationViewContext();

    const hasCapability = useHasCapability();

    const [isWorkItemWasCreated, setIsWorkItemWasCreated] = useState(false);

    const dataRaw = sections.DissolutionRequest;
    const noPendingData = noPendingSections.DissolutionRequest;

    // const data = calculateDefaultValues(dataRaw);
    const data = dataRaw;

    if (isEmpty(data)) {
        return null;
    }

    if (
        !hasCapability(Capabilities.createDissolutionRequest) ||
        isWorkItemsRefreshing ||
        (!selectedPanel?.hasActions && !editWorkFlow)
    ) {
        return (
            <LegalEntityDissolutionDetailsSection
                data={data}
                mode='Readonly'
                noPendingData={noPendingData}
                isWorkItemWasCreated={isWorkItemWasCreated}
                workItem={workItem}
            />
        );
    }

    if (editWorkFlow) {
        return (
            <LegalEntityDissolutionDetailsSection
                mode='Create'
                data={data}
                noPendingData={noPendingData}
                onSave={(dataToSave, workItemId) => {
                    return saveDissolutionSection(sections, 'DissolutionRequest', workItemId, {
                        ...dataToSave,
                    }).then((e) => e?.workflowId ?? 0);
                }}
                onCreateWorkItem={(entityOId, type, reloadWorkItemsAndUserScopes) =>
                    createStandalone(entityOId, type, reloadWorkItemsAndUserScopes)
                }
                workItem={workItem}
                setIsWorkItemWasCreated={setIsWorkItemWasCreated}
            />
        );
    }
    switch (workItem?.workItemState) {
        case DISSOLUTION_STATE.DISSOLUTION_REQUEST:
            return (
                <LegalEntityDissolutionDetailsSection
                    mode='Edit'
                    data={data}
                    noPendingData={noPendingData}
                    onSave={(dataToSave) => {
                        const data = {
                            ...sections.DissolutionRequest,
                            ...dataToSave,
                        };
                        return updateDissolutionSection(
                            sections,
                            'DissolutionRequest',
                            workItem?.workItemId,
                            data
                        ).then((e) => e?.workflowId ?? 0);
                    }}
                    onCompleteTask={(task, exitCode) => {
                        if (workItem) {
                            completeTask(workItem, task, exitCode, '').then(
                                () =>
                                    exitCode === 'Withdraw' &&
                                    setTimeout(() => {
                                        reloadEntityAndWorkItems();
                                    }, 3000)
                            );
                        }
                    }}
                    workItem={workItem}
                    setIsWorkItemWasCreated={setIsWorkItemWasCreated}
                />
            );
        case DISSOLUTION_STATE.AWAITING_FOR_CHILD_APPROVAL:
        case DISSOLUTION_STATE.BDG_PENDING_SUBMIT:
            return (
                <LegalEntityDissolutionDetailsSection
                    mode='Withdraw'
                    data={data}
                    noPendingData={noPendingData}
                    onCompleteTask={(task, exitCode) => {
                        if (workItem) {
                            completeTask(workItem, task, exitCode, '').then(
                                () =>
                                    exitCode === 'Withdraw' &&
                                    setTimeout(() => {
                                        reloadEntityAndWorkItems();
                                    }, 3000)
                            );
                        }
                    }}
                    workItem={workItem}
                />
            );
        default:
            return (
                <LegalEntityDissolutionDetailsSection
                    data={data}
                    noPendingData={noPendingData}
                    mode='Readonly'
                    workItem={workItem}
                />
            );
    }
}
