import { CREATE_ENTITY_STATE } from 'models/LegalEntityRequest/Workflow';
import { Capabilities } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import useHasCapability from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-has-capability';
import { useMemo } from 'react';

export default function useOnlyCtaReviewer(reviewDate?: string | undefined, workItemState?: string) {
    const hasCapability = useHasCapability();

    const isCTAReview = useMemo(() => {
        return (
            [
                CREATE_ENTITY_STATE.APPROVAL.toString(),
                CREATE_ENTITY_STATE.POSTPROCESS_1.toString(),
                CREATE_ENTITY_STATE.POSTPROCESS_2.toString(),
                CREATE_ENTITY_STATE.LEGAL_ENTITY_SETUP_COMPLETE.toString(),
            ].includes(`${workItemState}`) &&
            !reviewDate &&
            hasCapability(Capabilities.approveCorporateTransparency)
        );
    }, [hasCapability, reviewDate, workItemState]);

    return { isOnlyCtaReviewer: isCTAReview };
}
