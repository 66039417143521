import { DocumentSection, FormState } from '../../../../modules/LegalEntityCreation/context/model';
import { FileUploadProps, PendingDocument, StoredDocument, StoredDocumentModel } from '../models';
import { cleanupSectionName, getSectionName } from '../utils';
import { useCallback, useMemo } from 'react';

import { LegalEntityDocumentPreparationData } from '../../../../modules/LegalEntityCreation/LegalEntityDocumentPreparation/model';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { addStoredDocuments } from 'modules/LegalEntityCreation/context/actions';
import { getValuesOf } from 'Utilities/object';
import { isEmpty } from 'lodash';

const defaultSection = {
    pendingDocuments: [] as PendingDocument[],
    storedDocuments: [] as StoredDocumentModel[],
} as DocumentSection;

export default function useFileUpload({
    onlyOneDocumentPerTypeAllowed,
    documentTypes,
    stampedDocumentTypes: stampedDocumentTypesProp,
    showStampedDocuments = true,
    taxRegistrationId,
    context,
    sectionName,
    isStampedDocumentUpload,
    showDocumentsInReadOnlyTreeView,
    treeViewDocumentTypes,
}: FileUploadProps) {
    const { state, dispatch } = context;
    const {
        documents: { sections = {} },
    }: FormState<LegalEntityDocumentPreparationData> = state;

    const section = sections[sectionName] ?? defaultSection;
    const sectionNameWithouthId = useMemo(() => cleanupSectionName(sectionName), [sectionName]);
    const { pendingDocuments = [], storedDocuments = [], errorMessage, additionalErrorMessage, isFetching } = section;

    const getDocumentsForTreeView = useCallback(
        (documents: StoredDocument[] = []) =>
            treeViewDocumentTypes
                ? documents.filter(({ coreDocumentTypeId }) => {
                      return getValuesOf(treeViewDocumentTypes)?.some(({ Key }) => Key === coreDocumentTypeId);
                  })
                : documents,
        [treeViewDocumentTypes]
    );

    const filterDocuments = useCallback(
        (documents: StoredDocument[] = []) => {
            let filteredDocuments = [];

            if (showDocumentsInReadOnlyTreeView) {
                filteredDocuments = getDocumentsForTreeView(documents);
            } else {
                filteredDocuments = documents.filter(({ documentElements, taxRegistrationIds, coreDocumentTypeId }) => {
                    const documentSectionName = getSectionName(documentElements);
                    if (
                        documentSectionName === sectionNameWithouthId ||
                        (isStampedDocumentUpload ? documentSectionName === SECTION_NAMES.STAMPED : false)
                    ) {
                        if (onlyOneDocumentPerTypeAllowed || stampedDocumentTypesProp) {
                            return getValuesOf(documentTypes)?.some(({ Key }) => Key === coreDocumentTypeId);
                        }
                        if (
                            sectionName.includes(SECTION_NAMES.FOREIGN_REGISTRATION) ||
                            sectionName.includes(SECTION_NAMES.DOMESTIC_REGISTRATION) ||
                            sectionName.includes(SECTION_NAMES.FOREIGN_REGISTRATION_UPDATE)
                        ) {
                            return taxRegistrationId && taxRegistrationIds.includes(taxRegistrationId);
                        }
                        return true;
                    }

                    if (taxRegistrationId && showStampedDocuments && documentSectionName === SECTION_NAMES.STAMPED) {
                        const isDocumentTypeCorrect =
                            !stampedDocumentTypesProp ||
                            getValuesOf(stampedDocumentTypesProp)?.some(({ Key }) => Key === coreDocumentTypeId);

                        return taxRegistrationIds.includes(taxRegistrationId) && isDocumentTypeCorrect;
                    }
                    return false;
                });
            }

            if (!isEmpty(filteredDocuments)) {
                addStoredDocuments(dispatch, sectionName, filteredDocuments);
            }
        },
        [
            dispatch,
            documentTypes,
            isStampedDocumentUpload,
            onlyOneDocumentPerTypeAllowed,
            sectionName,
            sectionNameWithouthId,
            showStampedDocuments,
            stampedDocumentTypesProp,
            taxRegistrationId,
            showDocumentsInReadOnlyTreeView,
        ]
    );
    const filterBySectionName = (documents: StoredDocumentModel[], section: string) => {
        return documents.filter(({ document: { documentElements } }) => getSectionName(documentElements) === section);
    };

    const filteredStoredDocuments = useMemo(
        () =>
            !showDocumentsInReadOnlyTreeView
                ? filterBySectionName(storedDocuments, sectionNameWithouthId)
                : storedDocuments,
        [sectionNameWithouthId, storedDocuments, showDocumentsInReadOnlyTreeView]
    );

    const filteredStampedDocuments = useMemo(
        () => filterBySectionName(storedDocuments, SECTION_NAMES.STAMPED),
        [storedDocuments]
    );

    const storedDocumentTypes = useMemo(
        () =>
            new Set(
                filteredStoredDocuments
                    .filter(({ updates: { isDeleted } }) => !isDeleted)
                    .map(({ document: { coreDocumentTypeId } }) => coreDocumentTypeId)
                    .filter(Boolean)
            ),
        [filteredStoredDocuments]
    );
    const pendingDocumentTypes = useMemo(
        () => new Set(pendingDocuments.map(({ documentTypeId }) => documentTypeId).filter(Boolean)),
        [pendingDocuments]
    );
    const stampedDocumentTypes = useMemo(
        () =>
            new Set(
                filteredStampedDocuments
                    .filter(({ updates: { isDeleted } }) => !isDeleted)
                    .map(({ document: { coreDocumentTypeId } }) => coreDocumentTypeId)
                    .filter(Boolean)
            ),
        [filteredStampedDocuments]
    );

    const allDocumentTypes = useMemo(
        () => Array.of(...storedDocumentTypes, ...stampedDocumentTypes),
        [stampedDocumentTypes, storedDocumentTypes]
    );

    const numberOfDocuments = useMemo(
        () => [...pendingDocuments, ...filteredStoredDocuments, ...filteredStampedDocuments].length,
        [filteredStampedDocuments, filteredStoredDocuments, pendingDocuments]
    );

    const hasAnyDocumentEffectiveDate = useMemo(
        () =>
            [...filteredStoredDocuments, ...filteredStampedDocuments].some(
                ({ document: { effectiveDateString } }) => effectiveDateString
            ),
        [filteredStampedDocuments, filteredStoredDocuments]
    );

    const allDocuments = useMemo(
        () => [...filteredStoredDocuments, ...filteredStampedDocuments],
        [filteredStampedDocuments, filteredStoredDocuments]
    );

    return {
        filterDocuments,
        pendingDocuments,
        storedDocuments: filteredStoredDocuments,
        stampedDocuments: filteredStampedDocuments,
        allDocuments,
        numberOfDocuments,
        storedDocumentTypes,
        stampedDocumentTypes,
        pendingDocumentTypes,
        allDocumentTypes,
        section: {
            errorMessage,
            additionalErrorMessage,
            isFetching,
            hasAnyDocumentEffectiveDate,
        },
    };
}
