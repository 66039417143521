import { LegalEntityDTO } from '../../../../models';
import { LegalEntityDissolutionRequestData } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionDetails/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityDissolutionRequestData => {
    const { dissolutionData } = entityDto;

    return {
        desiredDissolutionDate: dissolutionData?.dissolutionRequest?.desiredDissolutionDate,
        requestNotes: dissolutionData?.dissolutionRequest?.requestNotes,
        approval: dissolutionData?.dissolutionRequest?.approval,
        savedBy: dissolutionData?.dissolutionRequest?.savedBy,
        savedAt: dissolutionData?.dissolutionRequest?.savedAt,
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityDissolutionRequestData>
) => {
    const toReturn = { ...original } as RecursivePartial<LegalEntityDTO>;
    toReturn.dissolutionData = {
        ...original?.dissolutionData,
        dissolutionRequest: {
            ...original?.dissolutionData?.dissolutionRequest,
            ...section,
        },
    };

    return toReturn;
};
