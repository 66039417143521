import { Button, StackPanel } from '@bxgrandcentral/controls';
import { setIsLoading, setShouldReload } from 'modules/LegalEntityCreation/context/actions';

import { GlobalState } from 'GlobalState';
import { LegalEntityOwnershipInformationProps } from '../../model';
import React from 'react';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { SubmitButton } from 'components';
import { TaskActions } from 'modules/LegalEntityCreation/Shared/TaskActions';
import { isEmpty } from 'lodash';
import { isOpen } from 'models/Workflow';
import useApiServices from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-api-services';
import useDocumentUpload from 'hooks/use-document-upload';
import { useLegalEntityOwnerShipInformation } from 'modules/LegalEntityCreation/context/Provider';
import { useRequestContext } from 'context/request-context';

export default function ActionPanel(props: LegalEntityOwnershipInformationProps) {
    const { state, getChanges, dispatch } = useLegalEntityOwnerShipInformation();

    const {
        data: { values, changes },
        documents: { sections },
        canSave,
        canSubmit,
    } = state;

    const { id, authorizedPerson } = values;

    const { isRequestNotesChanged } = useRequestContext();
    const { updateWorkItemNotes } = useApiServices();

    const { updateDocuments, withdrawDocuments } = useDocumentUpload();

    const documentSectionName = props.workItem?.isStandalone
        ? SECTION_NAMES.OWNERSHIP_INFORMATION_STANDALONE
        : SECTION_NAMES.OWNERSHIP_INFORMATION;

    if (props.mode === 'Create' && id) {
        return (
            <SubmitButton
                isEnabled={canSave}
                label='Save'
                onClick={async () => {
                    setIsLoading(dispatch, true);
                    try {
                        if (!isEmpty(changes)) {
                            await props.onSave(changes);
                        }
                        await updateDocuments(sections, id);
                        await props.onCreateWorkItem(id, 'OwnershipChildProcess');
                        setShouldReload(dispatch, true);
                    } catch (reason) {
                        setIsLoading(dispatch, false);
                        return Promise.reject(reason);
                    }
                }}
            />
        );
    }

    if (props.mode === 'Edit') {
        const task = props.workItem.tasks?.find((t) => t.taskType === 'RequestTaskType' && isOpen(t.status));

        return (
            <StackPanel
                orientation='horizontal'
                horizontalAlignment='right'
                styleOverrides={{ justifyContent: 'space-between' }}>
                <Button
                    content='Save'
                    styleName='textOnlyButtonStyle'
                    isEnabled={canSave || isRequestNotesChanged(props.workItem)}
                    onClick={async () => {
                        setIsLoading(dispatch, true);
                        try {
                            await updateDocuments(sections, id);
                            if (isRequestNotesChanged(props.workItem)) {
                                await updateWorkItemNotes(props.workItem);
                            }
                            await props.onSave(getChanges());
                            setShouldReload(dispatch, true);
                        } catch (reason) {
                            setIsLoading(dispatch, false);
                            return Promise.reject(reason);
                        }
                    }}
                />
                {task && (
                    <TaskActions
                        task={task}
                        isSubmitEnabled={canSubmit}
                        onCompleteTask={async (task, exitCode) => {
                            const code = exitCode?.toLowerCase();
                            if (code === 'submit' && isEmpty(authorizedPerson?.filter((person) => !person.isDeleted))) {
                                const isConfirmed = await GlobalState.openDialog({
                                    variant: 'warning',
                                    title: 'Authorized Persons',
                                    content: 'No Authorized Persons were added. Are you sure you wish to submit?',
                                });
                                if (!isConfirmed) {
                                    return;
                                }
                            }
                            setIsLoading(dispatch, true);

                            try {
                                if (code === 'withdraw') {
                                    await withdrawDocuments(sections[documentSectionName], id);
                                } else {
                                    await updateDocuments(sections, id);
                                }

                                const changes = {
                                    ...values,
                                    authorizedPerson: values?.authorizedPerson?.map((authorizedPerson) => ({
                                        ...authorizedPerson,
                                        isDeleted: code === 'withdraw' || authorizedPerson.isDeleted,
                                    })),
                                    ownershipDetails: values?.ownershipDetails?.map((ownershipDetail) => ({
                                        ...ownershipDetail,
                                        isDeleted: code === 'withdraw' || ownershipDetail.isDeleted,
                                    })),
                                };

                                await props.onSave(changes);
                                props.onCompleteTask(task, exitCode as string);
                                setShouldReload(dispatch, true);
                            } catch (reason) {
                                setIsLoading(dispatch, false);
                                return Promise.reject(reason);
                            }
                        }}
                    />
                )}
            </StackPanel>
        );
    }

    if (props.mode === 'Approval') {
        const task = props.workItem.tasks?.find(
            ({ taskType, status }) => taskType === 'UpdateRequestTaskType' && isOpen(status)
        );

        return task ? (
            <TaskActions
                onCompleteTask={(task, exitCode, rejectReason) => {
                    props.onCompleteTask(task, exitCode as string, rejectReason);
                    setShouldReload(dispatch, true);
                }}
                task={task}
            />
        ) : null;
    }

    return null;
}
