import {
    LegalEntityDissolutionFinanceTask,
    LegalEntityDissolutionFinanceWorkflow,
} from '../../../../models/LegalEntityRequest/Workflow';

import { KeyValue } from 'models';

export type OnSaveAction = (data: Partial<LegalEntityDissolutionFinanceData>) => Promise<number>;
export type OnCompleteTaskAction = (
    task: LegalEntityDissolutionFinanceTask,
    exitCode: string,
    rejectReason?: string
) => void;

export type LegalEntityDissolutionFinanceData = {
    entityOid?: number;
    isAuditRequired?: boolean;
    auditFinalBilling?: number;
    auditFinalPaymentTimeline?: number;
    cashPositionSettleReceivables?: number;
    cashPositionDeterminePaydown?: number;
    cashPositionProjectCash?: number;
    cashPositionProjectCodingType?: number;
    cashPositionProjectCoding?: string;
    liabilities?: number;
    finalPaymentApproved?: number;
    finalPaymentDistributed?: number;
    approverNotes?: string;
    savedBy?: string;
    savedAt?: string;
};

export const BillingOrProjectCoding: KeyValue[] = [
    { Key: 1, Value: 'Billing Project Code' },
    { Key: 2, Value: 'Another Active Oracle Billing code' },
    { Key: 3, Value: 'Override the original billing code(s)' },
];

type BaseProps = {
    data?: LegalEntityDissolutionFinanceData;
};

type noPendingValues = {
    noPendingData?: LegalEntityDissolutionFinanceData | undefined;
};

type ActionPanelBaseProps = {
    entityOId?: number;
};

type ReadOnlyProps = {
    mode: 'Readonly';
    workItem?: LegalEntityDissolutionFinanceWorkflow;
};

type CreateProps = {
    mode: 'Create';
    workItem?: LegalEntityDissolutionFinanceWorkflow;
    onSave: OnSaveAction;
};

type ApprovalProps = {
    mode: 'Approval';
    workItem: LegalEntityDissolutionFinanceWorkflow;
    onCompleteTask: OnCompleteTaskAction;
    onSave: OnSaveAction;
};

type EditProps = {
    mode: 'Edit';
    workItem?: LegalEntityDissolutionFinanceWorkflow;
    onSave: OnSaveAction;
    onCompleteTask: OnCompleteTaskAction;
};

export type LegalEntityDissolutionFinanceProps = BaseProps &
    noPendingValues &
    (CreateProps | EditProps | ApprovalProps | ReadOnlyProps);

export type ActionPanelProps = ActionPanelBaseProps & LegalEntityDissolutionFinanceProps;
