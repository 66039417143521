import { DoubleColumnLayout, SPACING } from '../../../../../Utilities/Layout';
import { Expander, FileUpload, Select } from 'components';
import React, { useEffect, useMemo } from 'react';
import { getDocumentTypes, getRegisteredAgents } from '../../utils';
import { isCaymanIslands, isUnitedStates } from '../../../../../models';

import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { StackPanel } from '@bxgrandcentral/controls';
import { getDefaultAddress } from '../../../../../models/LegalEntityRequest/DefaultValues';
import { isEmpty } from 'lodash';
import { updateDocumentSection } from 'modules/LegalEntityCreation/context/actions';
import useDocumentPreparationRequirements from '../../../../../hooks/use-document-preparation-requirements';
import { useLegalEntityDocumentPreparation } from '../../../context/Provider';
import { useReferenceData } from '../../../../../api';

export default function DomesticRegistrationDetails() {
    const context = useLegalEntityDocumentPreparation();

    const {
        state: {
            data: {
                values: { entityOid, domesticCountry, domesticRegisteredAgent, legalType, key: taxRegistrationId },
                validationErrors: {
                    domesticCountry: domesticCountryError,
                    domesticRegisteredAgent: domesticRegisteredAgentError,
                },
            },
            documents: { sections },
            isEditable,
        },
        getValue,
        setValues,
        dispatch,
    } = context;

    const {
        data: { State, RegisteredAgent, Country },
    } = useReferenceData();

    const filteredStates = useMemo(
        () => State?.filter(({ ParentId }) => ParentId === `${domesticCountry}`),
        [domesticCountry, State]
    );

    const countryHasRegisteredAgent = useMemo(() => getRegisteredAgents(domesticCountry), [domesticCountry]);

    const documentTypes = useMemo(() => getDocumentTypes(legalType), [legalType]);
    const section = sections[SECTION_NAMES.DOMESTIC_REGISTRATION] ?? { errorMessage: '' };

    const { documentTypeError } = useDocumentPreparationRequirements({
        country: domesticCountry,
        legalType,
        section,
    });

    const isStateRequired = useMemo(
        () => isUnitedStates(domesticCountry) || isCaymanIslands(domesticCountry),
        [domesticCountry]
    );

    useEffect(() => {
        updateDocumentSection(dispatch, SECTION_NAMES.DOMESTIC_REGISTRATION, {
            additionalErrorMessage: documentTypeError,
            isRequired: !!documentTypeError,
        });
    }, [dispatch, documentTypeError, section.errorMessage]);

    return (
        <>
            <Expander
                header='Domestic Registration Details'
                content={
                    <StackPanel itemGap={SPACING.SM}>
                        <Select
                            label='Registered Agent'
                            labelToolTip={{ component: 'Select the registered agent' }}
                            itemsSource={RegisteredAgent}
                            isRequired
                            showSearchBox
                            {...getValue('domesticRegisteredAgent')}
                            isEditable={isEditable && countryHasRegisteredAgent}
                            onValueChanged={(newValue) => {
                                setValues({
                                    domesticRegisteredAgent: newValue,
                                    headquarterAddress: getDefaultAddress(domesticCountry, newValue),
                                });
                            }}
                            validationError={domesticRegisteredAgentError}
                        />
                        <DoubleColumnLayout>
                            <Select
                                label='Country'
                                labelToolTip={{ component: 'Country in which the legal entity is domiciled' }}
                                itemsSource={Country}
                                {...getValue('domesticCountry')}
                                isRequired
                                showSearchBox
                                onValueChanged={(newValue) => {
                                    setValues({
                                        domesticCountry: newValue,
                                        domesticState: undefined,
                                        domesticRegisteredAgent: getRegisteredAgents(newValue)
                                            ? domesticRegisteredAgent
                                            : undefined,
                                        headquarterAddress: getDefaultAddress(newValue, domesticRegisteredAgent),
                                    });
                                }}
                                validationError={domesticCountryError}
                            />
                            <Select
                                label='State'
                                labelToolTip={{ component: 'State in which the legal entity is domiciled' }}
                                itemsSource={filteredStates}
                                showSearchBox
                                isRequired={isStateRequired}
                                {...getValue('domesticState')}
                                isEditable={isEditable && !isEmpty(filteredStates)}
                            />
                        </DoubleColumnLayout>
                        <StackPanel styleOverrides={{ marginTop: `${SPACING.MD}px` }}>
                            <FileUpload
                                entityId={entityOid}
                                sectionName={SECTION_NAMES.DOMESTIC_REGISTRATION}
                                documentTypes={documentTypes}
                                showStoredDocuments={!!taxRegistrationId}
                                showStampedDocuments={false}
                                effectiveDate='optional'
                                taxRegistrationId={taxRegistrationId}
                                context={context}
                            />
                        </StackPanel>
                    </StackPanel>
                }
            />
        </>
    );
}
