import {
    BooleanField,
    DateTimeField,
    Debugger,
    Expander,
    FileUpload,
    Footer,
    Form,
    RequestNotes,
    Select,
} from 'components';
import { DoubleColumnLayout, SPACING } from 'Utilities/Layout';
import {
    FATCAClassifications,
    LegalEntityTaxClassificationProps,
    allowedUSTaxClassificationTypeKey,
    allowedUSTaxClassificationTypeKeys,
    getChapterThreeOptions,
    taxClassificationDocumentTypes,
} from './model';
import React, { Fragment, useEffect, useMemo } from 'react';
import { isEmpty, isEqual, isNil } from 'lodash';

import ActionPanel from './components/ActionPanel/ActionPanel';
import RejectReason from '../Shared/RejectReason';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import { SECTION_NAMES } from '../models';
import { StackPanel } from '@bxgrandcentral/controls';
import { convertToLocalDate } from 'Utilities/date';
import { isUnitedStates } from 'models';
import { useLegalEntityTaxClassification } from '../context/Provider';
import { useReferenceData } from 'api';
import useTaxClassificationValidation from '../validation/use-tax-classification-validation';

export default function LegalEntityTaxClassification(props: LegalEntityTaxClassificationProps) {
    const { state, dispatch, getValue, setValues, setValue } = useLegalEntityTaxClassification();
    const { mode, workItem } = props;

    const {
        data: {
            values: {
                crsClassificationId,
                crsSubClassificationId,
                crsSubClassificationDetailId,
                domesticCountry,
                entityOId,
                fatcaClassificationId,
                isCtbElectionApproved,
                usTaxClassificationTypeId,
                isGIINRequired,
                chapterThreeStatusId,
                usStateTaxClassificationTypeId,
                taxPreparerEntityOId,
                tax8832EffectiveDate,
                isPortCoManaged,
            },
            noPendingValues,
        },
        isEditable,
        version,
    } = state;

    const {
        data: {
            CRSClassifications,
            CRSSubClassification,
            CRSSubClassificationDetails,
            FATCAClassification,
            USTaxClassificationType,
            ChapterThreeStatus,
            USStateTaxClassificationType,
            TaxPreparer,
        },
    } = useReferenceData();

    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return isStandaloneRequest &&
            !isNil(noPendingValues) &&
            !isEditable &&
            !(isNil(noPendingValue) && isNil(newValue))
            ? !isEqual(newValue, noPendingValue)
            : undefined;
    };

    const crsSubClassificationsFiltered = useMemo(
        () => CRSSubClassification?.filter(({ ParentId }) => ParentId === crsClassificationId?.toString()),
        [CRSSubClassification, crsClassificationId]
    );

    const crsSubClassificationDetailsFiltered = useMemo(
        () =>
            CRSSubClassificationDetails?.filter(({ ParentId }) => ParentId === crsSubClassificationId?.toString()).sort(
                (a, b) => a.Key - b.Key
            ),
        [CRSSubClassificationDetails, crsSubClassificationId]
    );

    const isStandaloneRequest = useMemo(
        () => (props.workItem && isNil(props.workItem.customProperties.parentItemId)) || props.mode === 'Create',
        [props.mode, props.workItem]
    );

    const shouldShowFileUpload = useMemo(
        () => isStandaloneRequest && !(props.workItem?.workItemStatus === 'Complete' && props.mode === 'Readonly'),
        [isStandaloneRequest, props.mode, props.workItem]
    );

    const ChapterThreeStatusFiltered = useMemo(() => {
        const allowedOptions = getChapterThreeOptions(
            usTaxClassificationTypeId as allowedUSTaxClassificationTypeKey,
            domesticCountry
        );
        return ChapterThreeStatus?.filter(({ Key }) => allowedOptions.includes(Key));
    }, [ChapterThreeStatus, domesticCountry, usTaxClassificationTypeId]);

    const USTaxClassificationTypeFiltered = useMemo(
        () =>
            USTaxClassificationType?.filter(({ Key }) =>
                allowedUSTaxClassificationTypeKeys.includes(Key as allowedUSTaxClassificationTypeKey)
            ),
        [USTaxClassificationType]
    );

    const isChapterThreeEnabled = useMemo(() => {
        return (
            isEditable &&
            !!usTaxClassificationTypeId &&
            !!ChapterThreeStatusFiltered?.length &&
            ChapterThreeStatusFiltered.length > 1
        );
    }, [isEditable, usTaxClassificationTypeId, ChapterThreeStatusFiltered]);

    useEffect(() => {
        const chapterThreeDefaultValue =
            ChapterThreeStatusFiltered?.length === 1 ? ChapterThreeStatusFiltered[0].Key : chapterThreeStatusId;

        if (
            ['Edit', 'Create'].includes(props.mode) &&
            ChapterThreeStatusFiltered &&
            chapterThreeDefaultValue &&
            !ChapterThreeStatusFiltered.find(({ Key }) => Key === chapterThreeDefaultValue)
        ) {
            // at Axiom MDM the user can select chapterThreeStatusId which are not present in dropdown options at RC 2.0
            setValue('chapterThreeStatusId', undefined);
        } else {
            setValue('chapterThreeStatusId', chapterThreeDefaultValue);
        }
    }, [ChapterThreeStatusFiltered, chapterThreeStatusId, setValue, props.mode]);

    useTaxClassificationValidation({ isPortCoManaged: isPortCoManaged ?? false });

    return (
        <>
            <Form>
                <Debugger inputState={state} requestStep={RequestStepsView.Tax_Classification} />
                <Expander
                    header='Tax Classification'
                    content={
                        <DoubleColumnLayout>
                            <StackPanel itemGap={SPACING.SM}>
                                <Select
                                    label='FATCA Classification'
                                    labelToolTip={{ component: 'FATCA classification for a Legal Entity' }}
                                    itemsSource={FATCAClassification}
                                    showSearchBox
                                    isRequired
                                    {...getValue('fatcaClassificationId')}
                                    isPendingChange={isPendingChange(
                                        fatcaClassificationId,
                                        noPendingValues?.fatcaClassificationId
                                    )}
                                    onValueChanged={(newValue) =>
                                        setValues({
                                            fatcaClassificationId: newValue,
                                            isGIINRequired:
                                                !isUnitedStates(domesticCountry) &&
                                                !isEqual(
                                                    fatcaClassificationId,
                                                    FATCAClassifications.ActiveOrPassiveNFFE
                                                ),
                                        })
                                    }
                                    version={version}
                                />
                                <Select
                                    label='CRS Classification'
                                    labelToolTip={{ component: 'Tax entity CRS classification ' }}
                                    itemsSource={CRSClassifications}
                                    isRequired
                                    {...getValue('crsClassificationId')}
                                    isPendingChange={isPendingChange(
                                        crsClassificationId,
                                        noPendingValues?.crsClassificationId
                                    )}
                                    onValueChanged={(newValue) =>
                                        setValues({
                                            crsClassificationId: newValue,
                                            crsSubClassificationId: undefined,
                                            crsSubClassificationDetailId: undefined,
                                        })
                                    }
                                />
                                {!isEmpty(crsSubClassificationsFiltered) && (
                                    <Fragment key={crsClassificationId}>
                                        <Select
                                            label='CRS Sub Classification'
                                            labelToolTip={{ component: 'Tax entity CRS sub classification' }}
                                            itemsSource={crsSubClassificationsFiltered}
                                            isRequired
                                            {...getValue('crsSubClassificationId')}
                                            isPendingChange={isPendingChange(
                                                crsSubClassificationId,
                                                noPendingValues?.crsSubClassificationId
                                            )}
                                            onValueChanged={(newValue) =>
                                                setValues({
                                                    crsSubClassificationId: newValue,
                                                    crsSubClassificationDetailId: undefined,
                                                })
                                            }
                                        />
                                    </Fragment>
                                )}
                                {!isEmpty(crsSubClassificationDetailsFiltered) && (
                                    <Fragment key={`${crsClassificationId}-${crsSubClassificationId}`}>
                                        <Select
                                            label='CRS Sub Classification Detail'
                                            labelToolTip={{ component: 'Tax entity CRS sub classification detail' }}
                                            itemsSource={crsSubClassificationDetailsFiltered}
                                            isRequired
                                            {...getValue('crsSubClassificationDetailId')}
                                            isPendingChange={isPendingChange(
                                                crsSubClassificationDetailId,
                                                noPendingValues?.crsSubClassificationDetailId
                                            )}
                                        />
                                    </Fragment>
                                )}
                                <Select
                                    label='US Tax Classification'
                                    labelToolTip={{ component: 'Entity’s classification for U.S. tax purposes' }}
                                    itemsSource={USTaxClassificationTypeFiltered}
                                    showSearchBox
                                    isRequired
                                    {...getValue('usTaxClassificationTypeId')}
                                    isPendingChange={isPendingChange(
                                        usTaxClassificationTypeId,
                                        noPendingValues?.usTaxClassificationTypeId
                                    )}
                                    onValueChanged={(newValue) =>
                                        setValues({
                                            usTaxClassificationTypeId: newValue,
                                            chapterThreeStatusId: undefined,
                                        })
                                    }
                                />
                                <Select
                                    label='Chapter Three Status'
                                    labelToolTip={{ component: 'Select the Chapter Three tax classification status.' }}
                                    itemsSource={ChapterThreeStatusFiltered}
                                    isRequired
                                    canUndo={false}
                                    {...getValue('chapterThreeStatusId')}
                                    isPendingChange={isPendingChange(
                                        chapterThreeStatusId,
                                        noPendingValues?.chapterThreeStatusId
                                    )}
                                    isEditable={isChapterThreeEnabled}
                                />
                            </StackPanel>
                            <StackPanel itemGap={SPACING.SM}>
                                <BooleanField
                                    label='Is GIIN Required'
                                    labelToolTip={{
                                        component: 'Select whether GIIN is required for this entity',
                                    }}
                                    {...getValue('isGIINRequired')}
                                    isPendingChange={isPendingChange(isGIINRequired, noPendingValues?.isGIINRequired)}
                                />
                                <BooleanField
                                    label='Is 8832/CTB Required'
                                    labelToolTip={{
                                        component:
                                            'An eligible entity uses Form 8832. Check the Box to elect how it will be classified for federal tax purposes: a corporate, a partnership, or an entity disregarded as separate from its owner.',
                                        options: { showDuration: 10000 },
                                    }}
                                    {...getValue('isCtbElectionApproved')}
                                    isPendingChange={isPendingChange(
                                        isCtbElectionApproved,
                                        noPendingValues?.isCtbElectionApproved
                                    )}
                                    onValueChanged={(newValue) => {
                                        setValues({
                                            isCtbElectionApproved: newValue,
                                            ...(!newValue && { tax8832EffectiveDate: undefined }),
                                        });
                                    }}
                                />
                                <DateTimeField
                                    label='8832 Effective Date'
                                    labelToolTip='Select the 8832 Effective Date'
                                    {...getValue('tax8832EffectiveDate')}
                                    isPendingChange={isPendingChange(
                                        tax8832EffectiveDate,
                                        noPendingValues?.tax8832EffectiveDate
                                    )}
                                    isEditable={isEditable && isCtbElectionApproved}
                                    onValueChanged={(newValue) => {
                                        setValues({ tax8832EffectiveDate: convertToLocalDate(newValue) });
                                    }}
                                />
                                <Select
                                    label='US State Tax Classification'
                                    labelToolTip={{ component: "Entity's classification for U.S. state tax purposes" }}
                                    itemsSource={USStateTaxClassificationType}
                                    showSearchBox
                                    isRequired
                                    {...getValue('usStateTaxClassificationTypeId')}
                                    isPendingChange={isPendingChange(
                                        usStateTaxClassificationTypeId,
                                        noPendingValues?.usStateTaxClassificationTypeId
                                    )}
                                />
                                <BooleanField
                                    label='Is Port Co Managed?'
                                    labelToolTip={{
                                        component:
                                            'Check this as Yes if this entity is managed by a Portfolio Company. As a result, the tax preparer will not be collected as part of onboarding.',
                                        options: { showDuration: 10000 },
                                    }}
                                    value={isPortCoManaged}
                                    version={version}
                                    isPendingChange={isPendingChange(isPortCoManaged, noPendingValues?.isPortCoManaged)}
                                    isEditable={isEditable}
                                    onValueChanged={(newValue) => {
                                        setValue('isPortCoManaged', newValue ?? false, false);
                                        if (newValue) {
                                            setValues({ taxPreparerEntityOId: undefined });
                                        }
                                    }}
                                />
                                <Select
                                    label='Tax Preparer'
                                    labelToolTip={{
                                        component:
                                            'Accounting firm that prepares (calculates and files) the taxes for this entity. This entity is not Port Co managed.',
                                    }}
                                    itemsSource={TaxPreparer}
                                    isRequired={!isPortCoManaged}
                                    {...getValue('taxPreparerEntityOId')}
                                    isPendingChange={isPendingChange(
                                        taxPreparerEntityOId,
                                        noPendingValues?.taxPreparerEntityOId
                                    )}
                                    isEditable={isEditable && !isPortCoManaged}
                                />
                            </StackPanel>
                        </DoubleColumnLayout>
                    }
                />
                {shouldShowFileUpload && (
                    <Expander
                        header='Documents'
                        content={
                            <FileUpload
                                entityId={entityOId}
                                sectionName={SECTION_NAMES.TAX_CLASSIFICATION}
                                onlyOneDocumentPerTypeAllowed
                                documentTypes={taxClassificationDocumentTypes}
                                context={{ state, dispatch }}
                                standaloneCreateMode = {props.mode == 'Create'}
                            />
                        }
                    />
                )}
                {props.mode !== 'Create' && <RequestNotes workItem={props.workItem} mode={props.mode} />}
                <RejectReason mode={mode} taskType='UpdateRequestTaskType' tasks={workItem?.tasks} />
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel {...props} />
                </Footer>
            )}
        </>
    );
}
