import { Capabilities, RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import { Debugger, EmailLink, Expander, Footer, Form, Select, Text, TextField } from 'components';
import { GridLayout, SPACING } from 'Utilities/Layout';
import React, { useEffect } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { YesNoState, YesNoStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import useCorporateTransparencyValidation, {
    bxHasEnoughOwnershipPercent,
} from '../validation/use-corporate-transparency-validation';

import ActionPanel from './ActionPanel';
import Documents from './components/Documents/Documents';
import EntityDetails from './components/EntityDetails/EntityDetails';
import { LegalEntityCorporateTransparencyProps } from './model';
import Registrations from './components/Registrations/Registrations';
import ReviewInfo from './components/ReviewInfo/ReviewInfo';
import { isEmpty } from 'lodash';
import useHasCapability from '../LegalEntityCreationView/hooks/use-has-capability';
import useHasUsRegistration from './hooks/use-has-us-registration';
import { useLegalEntityCorporateTransparency } from '../context/Provider';
import { useReferenceData } from 'api';

export default function LegalEntityCorporateTransparency(props: LegalEntityCorporateTransparencyProps) {
    const context = useLegalEntityCorporateTransparency();
    const {
        state: {
            data: {
                values: {
                    isEntityOwnedByBlackstone,
                    bxOwnershipPercentage,
                    canBlackstoneControlOwnershipTransfer,
                    canBlackstoneEffectuateReorganization,
                    corporateTransparencyStatusInternal,
                    corporateTransparencyStatus,
                    domesticRegistration,
                    foreignRegistrations,
                    legalName,
                    reviewDate,
                    CTAReviewer,
                    reason,
                },
                storedValues: { corporateTransparencyStatus: storedCorporateTransparencyStatus },
                changes,
                validationErrors,
            },
        },
        setValue,
        setValues,
        getValue,
    } = context;

    const hasCapability = useHasCapability();
    useCorporateTransparencyValidation({ mode: props.mode });
    const hasUsRegistration = useHasUsRegistration();

    const {
        data: { CorporateTransparencyStatus: CorporateTransparencyStatusRefData },
    } = useReferenceData();

    const exemptKey = CorporateTransparencyStatusRefData?.find(({ Value }) => Value === 'Exempt')?.Key;
    const nonExemptKey = CorporateTransparencyStatusRefData?.find(({ Value }) => Value === 'Non-exempt')?.Key;

    const customSubmitMessage =
        corporateTransparencyStatusInternal === exemptKey
            ? 'By hitting confirm, you are certifying that this entity appears to meet CTA exemption. The AML/Compliance team will be notified.'
            : 'By hitting confirm, you are certifying that this entity does not appear to meet CTA exemption. This entity will be flagged, and the AML/Compliance team will be notified.';

    const bxHasEnoughOwnership = bxHasEnoughOwnershipPercent(bxOwnershipPercentage);

    useEffect(() => {
        if (!hasUsRegistration && (corporateTransparencyStatusInternal !== exemptKey || !reason)) {
            setValues({ corporateTransparencyStatusInternal: exemptKey, reason: 'Non-US registered entity' }, false);
        }
    }, [hasUsRegistration, setValues, exemptKey, corporateTransparencyStatusInternal, reason]);

    useEffect(() => {
        if (isEntityOwnedByBlackstone === undefined || !nonExemptKey || !exemptKey) {
            return;
        }

        if (
            isEntityOwnedByBlackstone === YesNoStateKeys.Yes ||
            (bxHasEnoughOwnership &&
                canBlackstoneControlOwnershipTransfer === YesNoStateKeys.Yes &&
                canBlackstoneEffectuateReorganization === YesNoStateKeys.Yes)
        ) {
            if (corporateTransparencyStatusInternal === nonExemptKey && !isEmpty(changes)) {
                setValue('reason', undefined);
                if (props.isReviewMode) {
                    setValues({
                        corporateTransparencyStatus: undefined,
                        CTAReviewReason: undefined,
                    });
                    setValue('corporateTransparencyStatus', undefined);
                }
            }
            setValue('corporateTransparencyStatusInternal', exemptKey, false);
        } else if (
            (isEntityOwnedByBlackstone === YesNoStateKeys.No && !bxHasEnoughOwnership) ||
            (bxHasEnoughOwnership &&
                (canBlackstoneControlOwnershipTransfer !== YesNoStateKeys.Yes ||
                    canBlackstoneEffectuateReorganization !== YesNoStateKeys.Yes))
        ) {
            if (corporateTransparencyStatusInternal === exemptKey && !isEmpty(changes)) {
                setValue('reason', undefined);
                if (props.isReviewMode) {
                    setValues({
                        corporateTransparencyStatus: undefined,
                        CTAReviewReason: undefined,
                    });
                }
            }
            setValue('corporateTransparencyStatusInternal', nonExemptKey, false);
        } else {
            setValue('corporateTransparencyStatusInternal', undefined, false);
        }
    }, [
        isEntityOwnedByBlackstone,
        canBlackstoneControlOwnershipTransfer,
        canBlackstoneEffectuateReorganization,
        bxHasEnoughOwnership,
        corporateTransparencyStatusInternal,
        exemptKey,
        nonExemptKey,
        setValue,
        setValues,
        changes,
        props.isReviewMode,
    ]);

    const isStatusMessageVisible =
        !hasUsRegistration ||
        isEntityOwnedByBlackstone === YesNoStateKeys.Yes ||
        (bxOwnershipPercentage && !validationErrors.bxOwnershipPercentage);

    const isReviewMode = props.isReviewMode && hasCapability(Capabilities.approveCorporateTransparency);

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Corporate_Transparency} />
            <Form>
                {isReviewMode && (
                    <>
                        <Text verticalAlignment='center' variant='headline'>
                            Corporate Transparency - AML/Compliance Review
                        </Text>
                        <EntityDetails legalName={legalName} />
                        <Documents />
                        <Registrations registrationType='Domestic' registrations={domesticRegistration} />
                        <Registrations registrationType='Foreign' registrations={foreignRegistrations} />
                    </>
                )}
                <Expander
                    header='Corporate Transparency'
                    content={
                        <StackPanel itemGap={SPACING.XL} margin={`${SPACING.XL}px 0 ${SPACING.XL}px 0`}>
                            {!hasUsRegistration ? (
                                <Text horizontalAlignment='center' verticalAlignment='center' textAlignment='center'>
                                    <p>The legal entity is not registered in the United States.</p>
                                </Text>
                            ) : (
                                <GridLayout columns='2fr 1fr'>
                                    <StackPanel>
                                        <TextBlock
                                            text='Is this entity wholly owned by Blackstone Inc.?'
                                            toolTip='Does Blackstone own 100% of this entity?'
                                            margin={`${SPACING.XS}px 0 0 0`}
                                        />
                                    </StackPanel>
                                    <Select
                                        itemsSource={YesNoState}
                                        isRequired
                                        {...getValue('isEntityOwnedByBlackstone')}
                                        isPendingChange={false}
                                        onValueChanged={(newValue) => {
                                            setValues({
                                                isEntityOwnedByBlackstone: newValue,
                                                bxOwnershipPercentage: undefined,
                                                canBlackstoneControlOwnershipTransfer: undefined,
                                                canBlackstoneEffectuateReorganization: undefined,
                                                reason: undefined,
                                            });
                                        }}
                                    />
                                    {isEntityOwnedByBlackstone === YesNoStateKeys.No && (
                                        <>
                                            <StackPanel>
                                                <TextBlock
                                                    text='BX Ownership Percentage'
                                                    toolTip='What percentage of this entity does Blackstone own? 
                                                    Please enter a number less than 100. If BX owns 100%, then please select yes to the question above.'
                                                    margin={`${SPACING.XS}px 0 0 0`}
                                                />
                                            </StackPanel>
                                            <TextField
                                                {...getValue('bxOwnershipPercentage')}
                                                value={bxOwnershipPercentage ? bxOwnershipPercentage.toString() : ''}
                                                isPendingChange={false}
                                                onValueChanged={(newValue) => {
                                                    setValues({
                                                        bxOwnershipPercentage: newValue,
                                                        canBlackstoneControlOwnershipTransfer: undefined,
                                                        canBlackstoneEffectuateReorganization: undefined,
                                                    });
                                                }}
                                            />
                                        </>
                                    )}
                                    {isEntityOwnedByBlackstone === YesNoStateKeys.No &&
                                        !validationErrors.bxOwnershipPercentage &&
                                        bxHasEnoughOwnership && (
                                            <>
                                                <StackPanel>
                                                    <TextBlock
                                                        text='Can Blackstone control the third party’s ability to transfer its ownership interest, either through a consent right or otherwise?'
                                                        toolTip='Can Blackstone control ownership transfers on this entity?'
                                                        margin={`${SPACING.XS}px 0 0 0`}
                                                    />
                                                </StackPanel>
                                                <Select
                                                    itemsSource={YesNoState}
                                                    isRequired
                                                    {...getValue('canBlackstoneControlOwnershipTransfer')}
                                                    isPendingChange={false}
                                                />
                                                <StackPanel>
                                                    <TextBlock
                                                        text='Can Blackstone, through its own action and without the consent of the third party, effectuate a reorganization of the new entity in a way that divests the third party of ownership, either with or without drag rights or other consideration being paid to the third party?'
                                                        toolTip='Can Blackstone control ownership removals on this entity?'
                                                        margin={`${SPACING.XS}px 0 0 0`}
                                                    />
                                                </StackPanel>
                                                <Select
                                                    itemsSource={YesNoState}
                                                    isRequired
                                                    {...getValue('canBlackstoneEffectuateReorganization')}
                                                    isPendingChange={false}
                                                />
                                            </>
                                        )}
                                </GridLayout>
                            )}
                            {(isEntityOwnedByBlackstone || !hasUsRegistration) && (
                                <>
                                    {isStatusMessageVisible && (
                                        <Text
                                            horizontalAlignment='center'
                                            verticalAlignment='center'
                                            textAlignment='center'
                                            variant={
                                                !hasUsRegistration || corporateTransparencyStatusInternal === exemptKey
                                                    ? 'success'
                                                    : 'error'
                                            }>
                                            {!hasUsRegistration || corporateTransparencyStatusInternal === exemptKey ? (
                                                <p>Entity appears to be exempt from CTA reporting.</p>
                                            ) : (
                                                <p>
                                                    Entity does not meet CTA exemption. Please contact:{' '}
                                                    <EmailLink
                                                        mailTo='LC-CorporateTransparencyAct@Blackstone.com'
                                                        label='LC-CorporateTransparencyAct@Blackstone.com'
                                                    />
                                                </p>
                                            )}
                                        </Text>
                                    )}
                                    <div className='text-area-hide-scrollbar'>
                                        <TextBlock
                                            text='CTA Status Reason'
                                            margin={`${SPACING.XS}px 0 0 0`}
                                            toolTip={
                                                isReviewMode
                                                    ? 'Reason entered by the law firms for the CTA status determined.'
                                                    : `Please enter the reason for the CTA status determined.
This field is used as a certification. For example, you may enter “Entity is 100% owned by BX” if you select Yes to the first question.`
                                            }
                                        />
                                        <TextField
                                            minHeight={120}
                                            maxHeight={240}
                                            variant='default'
                                            textWrapping='wrap'
                                            padding={`${SPACING.XS}px 0`}
                                            canUndo={false}
                                            {...getValue('reason')}
                                            isPendingChange={false}
                                            isRequired
                                            isReadOnly={!hasUsRegistration}
                                        />
                                    </div>
                                    {isReviewMode && (
                                        <>
                                            <GridLayout>
                                                <StackPanel>
                                                    <TextBlock
                                                        text='Corporate Transparency Status'
                                                        toolTip='Change the Corporate Transparency Status if you disagree with the CTA status determined.'
                                                        margin={`${SPACING.XXL}px 0 0 0`}
                                                    />
                                                </StackPanel>
                                                <Select
                                                    itemsSource={CorporateTransparencyStatusRefData}
                                                    isRequired
                                                    {...getValue('corporateTransparencyStatus')}
                                                    isPendingChange={false}
                                                    margin={`${SPACING.XXL}px 0 0 0`}
                                                    isReadOnly={!hasUsRegistration}
                                                />
                                                <TextBlock
                                                    text='Reason for Status Change'
                                                    maxHeight={18}
                                                    toolTip='Please provide the reason for changing the current Corporate Transparency status.'
                                                    margin={`0 0 0 0`}
                                                />
                                                <TextField
                                                    minHeight={120}
                                                    maxHeight={240}
                                                    variant='default'
                                                    textWrapping='wrap'
                                                    padding={`${SPACING.XS}px 0`}
                                                    canUndo={false}
                                                    {...getValue('CTAReviewReason')}
                                                    isPendingChange={false}
                                                    isRequired={
                                                        corporateTransparencyStatus !==
                                                        storedCorporateTransparencyStatus
                                                    }
                                                    isReadOnly={!hasUsRegistration}
                                                />
                                            </GridLayout>
                                            <ReviewInfo reviewerUser={CTAReviewer} reviewDate={reviewDate} />
                                        </>
                                    )}
                                </>
                            )}
                        </StackPanel>
                    }
                />
            </Form>
            {props.mode !== 'ReadOnly' && (
                <Footer>
                    <ActionPanel customSubmitMessage={customSubmitMessage} {...props} />
                </Footer>
            )}
        </>
    );
}
