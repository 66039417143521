import { Border, HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import { Button, StackPanel, TextBlock } from '@bxgrandcentral/controls';
import React, { Fragment, useEffect } from 'react';

import { Expander } from 'components';
import OwnershipDetail from './OwnershipDetail';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { isEmpty } from 'Utilities/Validations';
import useColors from 'api/hooks/use-theme';
import { useLegalEntityOwnerShipInformation } from 'modules/LegalEntityCreation/context/Provider';
import useOwnershipDetailsValidation from 'modules/LegalEntityCreation/validation/use-ownership-details-validation';

export default function OwnershipDetails() {
    const { state, setValue } = useLegalEntityOwnerShipInformation();

    const { normalControlBorderColor } = useColors();

    const {
        data: {
            values: { ownershipDetails = [], legalType },
            noPendingValues,
        },
        isEditable,
    } = state;

    function addOwnershipDetail() {
        setValue('ownershipDetails', [...ownershipDetails.concat({ isDeleted: false })]);
    }

    useEffect(() => {
        if (isEmpty(ownershipDetails) && isEditable) {
            setValue('ownershipDetails', [...ownershipDetails.concat({ isDeleted: false })]);
        }
    }, [ownershipDetails, isEditable, setValue]);

    const { validations } = useOwnershipDetailsValidation({ legalType });

    const noPendingRegistrationData = noPendingValues?.ownershipDetails;

    return (
        <Expander
            header='Ownership Details'
            requestStep={RequestStepsView.Ownership_Information}
            padding={0}
            content={
                <StackPanel itemGap={SPACING.XL}>
                    {isEmpty(ownershipDetails) ? (
                        <TextBlock styleName='captionStyle' padding={HORIZONTAL_FORM_PADDING}>
                            No ownership added
                        </TextBlock>
                    ) : (
                        ownershipDetails.map((_, index) => (
                            <Fragment key={index}>
                                {index > 0 && <Border color={normalControlBorderColor} />}
                                <OwnershipDetail
                                    index={index}
                                    validation={validations[index]}
                                    noPendingValues={noPendingRegistrationData?.[index]}
                                />
                            </Fragment>
                        ))
                    )}
                </StackPanel>
            }
            {...(isEditable && {
                footer: <Button content='Add Ownership Detail' isEnabled={isEditable} onClick={addOwnershipDetail} />,
            })}
        />
    );
}
