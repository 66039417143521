import React, { FC, useRef } from 'react';
import { StackPanel, ThemeColors } from '@bxgrandcentral/controls';

import { SPACING } from 'Utilities/Layout';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';
import useElementOffset from 'hooks/use-element-offset';

const Footer: FC = ({ children }) => {
    const colors = useColors();
    const ref = useRef<HTMLDivElement>(null);
    const { width } = useElementOffset(ref);

    return (
        <Component ref={ref}>
            <FixedContainer colors={colors} width={width}>
                <StackPanel padding={`${SPACING.SM}px ${SPACING.XL}px`}>{children}</StackPanel>
            </FixedContainer>
        </Component>
    );
};

const Component = styled.div`
    width: 100%;
`;

const FixedContainer = styled.div<{ colors: ThemeColors; width: number }>`
    position: fixed;
    bottom: 0;
    width: ${({ width }) => `${width}px`};
    border-top: ${({ colors }) => `1px solid ${colors.normalControlBorderColor}`};
    background-color: ${({ colors }) => colors.backgroundColor};
`;

export default Footer;
