import { DateTimeField, DeleteButton, ElasticSearchField, Select, Text } from 'components';
import { DoubleColumnLayout, HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import React, { useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';

import { AuthorizedPersonModel } from '../../model';
import { EntitySubType } from 'models/LegalEntity/EntitySubType';
import { FormControl } from 'components/controls/controls.styled';
import { GlobalState } from 'GlobalState';
import { ValidationErrors } from 'modules/LegalEntityCreation/context/model';
import { convertToLocalDate } from 'Utilities/date';
import { isEqual, isNil } from 'lodash';
import { removeAt } from 'Utilities/array';
import { useLegalEntityOwnerShipInformation } from 'modules/LegalEntityCreation/context/Provider';
import { useReferenceData } from 'api';

type Props = {
    index: number;
    validation: ValidationErrors<AuthorizedPersonModel>;
    noPendingValues?: AuthorizedPersonModel;
};

export default function AuthorizedPerson({ index, validation = {}, noPendingValues }: Props) {
    const {
        state: {
            data: {
                values: { authorizedPerson = [] },
            },
            isEditable,
            version,
        },
        setValue,
    } = useLegalEntityOwnerShipInformation();

    const { key, appointmentDate, resignationDate, name, title, isDeleted } = authorizedPerson[index];

    const {
        data: { SignatoryTitles },
    } = useReferenceData();

    const isUnfinishedRegistration = useMemo(() => isNil(name) || isNil(title), [name, title]);

    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return !isEditable && !isNil(noPendingValues) ? !isEqual(newValue, noPendingValue) : undefined;
    };

    async function setIsDeleted() {
        if (key) {
            authorizedPerson[index].isDeleted = !isDeleted;
            setValue('authorizedPerson', Array.of(...authorizedPerson));
        } else {
            await GlobalState.openDialog({
                variant: 'info',
                title: 'Delete Authorized Person',
                content:
                    'Are you sure you want to remove this Authorized Person?  Any information on this unsaved relationship will be lost.',
            }).then((response) => {
                if (response) {
                    setValue('authorizedPerson', removeAt(authorizedPerson, index));
                }
            });
        }
    }

    return (
        <StackPanel padding={HORIZONTAL_FORM_PADDING}>
            {!key && (
                <TextBlock styleName='heading4Style' margin={`0 0 ${SPACING.SM}px 0 `}>
                    New Person
                </TextBlock>
            )}
            <DoubleColumnLayout isDisabled={isDeleted}>
                <StackPanel itemGap={SPACING.SM}>
                    <FormControl className='form'>
                        <ElasticSearchField
                            label='Name'
                            labelToolTip='Enter the name of the authorized person'
                            searchType={EntitySubType.ThirdParty}
                            selectedEntityOId={name}
                            onEntitySelected={(result) => {
                                const updated = [...authorizedPerson];
                                updated[index].name = result?.entityOId;
                                setValue('authorizedPerson', updated);
                            }}
                            canClearSelection
                            {...(isEditable && { validationError: validation.name })}
                            isReadOnly={!isEditable}
                            isPendingChange={noPendingValues && isPendingChange(name, noPendingValues?.name)}
                            isRequired
                        />
                    </FormControl>
                    <Select
                        label='Title'
                        labelToolTip={{component: 'Select the authorized person’s title'}}
                        itemsSource={SignatoryTitles}
                        value={title}
                        validationError={validation.title}
                        isEditable={isEditable}
                        version={version}
                        onValueChanged={(newValue) => {
                            const updated = [...authorizedPerson];
                            updated[index].title = newValue;
                            setValue('authorizedPerson', updated);
                        }}
                        isPendingChange={noPendingValues && isPendingChange(title, noPendingValues?.title)}
                        isRequired
                    />
                </StackPanel>
                <StackPanel itemGap={SPACING.SM}>
                    <DateTimeField
                        label='Appointment Date'
                        labelToolTip='Date in which authorized person was appointed'
                        value={appointmentDate}
                        isEditable={isEditable}
                        version={version}
                        validationError={validation.appointmentDate}
                        onValueChanged={(newValue) => {
                            const updated = [...authorizedPerson];
                            updated[index].appointmentDate = convertToLocalDate(newValue);
                            setValue('authorizedPerson', updated);
                        }}
                        isPendingChange={
                            noPendingValues && isPendingChange(appointmentDate, noPendingValues?.appointmentDate)
                        }
                        isRequired
                    />
                    <DateTimeField
                        label='Resignation Date'
                        labelToolTip='Date of resignation'
                        value={resignationDate}
                        isEditable={isEditable}
                        version={version}
                        validationError={validation.resignationDate}
                        onValueChanged={(newValue) => {
                            const updated = [...authorizedPerson];
                            updated[index].resignationDate = convertToLocalDate(newValue);
                            setValue('authorizedPerson', updated);
                        }}
                        isPendingChange={
                            noPendingValues && isPendingChange(resignationDate, noPendingValues?.resignationDate)
                        }
                    />
                </StackPanel>
            </DoubleColumnLayout>
            <StackPanel itemGap={SPACING.MD}>
                {isUnfinishedRegistration && (
                    <Text
                        horizontalAlignment='center'
                        verticalAlignment='center'
                        textAlignment='center'
                        variant='errorBold'>
                        Unfinished Authorized Person registration can't be saved, please set name and title
                    </Text>
                )}
                {isEditable && <DeleteButton isDeleted={isDeleted} onClick={setIsDeleted} />}
            </StackPanel>
        </StackPanel>
    );
}
