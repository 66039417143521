import { DateTimeField, Debugger, Expander, Footer, Form, Select } from 'components';
import { GridLayout, SPACING } from 'Utilities/Layout';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';

import ActionPanel from './components/ActionPanel/ActionPanel';
import { CompletionState } from 'models/LegalEntityRequest/DefaultValues';
import { LegalEntityDissolutionBDGProps } from './model';
import PageUpdateInfo from '../components/PageUpdateInfo';
import React from 'react';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import { convertToLocalDate } from 'Utilities/date';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useDissolutionBDGValidation from 'modules/LegalEntityCreation/validation/use-dissolution-bdg-validation';
import useLastCompletedTask from '../hooks/use-last-completed-task';
import { useLegalEntityDissolutionBDG } from '../../context/Provider';

export default function LegalEntityDissolutionBDG(props: LegalEntityDissolutionBDGProps) {
    const context = useLegalEntityDissolutionBDG();
    const {
        state: {
            data: {
                values: { entityOid, savedAt, savedBy },
            },
        },
        getValue,
        setValue,
    } = context;
    const {
        state: { legalEntityDissolutionParentWorkItem },
    } = useCreationViewContext();

    const lastCompletedTask = useLastCompletedTask({
        workItem: legalEntityDissolutionParentWorkItem,
        taskType: 'BdgSubmitTaskType',
        saved: {
            savedBy,
            savedAt,
        },
    });

    useDissolutionBDGValidation();

    const onDateChanged = (value?: Date | string | undefined) => {
        setValue('dissolutionDate', convertToLocalDate(value));
    };

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Dissolution_BDG} />
            <Form>
                <Expander
                    header='Legal Entity Dissolution - Data Governance'
                    requestStep={RequestStepsView.Dissolution_BDG}
                    content={
                        <StackPanel itemGap={SPACING.XL} margin={`${SPACING.XL}px 0 0 0`}>
                            <GridLayout columns='2fr 1fr'>
                                <StackPanel>
                                    <TextBlock
                                        text='Reach out to the Registered Agent for the Dissolution Document.'
                                        toolTip='Registered Agent will send the Dissolution Document without the authorized signature back.'
                                        margin={`${SPACING.XS}px 0 0 0`}
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    {...getValue('registeredAgent')}
                                    isPendingChange={false}
                                />
                                <StackPanel>
                                    <TextBlock
                                        text='Get authorized signature(s) for the Dissolution Document and send signed document back to the Registered Agent.'
                                        toolTip='Email the authorized personal to get signature(s) on the Dissolution Document. If no authorized personal listed, reach out to the Legal team to obtain signature(s). After obtaining all required signatures, send the Dissolution Document back to the Registered Agent.'
                                        toolTipService={{ showDuration: 10000 }}
                                        margin={`${SPACING.XXS}px 0 0 0`}
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    {...getValue('signatures')}
                                    isPendingChange={false}
                                />
                                <StackPanel>
                                    <TextBlock
                                        text='Receive State Jurisdiction Document and Notarized Dissolution Document from the Registered Agent.'
                                        toolTip='The Registered Agent will file the signed Dissolution Document with the state/jurisdiction, and receive 2 documents back: 1) Signed State Jurisdiction Document, and 2) the Dissolution Document that is stamped/notarized. The Registered Agent will then send these two documents back.'
                                        toolTipService={{ showDuration: 10000 }}
                                        margin={`${SPACING.XXS}px 0 0 0`}
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    {...getValue('stateJurisdiction')}
                                    isPendingChange={false}
                                />
                                <StackPanel>
                                    <TextBlock text='Receive final Dissolution Confirmations ' />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    {...getValue('finalConfirmations')}
                                    isPendingChange={false}
                                />
                                <StackPanel>
                                    <TextBlock
                                        text='Update the legal entity formation details.'
                                        toolTip='Update all necessary documents and inform all stakeholders that the entity has been legally dissolved.'
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    {...getValue('updateDetails')}
                                    isPendingChange={false}
                                />
                                <StackPanel>
                                    <TextBlock
                                        text='Dissolution Date'
                                        toolTip='Please enter the official dissolution date for the entity.'
                                    />
                                </StackPanel>
                                <DateTimeField
                                    {...getValue('dissolutionDate')}
                                    onValueChanged={onDateChanged}
                                    isPendingChange={false}
                                />
                            </GridLayout>
                        </StackPanel>
                    }
                />
                <PageUpdateInfo lastCompletedTask={lastCompletedTask} />
            </Form>
            {props.mode === 'Edit' && (
                <Footer>
                    <ActionPanel entityOId={entityOid} {...props} />
                </Footer>
            )}
        </>
    );
}
