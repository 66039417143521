import { DocumentType, documentTypes } from '../../../models/shared/refData/NewDocumentType';
import { SECTION_NAMES, SectionName } from '../models';

import { COUNTRIES_WITHOUT_REGISTERED_AGENTS } from '../../../models';
import { LegalTypes } from '../../../models/LegalEntityRequest/DefaultValues';
import { getValuesOf } from 'Utilities/object';
import { taxClassificationDocumentTypes } from '../LegalEntityTaxClassification/model';

const { Corporation, LimitedLiabilityCompany, LimitedPartnership, Trust, Icav } = LegalTypes;
const {
    ActionOfSoleIncorporator,
    Bylaws,
    CertificateOfNameChange,
    CertificateIncorporation,
    CertificateLimitedPartnership,
    CertificateRegistration,
    ExtractsFromPublicRegister,
    FormationDocument,
    LpllcAgreement,
    MemorandumArticleAssociation,
    RegistrationDeclaration,
    TrustAgreement,
    TrustDeed,
    RegisterOfDirectorsAndOfficers,
    Resolution,
    RegisterOfMember,
    OfficerCertificate,
    CertificateOfIndependentDirector,
    Prospectus,
    PowerOfAttorney,
    BoardMinutesAndResolutions,
    ShareCertificate,
    ShareTransfer,
    ShareholderMinutes,
    RegulatoryReturns,
    StructureChart,
    ConsentToUseName,
    ApplicationAuthority,
    Notice,
    PartnershipAgreement,
    CertificateofGoodStanding,
    Affidavit,
    IncumbencyCertificate,
    CertificateofContinuance,
    CertificateOfDeRegistration,
    DissolutionDocument,
    DeclarationofTrust,
    LetterofResignation,
    ShareholdersRegister,
    LoanAgreement,
    Other,
    AnnualReturn,
    ArticlesofIncorporation,
    AssignmentofManagementAgreement,
    CertificateofChangeofRegisteredAgent,
    CertificateofRegisteredSeal,
    CertificateofTrust,
    DeedofVariation,
    Email,
    MergerDocuments,
    OperatingAgreement,
    Section10LPLaw,
    Section9LPLaw,
    ShareCharge,
    ShareholdersAgreement,
    SolvencyCertificate,
    TrustDoc,
} = documentTypes;

export const documentTypesForBluePrint = [
    Notice,
    PartnershipAgreement,
    CertificateofGoodStanding,
    Affidavit,
    IncumbencyCertificate,
    CertificateofContinuance,
    CertificateOfDeRegistration,
    DissolutionDocument,
    DeclarationofTrust,
    LetterofResignation,
    ShareholdersRegister,
    LoanAgreement,
    Other,
    AnnualReturn,
    ArticlesofIncorporation,
    AssignmentofManagementAgreement,
    CertificateofChangeofRegisteredAgent,
    CertificateofRegisteredSeal,
    CertificateofTrust,
    DeedofVariation,
    Email,
    MergerDocuments,
    OperatingAgreement,
    Section10LPLaw,
    Section9LPLaw,
    ShareCharge,
    ShareholdersAgreement,
    SolvencyCertificate,
    TrustDoc,
];

const documentTypesForAllLegalType = [
    RegisterOfDirectorsAndOfficers,
    CertificateOfNameChange,
    ExtractsFromPublicRegister,
    Resolution,
    RegisterOfMember,
    PowerOfAttorney,
    BoardMinutesAndResolutions,
    ShareCertificate,
    ShareTransfer,
    ShareholderMinutes,
    RegulatoryReturns,
    ...documentTypesForBluePrint,
];

const defaultDocumentTypes = [
    ActionOfSoleIncorporator,
    Bylaws,
    CertificateIncorporation,
    CertificateLimitedPartnership,
    CertificateRegistration,
    FormationDocument,
    LpllcAgreement,
    MemorandumArticleAssociation,
    RegistrationDeclaration,
    ...documentTypesForAllLegalType,
];

export function getDocumentTypes(legalType?: number): DocumentType[] {
    let documentTypes = [];
    switch (legalType) {
        case Corporation: {
            documentTypes = [
                ActionOfSoleIncorporator,
                Bylaws,
                CertificateIncorporation,
                MemorandumArticleAssociation,
                RegistrationDeclaration,
                ...documentTypesForAllLegalType,
            ];
            break;
        }
        case LimitedLiabilityCompany: {
            documentTypes = [
                CertificateOfIndependentDirector,
                CertificateRegistration,
                FormationDocument,
                LpllcAgreement,
                OfficerCertificate,
                ...documentTypesForAllLegalType,
            ];
            break;
        }
        case LimitedPartnership: {
            documentTypes = [
                CertificateOfIndependentDirector,
                CertificateLimitedPartnership,
                CertificateRegistration,
                LpllcAgreement,
                OfficerCertificate,
                ...documentTypesForAllLegalType,
            ];
            break;
        }
        case Trust: {
            documentTypes = [TrustAgreement, TrustDeed, ...documentTypesForAllLegalType];
            break;
        }
        case Icav: {
            documentTypes = [...defaultDocumentTypes, Prospectus];
            break;
        }
        default: {
            documentTypes = defaultDocumentTypes;
        }
    }
    return documentTypes.sort((a: DocumentType, b: DocumentType) => {
        if (a.Value < b.Value) {
            return -1;
        }
        if (a.Value > b.Value) {
            return 1;
        }
        return 0;
    });
}

export function getRegisteredAgents(country?: number) {
    return country ? !getValuesOf(COUNTRIES_WITHOUT_REGISTERED_AGENTS).includes(country) : true;
}

export function getDocumentTypesForTreeView(sectionName?: SectionName): DocumentType[] {
    let documentTypes = [] as DocumentType[];

    switch (sectionName) {
        case SECTION_NAMES.INFORMATION:
        case SECTION_NAMES.CORPORATE_TRANSPARENCY: {
            documentTypes = [
                StructureChart,
                ActionOfSoleIncorporator,
                Bylaws,
                CertificateIncorporation,
                CertificateOfNameChange,
                ExtractsFromPublicRegister,
                MemorandumArticleAssociation,
                RegistrationDeclaration,
                RegisterOfDirectorsAndOfficers,
                Resolution,
                RegisterOfMember,
                BoardMinutesAndResolutions,
                ShareTransfer,
                ShareCertificate,
                ShareholderMinutes,
                PowerOfAttorney,
                RegulatoryReturns,
                CertificateRegistration,
                FormationDocument,
                LpllcAgreement,
                OfficerCertificate,
                CertificateOfIndependentDirector,
                CertificateLimitedPartnership,
                TrustAgreement,
                TrustDeed,
                ...documentTypesForBluePrint,
            ];
            break;
        }
        case SECTION_NAMES.FOREIGN_REGISTRATION:
        case SECTION_NAMES.FOREIGN_REGISTRATION_UPDATE: {
            documentTypes = [
                ActionOfSoleIncorporator,
                Bylaws,
                CertificateIncorporation,
                CertificateOfNameChange,
                ExtractsFromPublicRegister,
                MemorandumArticleAssociation,
                RegistrationDeclaration,
                RegisterOfDirectorsAndOfficers,
                Resolution,
                RegisterOfMember,
                BoardMinutesAndResolutions,
                ShareTransfer,
                ShareCertificate,
                ShareholderMinutes,
                PowerOfAttorney,
                RegulatoryReturns,
                CertificateRegistration,
                FormationDocument,
                LpllcAgreement,
                OfficerCertificate,
                CertificateOfIndependentDirector,
                CertificateLimitedPartnership,
                TrustAgreement,
                TrustDeed,
                ...documentTypesForBluePrint,
            ];
            break;
        }
        case SECTION_NAMES.TAX_CLASSIFICATION: {
            documentTypes = taxClassificationDocumentTypes;
            break;
        }
        case SECTION_NAMES.OWNERSHIP_INFORMATION_STANDALONE:
        case SECTION_NAMES.OWNERSHIP_INFORMATION: {
            documentTypes = [
                Bylaws,
                RegisterOfMember,
                Resolution,
                BoardMinutesAndResolutions,
                ShareTransfer,
                ShareCertificate,
                ShareholderMinutes,
                PowerOfAttorney,
                RegulatoryReturns,
                RegisterOfDirectorsAndOfficers,
                LpllcAgreement,
                FormationDocument,
                CertificateOfIndependentDirector,
                OfficerCertificate,
                TrustDeed,
                TrustAgreement,
                ConsentToUseName,
                ApplicationAuthority,
                RegisterOfMember,
                ...documentTypesForBluePrint,
            ];
            break;
        }
    }

    return documentTypes;
}
