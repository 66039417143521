import { BillingOrProjectCoding, LegalEntityDissolutionFinanceProps } from './model';
import { BooleanField, Debugger, Expander, Footer, Form, Select, TextField } from 'components';
import { CompletionState, CompletionStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import { DoubleColumnLayout, SPACING } from 'Utilities/Layout';

import ActionPanel from './components/ActionPanel/ActionPanel';
import { DISSOLUTION_FINANCE_STATE } from 'models/LegalEntityRequest/Workflow';
import DissolutionRejectReason from 'modules/LegalEntityCreation/Shared/DissolutionRejectReason';
import PageUpdateInfo from '../components/PageUpdateInfo';
import React from 'react';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import { StackPanel } from '@bxgrandcentral/controls';
import { WorkflowType } from 'models/LegalEntityRequest/Workflow/WorkflowTypes';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useDissolutionFinanceValidation from 'modules/LegalEntityCreation/validation/use-dissolution-finance-validation';
import useLastCompletedTask from '../hooks/use-last-completed-task';
import { useLegalEntityDissolutionFinance } from '../../context/Provider';

export default function LegalEntityDissolutionFinance(props: LegalEntityDissolutionFinanceProps) {
    const context = useLegalEntityDissolutionFinance();
    const {
        state: {
            data: {
                values: { entityOid, isAuditRequired, cashPositionProjectCodingType, savedAt, savedBy },
            },
        },
        getValue,
        setValue,
    } = context;

    const { workItem } = props;
    const {
        state: { legalEntityDissolutionFinanceWorkItem },
    } = useCreationViewContext();
    const lastCompletedTask = useLastCompletedTask({
        workItem: legalEntityDissolutionFinanceWorkItem,
        saved: {
            savedBy,
            savedAt,
        },
    });

    useDissolutionFinanceValidation();

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Dissolution_Finance} />
            <Form>
                <Expander
                    header='Complete Liquidating Audit'
                    requestStep={RequestStepsView.Dissolution_Finance}
                    content={
                        <StackPanel itemGap={SPACING.SM}>
                            <DoubleColumnLayout>
                                <BooleanField
                                    margin={`0 0 ${SPACING.MD}px 0`}
                                    label='Is final liquidating audit required?'
                                    labelToolTip={{
                                        component:
                                            'If yes, then complete final liquidating audit tasks below. If no, skip to the next section.',
                                        options: { showDuration: 5000 },
                                    }}
                                    {...getValue('isAuditRequired')}
                                    onValueChanged={(newValue) => {
                                        setValue('isAuditRequired', newValue);
                                        setValue('auditFinalBilling', CompletionStateKeys.Incomplete);
                                        setValue('auditFinalPaymentTimeline', CompletionStateKeys.Incomplete);
                                    }}
                                    isPendingChange={false}
                                />
                                <div></div>
                            </DoubleColumnLayout>

                            {isAuditRequired && (
                                <DoubleColumnLayout>
                                    <StackPanel itemGap={SPACING.SM}>
                                        <Select
                                            label='Confirm final audited financial statements and final billing'
                                            labelToolTip={{
                                                component:
                                                    'Discuss with the Audit team on final audited financial statements and final billing.',
                                            }}
                                            itemsSource={CompletionState}
                                            isRequired
                                            {...getValue('auditFinalBilling')}
                                            isPendingChange={false}
                                        />
                                    </StackPanel>
                                    <StackPanel itemGap={SPACING.SM}>
                                        <Select
                                            label='Confirm final distribution and expense repayment timeline'
                                            labelToolTip={{
                                                component:
                                                    'Discuss timeline for final distribution and expense repayment.',
                                            }}
                                            itemsSource={CompletionState}
                                            isRequired
                                            {...getValue('auditFinalPaymentTimeline')}
                                            isPendingChange={false}
                                        />
                                    </StackPanel>
                                </DoubleColumnLayout>
                            )}
                        </StackPanel>
                    }
                />
                <Expander
                    header='Cash Position and Expected Remaining Expenses'
                    requestStep={RequestStepsView.Dissolution_Finance}
                    content={
                        <>
                            <DoubleColumnLayout>
                                <StackPanel itemGap={SPACING.SM}>
                                    <Select
                                        label='Settle all receivables and liabilities'
                                        labelToolTip={{
                                            component:
                                                'These include outstanding intercompany and P-code receivables/liabilities. Assess whether capital injection or debt forgiveness is needed in case the entity does not have sufficient cash to settle liabilities. Reach out to responsible parties for any outstanding balances and settle the balances as appropriate.',
                                        }}
                                        itemsSource={CompletionState}
                                        isRequired
                                        {...getValue('cashPositionSettleReceivables')}
                                        isPendingChange={false}
                                    />
                                    <Select
                                        label='Determine if cash at the entity/fund level is enough to paydown actual and projected liabilities or if additional cash is to be withheld from the final distribution'
                                        labelToolTip={{
                                            component: 'Analyze the current cash position.',
                                        }}
                                        itemsSource={CompletionState}
                                        isRequired
                                        height={120}
                                        {...getValue('cashPositionDeterminePaydown')}
                                        isPendingChange={false}
                                    />
                                </StackPanel>
                                <StackPanel itemGap={SPACING.SM}>
                                    <Select
                                        label='Project cash needed for future expenses'
                                        labelToolTip={{
                                            component:
                                                'Future expenses include audit, tax, legal, wipro, bank fees, and registration filing fees.',
                                        }}
                                        itemsSource={CompletionState}
                                        isRequired
                                        {...getValue('cashPositionProjectCash')}
                                        isPendingChange={false}
                                    />
                                    <Select
                                        label='Add billing or project coding for any future expenses so that there is a system record of where to charge any expenses that come in'
                                        labelToolTip={{
                                            component:
                                                "This should be a billing project code (p-code) on the Advisor's books or another active Oracle code for an entity that is not dissolved in that complex.",
                                        }}
                                        itemsSource={BillingOrProjectCoding}
                                        {...getValue('cashPositionProjectCodingType')}
                                        onValueChanged={(newValue) => {
                                            setValue('cashPositionProjectCodingType', newValue);
                                            setValue('cashPositionProjectCoding', undefined);
                                        }}
                                        isPendingChange={false}
                                    />
                                    <StackPanel margin={`${SPACING.LG}px 0 0 0`}>
                                        {cashPositionProjectCodingType ? (
                                            <TextField
                                                label='New Billing/Project Code'
                                                isRequired={!!cashPositionProjectCodingType}
                                                {...getValue('cashPositionProjectCoding')}
                                                isPendingChange={false}
                                            />
                                        ) : (
                                            <div />
                                        )}
                                    </StackPanel>
                                </StackPanel>
                            </DoubleColumnLayout>
                        </>
                    }
                />
                <Expander
                    header='Liabilities'
                    requestStep={RequestStepsView.Dissolution_Finance}
                    content={
                        <DoubleColumnLayout>
                            <StackPanel itemGap={SPACING.SM}>
                                <Select
                                    label='Pay down all outstanding liabilities'
                                    labelToolTip={{
                                        component:
                                            "Pay down all liabilities. All dissolved legal entities' balances should be zero. All dissolved legal entities' WSS bank account balances should be zero.",
                                    }}
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('liabilities')}
                                    isPendingChange={false}
                                />
                            </StackPanel>
                            <StackPanel itemGap={SPACING.SM}></StackPanel>
                        </DoubleColumnLayout>
                    }
                />
                <Expander
                    header='Final Payments Distribution'
                    requestStep={RequestStepsView.Dissolution_Finance}
                    content={
                        <DoubleColumnLayout>
                            <StackPanel itemGap={SPACING.SM}>
                                <Select
                                    label='Draft of final distribution to be approved by all necessary parties such as regional finance team, valuations team, upper management, legal, and tax'
                                    labelToolTip={{
                                        component:
                                            'Confirm final distribution and expense repayment timeline. All necessary holdbacks at the shareholder/investor level to be taken into consideration.',
                                    }}
                                    itemsSource={CompletionState}
                                    isRequired
                                    height={100}
                                    {...getValue('finalPaymentApproved')}
                                    isPendingChange={false}
                                />
                            </StackPanel>
                            <StackPanel itemGap={SPACING.SM} margin={`${SPACING.SM}px 0 0`}>
                                <Select
                                    label='Distribute final payments'
                                    labelToolTip={{
                                        component: 'Distribute final payments to investors and shareholders.',
                                    }}
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('finalPaymentDistributed')}
                                    isPendingChange={false}
                                />
                            </StackPanel>
                        </DoubleColumnLayout>
                    }
                />
                <Expander
                    header='Approver Notes and Status'
                    toolTip='This section is used to enter approver notes that help other approvers. It is also used to communicate in-progress status. Please enter in-progress status and hit Save during the in-progress steps.'
                    toolTipService={{ showDuration: 10000 }}
                    content={
                        <div className='text-area-hide-scrollbar'>
                            <TextField
                                minHeight={120}
                                maxHeight={240}
                                variant='default'
                                textWrapping='wrap'
                                padding={`${SPACING.XS}px 0`}
                                canUndo={false}
                                {...getValue('approverNotes')}
                                isPendingChange={false}
                            />
                        </div>
                    }
                />
                <DissolutionRejectReason
                    workflowType={WorkflowType.LegalEntityDissolutionFinanceWorkflowType}
                    currentWorkItemState={workItem?.workItemState}
                    workItemStateToExclude={DISSOLUTION_FINANCE_STATE.DISSOLUTION_APPROVED}
                />
                <PageUpdateInfo lastCompletedTask={lastCompletedTask} />
            </Form>
            {props.mode === 'Edit' && (
                <Footer>
                    <ActionPanel entityOId={entityOid} {...props} />
                </Footer>
            )}
        </>
    );
}
