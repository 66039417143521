import { DeleteButton, FileUpload, Select, Text } from 'components';
import { DoubleColumnLayout, HORIZONTAL_FORM_PADDING, SPACING } from '../../../../../Utilities/Layout';
import React, { useEffect, useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { getDocumentTypes, getRegisteredAgents } from '../../utils';
import { isCaymanIslands, isUnitedStates } from '../../../../../models';
import {
    removeDocumentSection,
    updateDocumentSection,
    updateStoredDocument,
} from 'modules/LegalEntityCreation/context/actions';

import { ForeignRegistration as ForeignRegistrationModel } from '../../model';
import { GlobalState } from 'GlobalState';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { SECTION_NAME_SEPARATOR } from 'components/controls/FileUpload/models';
import { ValidationErrors } from '../../../context/model';
import { isEmpty } from '../../../../../Utilities/Validations';
import { isNil } from 'lodash';
import { removeAt } from 'Utilities/array';
import useDocumentPreparationRequirements from '../../../../../hooks/use-document-preparation-requirements';
import { useLegalEntityDocumentPreparation } from '../../../context/Provider';
import { useReferenceData } from '../../../../../api';

type Props = {
    index: number;
    validation: ValidationErrors<ForeignRegistrationModel>;
};

export default function ForeignRegistration({ index, validation = {} }: Props) {
    const context = useLegalEntityDocumentPreparation();

    const {
        state: {
            data: {
                values: { entityOid, foreignRegistrations = [], legalType },
            },
            documents: { sections },
            isEditable,
            version,
        },
        dispatch,
        setValue,
    } = context;

    const { registeredAgent, country, state, isDeleted, key: taxRegistrationId } = foreignRegistrations[index];

    const {
        data: { State, RegisteredAgent, Country },
    } = useReferenceData();

    const filteredStates = useMemo(() => State?.filter(({ ParentId }) => ParentId === `${country}`), [State, country]);

    const countryHasRegisteredAgent = useMemo(() => getRegisteredAgents(country), [country]);
    const sectionName = useMemo(
        () => `${SECTION_NAMES.FOREIGN_REGISTRATION}${SECTION_NAME_SEPARATOR}${index}`,
        [index]
    );
    const section = sections[sectionName] ?? { errorMessage: '' };

    const documentTypes = useMemo(() => getDocumentTypes(legalType), [legalType]);
    const { documentTypeError } = useDocumentPreparationRequirements({
        country,
        legalType,
        section,
    });

    useEffect(() => {
        updateDocumentSection(dispatch, sectionName, {
            additionalErrorMessage: !!documentTypeError ? documentTypeError : '',
            isRequired: !!documentTypeError,
        });
    }, [dispatch, documentTypeError, section.errorMessage, sectionName]);

    async function setIsDeleted() {
        section.storedDocuments?.forEach(
            ({
                document: {
                    documentOId: { value },
                },
            }) => {
                updateStoredDocument(dispatch, sectionName, value, { isDeleted: !isDeleted });
            }
        );
        if (taxRegistrationId) {
            updateDocumentSection(dispatch, sectionName, {
                pendingDocuments: [],
                isDeleted: !isDeleted,
            });
            foreignRegistrations[index].isDeleted = !isDeleted;
            setValue('foreignRegistrations', Array.of(...foreignRegistrations));
        } else {
            await GlobalState.openDialog({
                variant: 'info',
                title: 'Delete Registration',
                content:
                    'Are you sure you want to remove this registration? Any information entered on this unsaved registration will be lost.',
            }).then((response) => {
                if (response) {
                    setValue('foreignRegistrations', removeAt(foreignRegistrations, index));
                    removeDocumentSection(dispatch, sectionName);
                }
            });
        }
    }

    const isEmptyRegistration = useMemo(
        () => isNil(country) && isNil(state) && isNil(registeredAgent),
        [country, registeredAgent, state]
    );

    const isStateRequired = useMemo(() => isUnitedStates(country) || isCaymanIslands(country), [country]);

    return (
        <StackPanel padding={HORIZONTAL_FORM_PADDING}>
            {!taxRegistrationId && (
                <TextBlock styleName='heading4Style' margin={`0 0 ${SPACING.SM}px 0 `}>
                    New Registration
                </TextBlock>
            )}
            <StackPanel itemGap={SPACING.SM}>
                <StackPanel itemGap={SPACING.SM} {...(isDeleted && { opacity: 0.5 })}>
                    <Select
                        label='Registered Agent'
                        labelToolTip={{ component: 'Select the foreign registered agent' }}
                        itemsSource={RegisteredAgent}
                        isRequired
                        showSearchBox
                        isEditable={isEditable && !isDeleted && countryHasRegisteredAgent}
                        version={version}
                        value={registeredAgent}
                        onValueChanged={(newValue) => {
                            const updated = [...foreignRegistrations];
                            updated[index].registeredAgent = newValue;
                            setValue('foreignRegistrations', updated);
                        }}
                        validationError={validation.registeredAgent}
                    />
                    <DoubleColumnLayout>
                        <Select
                            label='Country'
                            labelToolTip={{ component: 'Foreign Registration Country' }}
                            itemsSource={Country}
                            isRequired
                            showSearchBox
                            isEditable={isEditable && !isDeleted}
                            version={version}
                            value={country}
                            onValueChanged={(newValue) => {
                                const updated = [...foreignRegistrations];
                                updated[index].country = newValue;
                                updated[index].state = undefined;
                                if (!getRegisteredAgents(newValue)) {
                                    updated[index].registeredAgent = undefined;
                                }
                                setValue('foreignRegistrations', updated);
                            }}
                            validationError={validation.country}
                        />
                        <Select
                            label='State'
                            labelToolTip={{ component: 'Foreign Registration State' }}
                            itemsSource={filteredStates}
                            showSearchBox
                            isRequired={isStateRequired}
                            isEditable={isEditable && !isEmpty(filteredStates) && !isDeleted}
                            version={version}
                            value={state}
                            onValueChanged={(newValue) => {
                                const updated = [...foreignRegistrations];
                                updated[index].state = newValue;
                                setValue('foreignRegistrations', updated);
                            }}
                            validationError={validation.state}
                        />
                    </DoubleColumnLayout>
                </StackPanel>
                <StackPanel styleOverrides={{ marginTop: `${SPACING.MD}px` }}>
                    <FileUpload
                        entityId={entityOid}
                        sectionName={sectionName}
                        documentTypes={documentTypes}
                        isRequired={!!documentTypeError && !isDeleted}
                        taxRegistrationId={taxRegistrationId}
                        showStoredDocuments={!!taxRegistrationId}
                        showStampedDocuments={false}
                        isDisabled={isDeleted}
                        context={context}
                        effectiveDate='optional'
                    />
                </StackPanel>
                <StackPanel itemGap={SPACING.MD}>
                    {isEmptyRegistration && (
                        <Text
                            horizontalAlignment='center'
                            verticalAlignment='center'
                            textAlignment='center'
                            variant='errorBold'>
                            Empty registration can't be saved, please set at least one attribute
                        </Text>
                    )}
                </StackPanel>
                {isEditable && (
                    <DeleteButton
                        isDeleted={isDeleted}
                        onClick={setIsDeleted}
                        isEnabled={foreignRegistrations.filter(({ isDeleted }) => !isDeleted).length < 3 || !isDeleted}
                    />
                )}
            </StackPanel>
        </StackPanel>
    );
}
