import { LegalEntityDissolutionTask } from 'models/LegalEntityRequest/Workflow';
import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { Text } from 'components';

type Props = {
    lastCompletedTask?: LegalEntityDissolutionTask;
};

export default function PadeUpdateInfo({ lastCompletedTask }: Props) {
    if (!lastCompletedTask?.modifier || !lastCompletedTask?.modified) {
        return null;
    }

    return (
        <Text horizontalAlignment='center' verticalAlignment='center' textAlignment='center'>
            <Text margin={`${SPACING.XL}px`}>Last Updated by</Text>
            <Text margin={`${SPACING.XL}px`}>{lastCompletedTask?.modifier}</Text>
            <Text margin={`${SPACING.XL}px`}>{new Date(lastCompletedTask?.modified).toUTCYearMonthDay()}</Text>
        </Text>
    );
}
