import { CompletionStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import { LegalEntityDissolutionBDGData } from '../model';
import { useCallback } from 'react';

export default function useDissolutionBDGAutopopulateData(data?: LegalEntityDissolutionBDGData) {
    const calculateDefaultValues = useCallback(
        () => ({
            registeredAgent: CompletionStateKeys.Incomplete,
            signatures: CompletionStateKeys.Incomplete,
            stateJurisdiction: CompletionStateKeys.Incomplete,
            finalConfirmations: CompletionStateKeys.Incomplete,
            updateDetails: CompletionStateKeys.Incomplete,
            dissolutionDate: undefined,
        }),
        []
    );

    return { calculateDefaultValues };
}
