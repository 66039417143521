import {
    ConditionalWrapper,
    DateTimeField,
    Debugger,
    Expander,
    FileUpload,
    Footer,
    Form,
    Select,
    TextField,
} from 'components';
import { DoubleColumnLayout, HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import React, { useMemo } from 'react';
import { getDocumentTypes, getDocumentTypesForTreeView } from '../LegalEntityDocumentPreparation/utils';

import RejectReason from '../Shared/RejectReason';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { StackPanel } from '@bxgrandcentral/controls';
import { Task } from 'models/Workflow';
import { capitalizeFirstLetter } from 'Utilities/string';
import { convertToLocalDate } from 'Utilities/date';
import { isLuxembourg } from 'models';
import { useCreationViewContext } from '../LegalEntityCreationView/context/creation-view-context';
import useDomesticRegistrationDetailsValidation from 'modules/LegalEntityCreation/validation/use-domestic-registration-details-validation';
import { useLegalEntityRegistration } from 'modules/LegalEntityCreation/context/Provider';
import { useReferenceData } from 'api';

const REGISTRATION_TYPE = { DOMESTIC: 'domestic', FOREIGN: 'foreign' };
type RegistrationTypeKeys = keyof typeof REGISTRATION_TYPE;
type RegistrationType = (typeof REGISTRATION_TYPE)[RegistrationTypeKeys];

type Props = {
    mode: string;
    type: RegistrationType;
    tasks?: Task[];
    actionPanel?: JSX.Element;
    showDocumentsInReadOnlyTreeView?: boolean;
};

export default function LegalEntityRegistration({
    mode,
    type,
    tasks,
    actionPanel,
    showDocumentsInReadOnlyTreeView,
}: Props) {
    const context = useLegalEntityRegistration();

    const {
        state: {
            data: {
                values: { entityOid, country, registeredAgent, state, key, legalType },
            },
        },
        setValue,
        getValue,
    } = context;

    const {
        data: { State, RegisteredAgent, Country },
    } = useReferenceData();

    const {
        state: { entityCreationWorkItem },
    } = useCreationViewContext();

    useDomesticRegistrationDetailsValidation();

    const sectionName = useMemo(() => {
        switch (type) {
            case REGISTRATION_TYPE.DOMESTIC: {
                return SECTION_NAMES.DOMESTIC_REGISTRATION;
            }
            case REGISTRATION_TYPE.FOREIGN: {
                return SECTION_NAMES.FOREIGN_REGISTRATION;
            }
            default: {
                return null;
            }
        }
    }, [type]);

    const documentTypes = useMemo(() => getDocumentTypes(legalType), [legalType]);
    const treeViewDocumentTypes = getDocumentTypesForTreeView(SECTION_NAMES.FOREIGN_REGISTRATION);

    const documentApproveDate = useMemo(
        () =>
            entityCreationWorkItem?.tasks?.find(
                (task) => task.taskType === 'ReviewDocPrepTaskType' && task.exitCode === 'Approve'
            )?.completed!,
        [entityCreationWorkItem]
    );

    const showFileUpload =
        type === REGISTRATION_TYPE.DOMESTIC || (type === REGISTRATION_TYPE.FOREIGN && mode !== 'Readonly');

    const getLabelTooltip = (label: string) => {
        const labels = {
            [REGISTRATION_TYPE.DOMESTIC]: {
                'Registered Agent': 'Select the registered agent',
                Country: 'Country in which the legal entity is domiciled',
                State: 'State in which the legal entity is domiciled',
                'Registration Number':
                    "Unique identification number for the entity that's used to verify its legal existence",
                'Formation Date': 'Date in which the entity is legally formed',
            },
            [REGISTRATION_TYPE.FOREIGN]: {
                'Registered Agent': 'Select the foreign registered agent',
                Country: 'Foreign Registration Country',
                State: 'Foreign Registration State',
                'Registration Number':
                    "Foreign Registration unique identification number for the entity that's used to verify its legal existence",
                'Formation Date': 'Foreign Registration Formation Date',
            },
        };
        return labels[type][label as keyof (typeof labels)[typeof type]];
    };

    return (
        <>
            <ConditionalWrapper
                condition={type === REGISTRATION_TYPE.DOMESTIC}
                wrapper={(children) => <Form>{children}</Form>}>
                <Debugger inputState={context.state} />
                <Expander
                    header={`${capitalizeFirstLetter(type)} Registration Details`}
                    requestStep={
                        type === REGISTRATION_TYPE.DOMESTIC
                            ? RequestStepsView.Domestic_Registration
                            : RequestStepsView.Foreign_Registration
                    }
                    padding={0}
                    content={
                        <StackPanel itemGap={SPACING.SM}>
                            <StackPanel padding={`0 ${SPACING.XXXL}px`}>
                                <Select
                                    label='Registered Agent'
                                    labelToolTip={{ component: getLabelTooltip('Registered Agent') }}
                                    itemsSource={RegisteredAgent}
                                    value={registeredAgent}
                                    isEditable={false}
                                />
                                <DoubleColumnLayout>
                                    <StackPanel>
                                        <Select
                                            label='Country'
                                            labelToolTip={{
                                                component: getLabelTooltip('Country'),
                                            }}
                                            itemsSource={Country}
                                            value={country}
                                            isEditable={false}
                                        />
                                        <TextField
                                            label='Registration Number'
                                            labelToolTip={getLabelTooltip('Registration Number')}
                                            isRequired={!isLuxembourg(country)}
                                            {...getValue('registrationNumber')}
                                        />
                                    </StackPanel>
                                    <StackPanel>
                                        <Select
                                            label='State'
                                            labelToolTip={{ component: getLabelTooltip('State') }}
                                            itemsSource={State}
                                            value={state}
                                            isEditable={false}
                                        />
                                        <DateTimeField
                                            label='Formation Date'
                                            labelToolTip={getLabelTooltip('Formation Date')}
                                            {...getValue('formationDate')}
                                            onValueChanged={(value) => {
                                                setValue('formationDate', convertToLocalDate(value));
                                            }}
                                            isRequired={!isLuxembourg(country)}
                                        />
                                    </StackPanel>
                                </DoubleColumnLayout>
                            </StackPanel>
                            {sectionName && showFileUpload && (
                                <StackPanel
                                    padding={HORIZONTAL_FORM_PADDING}
                                    styleOverrides={{ marginTop: `${SPACING.MD}px` }}>
                                    <FileUpload
                                        entityId={entityOid}
                                        sectionName={sectionName}
                                        taxRegistrationId={key}
                                        documentTypes={documentTypes}
                                        context={context}
                                        effectiveDate='required'
                                        isRequired
                                        documentApproveDate={documentApproveDate}
                                        isStampedDocumentUpload={mode !== 'Readonly'}
                                    />
                                </StackPanel>
                            )}
                            {type === REGISTRATION_TYPE.FOREIGN && (
                                <StackPanel padding={HORIZONTAL_FORM_PADDING}>
                                    <RejectReason
                                        mode={mode}
                                        taskType='UpdateRequestTaskType'
                                        tasks={tasks}
                                        isExpandable={false}
                                    />
                                </StackPanel>
                            )}
                        </StackPanel>
                    }
                    {...(type === REGISTRATION_TYPE.FOREIGN && { footer: actionPanel })}
                />
                {type === REGISTRATION_TYPE.DOMESTIC && (
                    <RejectReason mode={mode} taskType='TransitionToPostProcess1TaskType' tasks={tasks} />
                )}
            </ConditionalWrapper>
            {mode === 'Readonly' && showDocumentsInReadOnlyTreeView && (
                <Expander
                    header='Existing Documents'
                    requestStep={
                        type === REGISTRATION_TYPE.DOMESTIC
                            ? RequestStepsView.Domestic_Registration
                            : RequestStepsView.Foreign_Registration
                    }
                    content={
                        <FileUpload
                            entityId={entityOid}
                            sectionName={SECTION_NAMES.FOREIGN_REGISTRATION}
                            context={context}
                            documentApproveDate={documentApproveDate}
                            keepDocumentTypes
                            showStampedDocuments={false}
                            showDocumentsInReadOnlyTreeView
                            treeViewDocumentTypes={treeViewDocumentTypes}
                        />
                    }
                />
            )}
            {type === REGISTRATION_TYPE.DOMESTIC && mode !== 'ReadOnly' && <Footer>{actionPanel}</Footer>}
        </>
    );
}
