import { AttributeType, BusinessUnit, KeyValue, KeyValueData } from '../../models';

import { Task } from '../../models/Workflow';
import { TaskTypes } from 'models/LegalEntityRequest/Workflow/TaskTypes';
import { constants } from 'constants/env-config';
import { isNil } from 'lodash';

const { ExternalAdmin, GeneralPartnersAccounting, HedgeFundSolutions, PortfolioCompany, RealState } = KeyValueData;

export function isHedgeFund(value?: number) {
    return value === BusinessUnit.Hedgehog;
}

export function shouldHaveSubBusinessUnit(value?: number) {
    return !isNil(value) && [RealState, GeneralPartnersAccounting, HedgeFundSolutions].includes(value);
}

export function isExternalAdmin(value?: number) {
    return value === ExternalAdmin;
}

export function isPortfolioCompany(value?: number) {
    return value === PortfolioCompany;
}

export function isEditable(mode: string) {
    return ['Create', 'Edit'].includes(mode);
}

export function isRequestNotesVisible(mode: string) {
    return ['Create', 'Edit', 'Approve'].includes(mode);
}

export function getCountryByTaxCodeType(TaxCodeType?: KeyValue[], taxCodeTypeId?: number) {
    const countryId = TaxCodeType?.find(({ Key }) => `${taxCodeTypeId}` === Key.toString())?.ParentId;

    return countryId ? parseInt(countryId) : undefined;
}

export function getRejectReason(taskTypeToFind: TaskTypes, tasks?: Task[]) {
    if (!taskTypeToFind || !tasks?.length) {
        return null;
    }

    const taskWithComment = [...tasks].reverse().find(({ exitCode, customProperties, taskType }) => {
        return taskTypeToFind === taskType && exitCode === 'Reject' && customProperties?.rejectReason?.length;
    });

    return taskWithComment?.customProperties?.rejectReason ?? null;
}

// This is a workaround for the first save at standalone workflows.
// API needs to have all the related object attributes to be sent at the first save, even the ones which are not changed.
export function handleObjectAttributesForSave<T extends Record<string, any>>(storedData: T, changes: T): T {
    const returnData = { ...changes };

    for (const key in storedData) {
        if (storedData.hasOwnProperty(key) && key in AttributeType) {
            returnData[key] = changes.hasOwnProperty(key) ? changes[key] : storedData[key];
        }
    }

    return returnData;
}

export function generateLegalNameValue(userDisplayName = '') {
    const userName = userDisplayName ? `${userDisplayName.split(' ')[0]} - ` : '';
    const today = new Date();
    const date = `${today.getFullYear()}.${today.getMonth() + 1}.${today.getDate()}`;
    const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}:${today.getMilliseconds()}`;
    const dateTime = `${date} ${time}`;

    return `${userName}UI test at ${constants.ENVIRONMENT.toUpperCase()} - ${dateTime}`;
}
