import { VALIDATION_MESSAGES, isEqual, validateRequiredField } from './utils';
import { useCallback, useEffect, useState } from 'react';

import { Address } from 'models';
import { ValidationErrors } from '../context/model';
import { addressTypes } from '../LegalEntityInformation/model';
import { isEmpty } from '../../../Utilities/Validations';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityInformation } from '../context/Provider';

export default function useAddressesValidation() {
    const {
        state: {
            data: {
                values: { addresses },
            },
            isEditable,
            version,
        },
        dispatch,
    } = useLegalEntityInformation();

    const [validations, setValidations] = useState<ValidationErrors<Address>[]>([]);

    const hasAddressDuplication = useCallback(
        (checkedAddress: Address, checkedAddressIndex: number = 0) => {
            if (!addresses?.length) {
                return false;
            }

            return addresses?.some((comparedWithAddress, index) => {
                if (index === checkedAddressIndex || comparedWithAddress.isDeleted) {
                    return false;
                }

                if (
                    isEqual(checkedAddress.countryId, comparedWithAddress.countryId) &&
                    isEqual(checkedAddress.line1, comparedWithAddress.line1) &&
                    isEqual(checkedAddress.line2, comparedWithAddress.line2) &&
                    isEqual(checkedAddress.line3, comparedWithAddress.line3) &&
                    isEqual(checkedAddress.line4, comparedWithAddress.line4) &&
                    isEqual(checkedAddress.city, comparedWithAddress.city) &&
                    isEqual(checkedAddress.state, comparedWithAddress.state) &&
                    isEqual(checkedAddress.stateId, comparedWithAddress.stateId) &&
                    isEqual(checkedAddress.postalCode, comparedWithAddress.postalCode)
                ) {
                    return true;
                }

                return false;
            });
        },
        [addresses]
    );

    const checkAddressType = useCallback(
        (address: Address, index: number = 0) => {
            const requiredError = validateRequiredField(address.addressTypeId);
            if (requiredError) {
                return requiredError;
            }

            const headquarterAddresses = addresses?.filter(
                ({ addressTypeId, isDeleted }) => addressTypeId === addressTypes.headquarterAddress.Key && !isDeleted
            );
            if (!headquarterAddresses?.length) {
                return 'A Headquarter Address is required';
            }
            if (headquarterAddresses.length > 1) {
                return 'Only one Headquarter Address can be submitted';
            }

            const registeredOfficeAddresses = addresses?.filter(
                ({ addressTypeId, isDeleted }) =>
                    addressTypeId === addressTypes.registeredOfficeAddress.Key && !isDeleted
            );
            if (registeredOfficeAddresses && registeredOfficeAddresses.length > 1) {
                return 'Only one Registered Office Address can be submitted';
            }

            if (hasAddressDuplication(address, index)) {
                return 'There is another address with the same values';
            }
        },
        [addresses, hasAddressDuplication]
    );

    useEffect(() => {
        if (isEmpty(addresses)) {
            setValidations([]);
            return;
        }

        setValidations(
            addresses.map((address, index) =>
                address.isDeleted
                    ? {}
                    : {
                          addressTypeId: checkAddressType(address, index),
                          countryId: validateRequiredField(address.countryId),
                      }
            )
        );
    }, [addresses, checkAddressType, dispatch]);

    useEffect(() => {
        if (!isEditable || isEmpty(validations)) {
            updateValidation(dispatch, { addresses: undefined });
            return;
        }

        updateValidation(dispatch, {
            addresses: validations.some((validation) => Object.values(validation).some((value) => value))
                ? VALIDATION_MESSAGES.COMBINED_ERROR
                : undefined,
        });
    }, [validations, dispatch, version, isEditable]);

    return { validations };
}
