import { VALIDATION_MESSAGES, validateDateTimeField, validateRequiredField } from './utils';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AuthorizedPerson } from '../LegalEntityOwnershipInformation/model';
import { ValidationErrors } from '../context/model';
import { isEmpty } from '../../../Utilities/Validations';
import { isNil } from 'lodash';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityOwnerShipInformation } from '../context/Provider';

export default function useAuthorizedPersonValidation() {
    const {
        state: {
            data: {
                values: { authorizedPerson = [] },
            },
            version,
        },
        dispatch,
    } = useLegalEntityOwnerShipInformation();

    const [validations, setValidations] = useState<ValidationErrors<AuthorizedPerson>[]>([]);

    const validateTitle = useCallback(
        (index: number) => {
            const { title, name } = authorizedPerson[index];
            if (isNil(title)) {
                return VALIDATION_MESSAGES.REQUIRED_FIELD;
            }
            return authorizedPerson.some(
                (person, personIndex) =>
                    person.title === title && person.name === name && index !== personIndex && !person.isDeleted
            )
                ? VALIDATION_MESSAGES.SAME_THIRD_PARTY_TITLE
                : undefined;
        },
        [authorizedPerson]
    );

    useEffect(() => {
        if (isEmpty(authorizedPerson)) {
            setValidations([]);
            return;
        }

        setValidations(
            authorizedPerson.map((person, index) => {
                const { appointmentDate, name, resignationDate, isDeleted } = person;
                return isDeleted
                    ? {}
                    : {
                          appointmentDate: validateRequiredField(appointmentDate),
                          resignationDate: validateDateTimeField(resignationDate, {
                              isRequired:
                                  appointmentDate &&
                                  resignationDate &&
                                  new Date(appointmentDate) > new Date(resignationDate),
                              errorMessage: VALIDATION_MESSAGES.APPOINTMENT_AND_RESIGNATION_DATE,
                          }),
                          name: validateRequiredField(name),
                          title: validateTitle(index),
                      };
            })
        );
    }, [authorizedPerson, version, dispatch, validateTitle]);

    const validationMessage = useMemo(() => {
        const hasUnfinishedValidation = validations.some(({ name, title }) => !isNil(name) || !isNil(title));

        if (hasUnfinishedValidation) {
            return VALIDATION_MESSAGES.COMBINED_ERROR;
        }

        const errors = Array.from(
            new Set(
                validations
                    .map((validation) => Object.values(validation))
                    .flat()
                    .filter(Boolean)
            )
        );

        if (isEmpty(errors)) {
            return;
        }

        return errors.filter((error) => error !== VALIDATION_MESSAGES.REQUIRED_FIELD).length > 0
            ? VALIDATION_MESSAGES.COMBINED_ERROR
            : VALIDATION_MESSAGES.REQUIRED_FIELD;
    }, [validations]);

    useEffect(() => {
        if (isEmpty(validations)) {
            updateValidation(dispatch, { authorizedPerson: undefined });
            return;
        }

        updateValidation(dispatch, {
            authorizedPerson: validationMessage,
        });
    }, [validations, version, dispatch, validationMessage]);

    return { validations };
}
