import { Border, SPACING } from '../../../../Utilities/Layout';
import { FileListLayout, FileListTreeViewHeader } from '../FileUpload.styled';
import { FileListProps, StoredDocumentModel } from '../models';
import { Grid, StackPanel, TextBlock, ThemeManager, ToolBarButton } from '@bxgrandcentral/controls';
import React, { Fragment, useEffect, useState } from 'react';

import { DocumentTypesData } from 'models/shared/refData/DocumentType';
import { GlobalState } from '../../../../GlobalState';
import { IDocumentService } from '../../../../services/interfaces/IDocumentService';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { Spinner } from 'components';
import StoredDocument from './StoredDocument';
import { isEmpty } from '../../../../Utilities/Validations';
import { noop } from 'lodash';
import { saveAs } from 'file-saver';

type DocumentsForTypes = {
    docs: StoredDocumentModel[];
    isOpen: boolean;
};

export default function FileListTreeView(props: FileListProps) {
    const {
        entityOId,
        isEditable = false,
        documents = [],
        header = 'Document types / documents',
        noDocumentLabel = 'There are no documents',
        isFetching,
        isStampedDocumentUpload,
    } = props;

    const service = ServiceLocator.container.resolve(IDocumentService);

    const [clickedDocumentIds, setClickedDocumentIds] = useState<number[]>([]);
    const [hasOpenedItem, setHasOpenedItem] = useState(false);
    const [documentsByType, setDocumentsByType] = useState<Map<string, DocumentsForTypes>>(
        new Map<string, DocumentsForTypes>([])
    );

    async function downloadDocument(documentId: number, fileName: string) {
        setClickedDocumentIds((current) => current.concat(documentId));
        await service
            .DownloadFile(entityOId, documentId)
            .then((response) => {
                setClickedDocumentIds((current) => current.filter((id) => id !== documentId));
                const blob = new Blob([response], { type: 'application/octet-stream' });
                saveAs(blob, fileName);
            })
            .catch(() => {
                GlobalState.ShowMessageBox('ERROR', 'Failed to download documents.');
            });
    }

    const {
        activeTheme: {
            colors: { accentColor, disabledColor, normalControlBorderColor },
        },
    } = ThemeManager;

    const collapseTypes = (typeId: string) => {
        const types = new Map(documentsByType);
        const selectedType = types.get(typeId);
        if (selectedType) {
            selectedType.isOpen = !selectedType.isOpen;
            types.set(typeId, selectedType);

            setDocumentsByType(types);
        }
    };

    const handleCollapseAll = () => {
        const types = new Map(documentsByType);
        for (const [key, documentType] of types.entries()) {
            documentType.isOpen = !hasOpenedItem;
        }
        setDocumentsByType(types);
    };

    useEffect(() => {
        let isThereOpenItems = false;
        for (const [key, documentType] of documentsByType.entries()) {
            if (documentType.isOpen) {
                isThereOpenItems = true;
                break;
            }
        }
        setHasOpenedItem(isThereOpenItems);
    }, [documentsByType]);

    useEffect(() => {
        const docsByType: Map<string, DocumentsForTypes> = new Map<string, DocumentsForTypes>();

        documents.forEach((document) => {
            if (document.document.coreDocumentTypeId) {
                const key = String(document.document.coreDocumentTypeId);
                const existingDocs = docsByType.get(key)?.docs ?? [];

                existingDocs.push(document);
                docsByType.set(key, { docs: existingDocs, isOpen: false });
            }
        });

        setDocumentsByType(docsByType);
    }, [documents]);

    if (isFetching) {
        return null;
    }

    if (isEmpty(documents)) {
        return noDocumentLabel ? (
            <TextBlock
                text={noDocumentLabel}
                styleName='captionStyle'
                verticalAlignment='center'
                textAlignment='center'
            />
        ) : null;
    }

    return (
        <Grid itemGap={SPACING.MD}>
            <FileListTreeViewHeader>
                <ToolBarButton
                    foregroundColor={accentColor}
                    fontSize={14}
                    icon={hasOpenedItem ? 'CalculatorSubtract' : 'Add'}
                    onClick={() => handleCollapseAll()}
                    margin={`-${SPACING.XS}px 0 0 0`}
                />
                <TextBlock text={header} styleName='captionStyle' />
                <TextBlock text={hasOpenedItem ? 'Effective Date' : ''} styleName='captionStyle' />
            </FileListTreeViewHeader>
            <Grid itemGap={SPACING.XS}>
                {[...documentsByType.entries()].map(([coreDocumentTypeId, documentsForType]) => (
                    <>
                        <StackPanel orientation='horizontal'>
                            <ToolBarButton
                                foregroundColor={accentColor}
                                fontSize={14}
                                icon={documentsForType.isOpen ? 'CalculatorSubtract' : 'Add'}
                                onClick={() => {
                                    collapseTypes(coreDocumentTypeId);
                                }}
                            />
                            <TextBlock
                                text={DocumentTypesData.find(({ Key }) => Key === Number(coreDocumentTypeId))?.Value}
                                styleName='heading4Style'
                                margin={`${SPACING.XS}px 0 0 ${SPACING.XS}px`}
                            />
                        </StackPanel>
                        {documentsForType.isOpen &&
                            documentsForType.docs.map(
                                (
                                    {
                                        document: {
                                            documentOId: { value },
                                            originalFileName,
                                        },
                                        updates: { isDeleted, sectionName: updatedSectionName },
                                    },
                                    index
                                ) => (
                                    <Fragment key={index}>
                                        <FileListLayout isEditable={isEditable}>
                                            {clickedDocumentIds.find((id) => id === value) ? (
                                                <Spinner />
                                            ) : (
                                                <StackPanel
                                                    orientation='horizontal'
                                                    styleOverrides={{ marginLeft: `${SPACING.XL}px` }}></StackPanel>
                                            )}
                                            <StoredDocument
                                                index={index}
                                                {...props}
                                                documents={documentsForType.docs}
                                                hideDocumentType
                                            />
                                            <ToolBarButton
                                                icon='Download'
                                                fontSize={18}
                                                width={34}
                                                isEnabled={!isDeleted}
                                                foregroundColor={isDeleted ? disabledColor : accentColor}
                                                toolTip='Click to download attachment'
                                                padding={`${SPACING.XS}px 0`}
                                                styleOverrides={{ alignSelf: 'flex-start' }}
                                                onClick={() => downloadDocument(value, originalFileName)}
                                            />
                                        </FileListLayout>
                                    </Fragment>
                                )
                            )}
                    </>
                ))}
            </Grid>
        </Grid>
    );
}
