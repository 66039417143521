import { LegalEntityDTO } from '../../../../models';
import { LegalEntityDissolutionFinanceData } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionFinance/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityDissolutionFinanceData => {
    const { dissolutionData, entity } = entityDto;

    return {
        entityOid: entity.entityOId,
        isAuditRequired: dissolutionData?.dissolutionFinance?.isAuditRequired,
        auditFinalBilling: dissolutionData?.dissolutionFinance?.auditFinalBilling,
        auditFinalPaymentTimeline: dissolutionData?.dissolutionFinance?.auditFinalPaymentTimeline,
        cashPositionSettleReceivables: dissolutionData?.dissolutionFinance?.cashPositionSettleReceivables,
        cashPositionDeterminePaydown: dissolutionData?.dissolutionFinance?.cashPositionDeterminePaydown,
        cashPositionProjectCash: dissolutionData?.dissolutionFinance?.cashPositionProjectCash,
        cashPositionProjectCodingType: dissolutionData?.dissolutionFinance?.cashPositionProjectCodingType,
        cashPositionProjectCoding: dissolutionData?.dissolutionFinance?.cashPositionProjectCoding,
        liabilities: dissolutionData?.dissolutionFinance?.liabilities,
        finalPaymentApproved: dissolutionData?.dissolutionFinance?.finalPaymentApproved,
        finalPaymentDistributed: dissolutionData?.dissolutionFinance?.finalPaymentDistributed,
        approverNotes: dissolutionData?.dissolutionFinance?.approverNotes,
        savedBy: dissolutionData?.dissolutionFinance?.savedBy,
        savedAt: dissolutionData?.dissolutionFinance?.savedAt,
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityDissolutionFinanceData>
) => {
    const toReturn = { ...original } as RecursivePartial<LegalEntityDTO>;
    toReturn.dissolutionData = {
        ...original?.dissolutionData,
        dissolutionFinance: {
            ...original?.dissolutionData?.dissolutionFinance,
            ...section,
        },
    };

    return toReturn;
};
