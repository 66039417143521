import {
    CreateLegalEntityWorkflow,
    FinanceInfoWorkflow,
    LegalEntityDissolutionDetailsWorkflow,
    LegalEntityDissolutionFinanceWorkflow,
    LegalEntityDissolutionLegalWorkflow,
    LegalEntityDissolutionLitigationWorkflow,
    LegalEntityDissolutionTaxWorkflow,
    LegalEntityDissolutionTreasuryWorkflow,
    LegalEntityUpdateWorkflow,
    OwnershipWorkflow,
    TaxClassificationWorkflow,
} from 'models/LegalEntityRequest/Workflow';
import { DissolutionWorkflow, IWorkflowService } from 'services/interfaces';
import { ServiceLocator, UserInfoContext } from '@bxgrandcentral/shell';
import { entitySectionMapper, entitySections } from '../EntityToSectionMapper';
import {
    getChildItemsFromDissolutionResponse,
    getChildItemsFromGetWorkItemsResponse,
    getStandaloneItemsFromGetWorkItemsResponse,
} from 'models/LegalEntityRequest/Workflow/utils';
import { isEmpty, isEqual, isNil, isNumber } from 'lodash';
import {
    setDissolutionFinanceWorkItem,
    setDissolutionLegalWorkItem,
    setDissolutionLitigationWorkItem,
    setDissolutionParentWorkItem,
    setDissolutionTaxWorkItem,
    setDissolutionTreasuryWorkItem,
    setEntityCreationWorkItem,
    setFinanceInformationWorkItem,
    setForeignRegistrationUpdateWorkItem,
    setForeignRegistrationWorkItems,
    setIsWorkItemsRefreshing,
    setLegalEntityUpdateWorkItem,
    setLoadingState,
    setNoPendingSections,
    setOwnershipWorkItem,
    setSections,
    setTaxClassificationWorkItem,
    setUserScopes,
    unsetLoadingState,
    useCreationViewContext,
} from '../context/creation-view-context';
import { setRequestNotes, useRequestContext } from 'context/request-context';
import { setResetLegalEntityCreationForm, useAppContext } from 'context/app-context';
import { useCallback, useContext } from 'react';

import { Entity } from 'models/LegalEntityRequest/LegalEntityDTO';
import { ForeignRegistrationWorkflow } from 'models/LegalEntityRequest/Workflow/ForeignRegistrationWorkflow';
import { GlobalState } from 'GlobalState';
import { ILegalEntityService } from 'services/interfaces/ILegalEntityService';
import { IPermissionService } from 'services/interfaces/IPermissionService';
import { LegalEntityApprovalData } from '../../LegalEntityApproval/model';
import { LegalEntityDissolutionData } from 'models/LegalEntityRequest/LegalEntityDissolution';
import { PartialOrElementPartial } from 'Utilities/Types';
import { RecursivePartial } from 'Utilities/ReflectionUtil';
import { RequestStepsView } from '../RequestStepsView';
import { WorkItem } from 'models/Workflow';
import { delay } from 'Utilities/Delay';
import useReloadPage from './use-reload-page';

type SaveDataProps = {
    savedBy: string;
    savedAt: string;
};

type WorkitemResponse = {
    mainWorkItem?: CreateLegalEntityWorkflow;
    dissolutionWorkitem?: DissolutionWorkItems;
    foreignRegistrationChild?: ForeignRegistrationWorkflow[];
    taxChild?: TaxClassificationWorkflow;
    financeChild?: FinanceInfoWorkflow;
    ownershipChild?: OwnershipWorkflow;
    taxStandalone?: TaxClassificationWorkflow;
    financeStandalone?: FinanceInfoWorkflow;
    ownershipStandalone?: OwnershipWorkflow;
    foreignRegistrationStandalone?: ForeignRegistrationWorkflow;
    legalEntityUpdateStandalone?: LegalEntityUpdateWorkflow;
};

type DissolutionWorkItems = {
    mainWorkItem?: LegalEntityDissolutionDetailsWorkflow;
    dissolutionLitigation?: LegalEntityDissolutionLitigationWorkflow;
    dissolutionTax?: LegalEntityDissolutionTaxWorkflow;
    dissolutionFinance?: LegalEntityDissolutionFinanceWorkflow;
    dissolutionLegal?: LegalEntityDissolutionLegalWorkflow;
    dissolutionTreasury?: LegalEntityDissolutionTreasuryWorkflow;
};

export default function useApiServices() {
    const userRequest = ServiceLocator.container.resolve(IPermissionService);
    const legalEntityRequest = ServiceLocator.container.resolve(ILegalEntityService);
    const workflowService = ServiceLocator.container.resolve(IWorkflowService);

    const {
        state: { entityCreationWorkItem, legalEntityOId, sections },
        dispatch,
    } = useCreationViewContext();

    const {
        state: { taskComment = {} },
        dispatch: requestDispatch,
    } = useRequestContext();

    const { dispatch: appContextDispatch } = useAppContext();

    const userinfo = useContext(UserInfoContext);

    const reloadPage = useReloadPage();

    const saveSection = useCallback(
        async <K extends keyof entitySections>(
            entitySections: entitySections,
            sectionKey: K,
            section: PartialOrElementPartial<entitySections[K]>
        ): Promise<Entity> => {
            const loadingKey = 'save entity';
            setLoadingState(dispatch, loadingKey);
            const leId = entitySections.target?.entity.entityOId ?? 0;
            const method =
                leId > 0
                    ? legalEntityRequest.UpdateLegalEntity.bind(legalEntityRequest, leId, sectionKey)
                    : legalEntityRequest.CreateLegalEntity.bind(legalEntityRequest);

            try {
                const mappedDto = entitySectionMapper.mapToDto(entitySections.target, sectionKey, section);
                const response = await method(mappedDto);
                setSections(dispatch, response);
                return response.entity;
            } catch (e) {
                console.error('failed to save legal entity', e);
                GlobalState.ShowMessageBox('ERROR', 'Failed to save entity');
                throw e;
            } finally {
                unsetLoadingState(dispatch, loadingKey);
            }
        },
        [dispatch, legalEntityRequest]
    );

    const saveWorkItem = useCallback(
        async (approvalData: Partial<LegalEntityApprovalData>) => {
            const loadingKey = 'updating work item';
            setLoadingState(dispatch, loadingKey);
            const { workItemId, workItemVersionNumber, customProperties } = entityCreationWorkItem ?? {};

            if (workItemId === undefined || workItemVersionNumber === undefined) {
                console.warn('no active workItem. Not updating data.', approvalData);
                unsetLoadingState(dispatch, loadingKey);
                return;
            }

            const mappedData = entitySectionMapper.mapToWorkItem(approvalData);
            const workItemDto = Object.assign({ workItemId, workItemVersionNumber }, mappedData);
            const dataComparision = { ...customProperties, ...mappedData.customProperties };

            try {
                if (JSON.stringify(customProperties) !== JSON.stringify(dataComparision)) {
                    const workItem = await workflowService.UpdateWorkItem(workItemDto);
                    setEntityCreationWorkItem(dispatch, workItem as CreateLegalEntityWorkflow);
                }
            } catch (e) {
                console.error('failed to save work item', e);
                GlobalState.ShowMessageBox('ERROR', 'Failed to update Workflow');
                throw e;
            } finally {
                unsetLoadingState(dispatch, loadingKey);
            }
        },
        [dispatch, entityCreationWorkItem, workflowService]
    );

    const getWorkitemData = useCallback(
        async (legalEntityId: number) => {
            const workItems = await workflowService.GetWorkItemForEntity(legalEntityId);

            const childItems = getChildItemsFromGetWorkItemsResponse(workItems);
            const standaloneItems = getStandaloneItemsFromGetWorkItemsResponse(workItems);
            const dissolutionitems = workItems?.dissolutionWorkflow
                ? getDissolutionWorkItems(workItems.dissolutionWorkflow)
                : undefined;
            const workitemData = {
                mainWorkItem: workItems?.workItem,
                dissolutionWorkitem: dissolutionitems,
                foreignRegistrationChild: childItems.ForeignRegistrationChildProcess,
                taxChild: childItems.TaxChildProcess?.[0] as TaxClassificationWorkflow,
                financeChild: childItems.FinanceChildProcess?.[0] as FinanceInfoWorkflow,
                ownershipChild: childItems.OwnershipChildProcess?.[0] as OwnershipWorkflow,
                taxStandalone: standaloneItems.TaxChildProcess?.[0] as TaxClassificationWorkflow,
                financeStandalone: standaloneItems.FinanceChildProcess?.[0] as FinanceInfoWorkflow,
                ownershipStandalone: standaloneItems.OwnershipChildProcess?.[0] as OwnershipWorkflow,
                foreignRegistrationStandalone: standaloneItems
                    .ForeignRegistrationUpdateWorkflow?.[0] as ForeignRegistrationWorkflow,
                legalEntityUpdateStandalone: standaloneItems.LegalEntityUpdateWorkflow?.filter(
                    (workItem) => workItem.workItemStatus === 'InProcess'
                )[0] as LegalEntityUpdateWorkflow,
            } as WorkitemResponse;

            return workitemData;
        },
        [workflowService]
    );

    const setWorkItemResponseInContext = useCallback(
        (workItems: WorkitemResponse) => {
            setEntityCreationWorkItem(dispatch, workItems.mainWorkItem);
            setForeignRegistrationWorkItems(dispatch, workItems.foreignRegistrationChild ?? []);

            if (workItems.financeChild?.workItemStatus !== 'InProcess' && workItems.financeStandalone)
                setFinanceInformationWorkItem(dispatch, { ...workItems.financeStandalone, isStandalone: true });
            else setFinanceInformationWorkItem(dispatch, workItems.financeChild);

            if (workItems.taxChild?.workItemStatus !== 'InProcess' && workItems.taxStandalone)
                setTaxClassificationWorkItem(dispatch, { ...workItems.taxStandalone, isStandalone: true });
            else setTaxClassificationWorkItem(dispatch, workItems.taxChild);

            if (workItems.ownershipChild?.workItemStatus !== 'InProcess' && workItems.ownershipStandalone) {
                setOwnershipWorkItem(dispatch, { ...workItems.ownershipStandalone, isStandalone: true });
            } else setOwnershipWorkItem(dispatch, workItems.ownershipChild);

            setLegalEntityUpdateWorkItem(dispatch, workItems.legalEntityUpdateStandalone);

            if (workItems.foreignRegistrationStandalone) {
                setForeignRegistrationUpdateWorkItem(dispatch, {
                    ...workItems.foreignRegistrationStandalone,
                    isStandalone: true,
                });
            }

            setDissolutionParentWorkItem(dispatch, workItems.dissolutionWorkitem?.mainWorkItem);
            setDissolutionLitigationWorkItem(dispatch, workItems.dissolutionWorkitem?.dissolutionLitigation);
            setDissolutionTaxWorkItem(dispatch, workItems.dissolutionWorkitem?.dissolutionTax);
            setDissolutionFinanceWorkItem(dispatch, workItems.dissolutionWorkitem?.dissolutionFinance);
            setDissolutionLegalWorkItem(dispatch, workItems.dissolutionWorkitem?.dissolutionLegal);
            setDissolutionTreasuryWorkItem(dispatch, workItems.dissolutionWorkitem?.dissolutionTreasury);
        },
        [dispatch]
    );

    const getDissolutionWorkItems = (dissolutionWorkitem: DissolutionWorkflow) => {
        // dissolution request was withdrawn
        if (dissolutionWorkitem?.workItem?.workItemStatus === 'Canceled') {
            return;
        }

        const childItems = getChildItemsFromDissolutionResponse(dissolutionWorkitem);

        const dissolutionWorkItems = {
            mainWorkItem: dissolutionWorkitem?.workItem,
            dissolutionLitigation: childItems
                ?.DissolutionLitigationWorkflow?.[0] as LegalEntityDissolutionLitigationWorkflow,
            dissolutionTax: childItems?.DissolutionTaxWorkflow?.[0] as LegalEntityDissolutionTaxWorkflow,
            dissolutionFinance: childItems?.DissolutionFinanceWorkflow?.[0] as LegalEntityDissolutionFinanceWorkflow,
            dissolutionLegal: childItems?.DissolutionLegalWorkflow?.[0] as LegalEntityDissolutionLegalWorkflow,
            dissolutionTreasury: childItems?.DissolutionTreasuryWorkflow?.[0] as LegalEntityDissolutionTreasuryWorkflow,
        } as DissolutionWorkItems;

        return dissolutionWorkItems;
    };

    const reloadWorkItems = useCallback(
        async (legalEntityId: number, delay: number = 0) => {
            setIsWorkItemsRefreshing(dispatch, true);
            if (delay) {
                await new Promise((resolve) => setTimeout(resolve, delay));
            }

            const workitemData = await getWorkitemData(legalEntityId);

            setWorkItemResponseInContext(workitemData);

            setIsWorkItemsRefreshing(dispatch, false);
            return { workitemData };
        },
        [dispatch, getWorkitemData, setWorkItemResponseInContext]
    );

    const loadEntityData = useCallback(
        async (legalEntityId: number, dissolutionMainWorkItemId?: number) => {
            const legalEntityWithPendingChangesData = await legalEntityRequest.GetLegalEntity(legalEntityId);
            const legalEntityWithoutPendingChangesData = await legalEntityRequest.GetLegalEntity(legalEntityId, false);

            const dissolutionData = dissolutionMainWorkItemId
                ? await legalEntityRequest.GetLegalEntityDissolutionData(dissolutionMainWorkItemId)
                : undefined;

            setSections(dispatch, { ...legalEntityWithPendingChangesData, dissolutionData });
            setNoPendingSections(dispatch, { ...legalEntityWithoutPendingChangesData, dissolutionData });
        },
        [legalEntityRequest, dispatch]
    );

    const getUserScopes = useCallback(
        async (entityOId: number = 0, delayBy: number = 0) => {
            const loadingKey = 'fetching permissions';
            setLoadingState(dispatch, loadingKey);
            try {
                await delay(delayBy);
                const resp = await userRequest.GetPermissionedUser(entityOId);
                setUserScopes(dispatch, resp);
            } catch (e) {
                throw e;
            } finally {
                unsetLoadingState(dispatch, loadingKey);
            }
        },
        [userRequest, dispatch]
    );

    const reloadUserScopes = useCallback(
        async (entityOId?: number, delayBy: number = 0) => {
            try {
                await getUserScopes(entityOId, delayBy);
            } catch (e) {
                GlobalState.ShowMessageBox('ERROR', 'Failed to resolve user permissions.');
            }
            return entityOId || 0;
        },
        [getUserScopes]
    );

    const createStandalone = useCallback(
        async (entityId: number, workflowType: string, reloadWorkItemsAndUserScopes: boolean = true) => {
            const loadingKey = 'creating standalone';
            setLoadingState(dispatch, loadingKey);

            try {
                const workItemData = await workflowService.CreateNewEntityWorkItemStandalone(entityId, workflowType);
                if (entityId && reloadWorkItemsAndUserScopes) {
                    reloadWorkItems(entityId, 2000);
                    reloadUserScopes(entityId, 2000);
                    reloadPage(entityId, 1000);
                }
                return workItemData;
            } catch (e) {
                console.warn(`could not create standalone task '${workflowType}`, e);
                GlobalState.ShowMessageBox(
                    'ERROR',
                    `An error has occurred. Please contact support staff. Message: ${e}`
                );
            } finally {
                unsetLoadingState(dispatch, loadingKey);
            }
        },
        [dispatch, reloadPage, reloadUserScopes, reloadWorkItems, workflowService]
    );

    const updateWorkItemNotes = useCallback(
        async (workItem: WorkItem, exitCode?: string) => {
            const { workItemId, workItemVersionNumber, customProperties } = workItem;
            const loadingKey = 'updating work item notes';
            setLoadingState(dispatch, loadingKey);

            const updatedTaskComment = ['Approve', 'Withdraw'].includes(exitCode ?? '')
                ? undefined
                : taskComment[workItemId];

            if (isNil(updatedTaskComment)) {
                setRequestNotes(requestDispatch, workItemId, undefined);
            }

            try {
                if (!isEqual(customProperties.taskComment || undefined, updatedTaskComment)) {
                    await workflowService.UpdateWorkItem({
                        workItemId,
                        workItemVersionNumber,
                        customProperties: {
                            ...customProperties,
                            taskComment: updatedTaskComment,
                        },
                    });

                    if (isNil(exitCode)) {
                        const entityId = parseInt(customProperties.workItemName.split('.').pop() ?? '');
                        if (isNumber(entityId)) {
                            await reloadWorkItems(entityId);
                        }
                    }
                }
            } catch (e) {
                GlobalState.ShowMessageBox('ERROR', typeof e === 'string' ? e : 'Failed to update work item notes');
            } finally {
                unsetLoadingState(dispatch, loadingKey);
            }
        },
        [dispatch, reloadWorkItems, requestDispatch, taskComment, workflowService]
    );

    const completeTask = useCallback(
        async (
            workItem: WorkItem,
            task: NonNullable<WorkItem['tasks']>[number],
            exitCode?: string,
            section?: string,
            rejectReason?: string,
            options?: { skipRequestNotesUpdate?: boolean },
            delay?: number
        ) => {
            const loadingKey = 'completing task';
            setLoadingState(dispatch, loadingKey);
            const { skipRequestNotesUpdate = false } = options ?? {};

            if (!workItem) {
                console.warn(`could not complete task '${task.taskKey}'. Don't have a workItem.`);
                GlobalState.ShowMessageBox(
                    'ERROR',
                    'An error has occurred. Please contact support staff. Message: WorkItem was not present.'
                );
                unsetLoadingState(dispatch, loadingKey);
                return;
            }
            try {
                if (!skipRequestNotesUpdate) {
                    await updateWorkItemNotes(workItem, exitCode);
                }
                await workflowService.CompleteTask(task, workItem, exitCode, rejectReason);
                if (sections.target?.entity.entityOId) {
                    reloadWorkItems(sections.target.entity.entityOId, delay ? delay : 2000);
                    reloadUserScopes(sections.target.entity.entityOId, delay ? delay : 2000);
                    if (section !== undefined || section === RequestStepsView.Approval) {
                        reloadPage(sections.target?.entity.entityOId, 1000, section);
                    }
                }
            } catch (e) {
                console.warn(`could not complete task '${task.taskKey}`, e);
                GlobalState.ShowMessageBox(
                    'ERROR',
                    `An error has occurred. Please contact support staff. Message: ${e}`
                );
            } finally {
                unsetLoadingState(dispatch, loadingKey);
            }
        },
        [dispatch, reloadPage, reloadUserScopes, reloadWorkItems, sections.target, updateWorkItemNotes, workflowService]
    );

    const reloadEntityAndWorkItems = useCallback(
        async (delay: number = 0, reloadUserScopesData = false) => {
            const legalEntityId = sections.Details.entityOid ?? legalEntityOId;

            if (isNil(legalEntityId)) {
                return;
            }

            const loadingKey = 'loading entity and work item';
            setLoadingState(dispatch, loadingKey);

            try {
                const legalEntityWithPendingChangesData = await legalEntityRequest.GetLegalEntity(legalEntityId);
                // handling old MDM ids
                if (
                    legalEntityWithPendingChangesData.entity.entityOId &&
                    legalEntityWithPendingChangesData.entity.entityOId !== legalEntityId
                ) {
                    reloadPage(legalEntityWithPendingChangesData.entity.entityOId, 0);
                    return;
                }

                const workItemsData = await reloadWorkItems(legalEntityId, delay);
                const dissolutionMainWorkItemId =
                    workItemsData.workitemData.dissolutionWorkitem?.mainWorkItem?.workItemId;

                await loadEntityData(legalEntityId, dissolutionMainWorkItemId);

                if (reloadUserScopesData) {
                    reloadUserScopes(legalEntityId);
                }
            } catch (e) {
                GlobalState.ShowMessageBox('ERROR', typeof e === 'string' ? e : 'Failed to load entity data!');
            } finally {
                setResetLegalEntityCreationForm(appContextDispatch, true);
                unsetLoadingState(dispatch, loadingKey);
            }
        },
        [
            dispatch,
            legalEntityOId,
            loadEntityData,
            reloadWorkItems,
            sections.Details.entityOid,
            appContextDispatch,
            legalEntityRequest,
            reloadPage,
            reloadUserScopes,
        ]
    );

    const saveDissolutionSection = useCallback(
        async <K extends keyof entitySections>(
            entitySections: entitySections,
            sectionKey: K,
            workflowId: number,
            section: PartialOrElementPartial<entitySections[K]>
        ): Promise<LegalEntityDissolutionData> => {
            const loadingKey = 'save dissolution data';
            setLoadingState(dispatch, loadingKey);

            const sectionWithExtraProps = {
                ...section,
                savedBy: userinfo?.upn,
                savedAt: new Date().toISOString(),
            };

            try {
                const data = {
                    workflowId,
                    dissolutionRequest: !isEmpty(section) ? sectionWithExtraProps : undefined,
                    dissolutionTax: undefined,
                    dissolutionFinance: undefined,
                    dissolutionTreasury: undefined,
                    dissolutionLitigation: undefined,
                    dissolutionBDG: undefined,
                    dissolutionLegal: undefined,
                } as LegalEntityDissolutionData;

                const response = await legalEntityRequest.SaveLegalEntityDissolutionData(data);
                const mappedDto = entitySectionMapper.mapToDto(entitySections.target, sectionKey, section);
                mappedDto.dissolutionData = response;

                setSections(dispatch, mappedDto);

                return response;
            } catch (e) {
                GlobalState.ShowMessageBox('ERROR', 'Failed to save dissolution data');
                throw e;
            } finally {
                unsetLoadingState(dispatch, loadingKey);
            }
        },
        [dispatch, legalEntityRequest]
    );

    const updateDissolutionSection = useCallback(
        async <K extends keyof entitySections>(
            entitySections: entitySections,
            sectionKey: K,
            workflowId: number,
            section: PartialOrElementPartial<entitySections[K]>,
            options?: {
                ignoreSaveProps: boolean;
            }
        ): Promise<LegalEntityDissolutionData> => {
            const loadingKey = 'update dissolution data';
            setLoadingState(dispatch, loadingKey);

            const sectionWithExtraProps = {
                ...section,
                ...((!options?.ignoreSaveProps && {
                    savedBy: userinfo?.upn,
                    savedAt: new Date().toISOString(),
                }) as unknown as SaveDataProps),
            };

            try {
                const response = await legalEntityRequest.UpdateLegalEntityDissolutionData(
                    workflowId,
                    sectionWithExtraProps as RecursivePartial<LegalEntityDissolutionData>,
                    sectionKey
                );
                const mappedDto = entitySectionMapper.mapToDto(entitySections.target, sectionKey, section);
                mappedDto.dissolutionData = response;

                setSections(dispatch, mappedDto);
                return response;
            } catch (e) {
                GlobalState.ShowMessageBox('ERROR', 'Failed to update dissolution data');
                throw e;
            } finally {
                unsetLoadingState(dispatch, loadingKey);
            }
        },
        [dispatch, legalEntityRequest]
    );

    const getCanDissolveEntity = useCallback(
        async (legalEntityOId: number) => {
            const loadingKey = 'get can dissolve entity info';
            setLoadingState(dispatch, loadingKey);

            try {
                const canDissolveEntity = await legalEntityRequest.GetCanDissolveEntity(legalEntityOId);
                return canDissolveEntity;
            } catch (e) {
                GlobalState.ShowMessageBox('ERROR', 'Failed to reach out to the CanDissolveEntity endpoint.');
            } finally {
                unsetLoadingState(dispatch, loadingKey);
            }
        },
        [dispatch, legalEntityRequest]
    );

    return {
        completeTask,
        createStandalone,
        reloadEntityAndWorkItems,
        reloadWorkItems,
        reloadUserScopes,
        saveSection,
        saveWorkItem,
        updateWorkItemNotes,
        getUserScopes,
        saveDissolutionSection,
        updateDissolutionSection,
        getCanDissolveEntity,
    };
}
