import { BooleanField, DeleteButton, ElasticSearchField, Select, Text, TextField } from 'components';
import { DoubleColumnLayout, HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import { InterestTypes, InterestUnit, LegalTypes } from 'models/LegalEntityRequest/DefaultValues';
import React, { useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { hasElement, removeAt } from 'Utilities/array';
import { isEqual, isNil } from 'lodash';

import { EntitySubType } from 'models/LegalEntity/EntitySubType';
import { FormControl } from 'components/controls/controls.styled';
import { GlobalState } from 'GlobalState';
import { OwnershipDetails } from '../../model';
import { ValidationErrors } from 'modules/LegalEntityCreation/context/model';
import { getValuesOf } from 'Utilities/object';
import { useLegalEntityOwnerShipInformation } from 'modules/LegalEntityCreation/context/Provider';
import { useReferenceData } from 'api';

type Props = {
    index: number;
    validation: ValidationErrors<OwnershipDetails>;
    noPendingValues?: OwnershipDetails;
};

export default function OwnershipDetail({ index, validation = {}, noPendingValues }: Props) {
    const {
        state: {
            data: {
                values: { ownershipDetails = [], legalType },
            },
            isEditable,
            version,
        },
        setValue,
    } = useLegalEntityOwnerShipInformation();

    const {
        data: {
            InterestClassType,
            InterestUnitType,
            InterestTypes: InterestTypesRefData,
            ReportingType,
            Currency,
            AxiomShareClass: ShareClassRefData,
        },
    } = useReferenceData();

    const {
        key,
        isDeleted,
        interestUnit,
        interestClass,
        interestType,
        numberOfUnits,
        ownerShip,
        parentEntity,
        reportingType,
        votingRight,
        shareClass,
        nominalValue,
        currency,
        ownershipName,
    } = ownershipDetails[index] ?? {};

    const isValidOwnership = useMemo(
        () =>
            getValuesOf({
                interestUnit,
                interestClass,
                interestType,
                numberOfUnits,
                ownerShip,
                parentEntity,
                reportingType,
                votingRight,
            }).every((value) => isNil(value)),
        [interestClass, interestType, interestUnit, numberOfUnits, ownerShip, parentEntity, reportingType, votingRight]
    );

    const currencyOptions = useMemo(
        () => Currency?.map((currency) => ({ ...currency, Value: `${currency.ShortValue} - ${currency.Value}` })),
        [Currency]
    );

    async function setIsDeleted() {
        if (key) {
            ownershipDetails[index].isDeleted = !isDeleted;
            setValue('ownershipDetails', Array.of(...ownershipDetails));
        } else {
            await GlobalState.openDialog({
                variant: 'info',
                title: 'Delete Ownership Details',
                content:
                    'Are you sure you want to remove these Ownership Details?  Any information on this unsaved relationship will be lost.',
            }).then((response) => {
                if (response) {
                    setValue('ownershipDetails', removeAt(ownershipDetails, index));
                }
            });
        }
    }

    const interestUnits = useMemo(() => {
        switch (legalType) {
            case LegalTypes.Corporation:
            case LegalTypes.SARLSAGmbH:
                return InterestUnitType?.filter(({ Key }) => Key === InterestUnit.Share);
            case LegalTypes.LimitedPartnership:
            case LegalTypes.LimitedLiabilityCompany:
            case LegalTypes.SCSp:
                return InterestUnitType?.filter(({ Key }) => Key !== InterestUnit.Share);
            default:
                return InterestUnitType;
        }
    }, [InterestUnitType, legalType]);

    const interestTypes = useMemo(() => {
        switch (legalType) {
            case LegalTypes.Corporation:
            case LegalTypes.SARLSAGmbH:
                return InterestTypesRefData?.filter(({ Key }) => Key === InterestTypes.Shareholder);
            case LegalTypes.LimitedPartnership:
            case LegalTypes.SCSp:
                return InterestTypesRefData?.filter(({ Key }) =>
                    [InterestTypes.GeneralPartner, InterestTypes.LimitedPartner].includes(Key)
                );
            case LegalTypes.LimitedLiabilityCompany:
                return InterestTypesRefData?.filter(({ Key }) =>
                    [InterestTypes.SoleMember, InterestTypes.ManagingMember, InterestTypes.Member].includes(Key)
                );
            default:
                return InterestTypesRefData;
        }
    }, [InterestTypesRefData, legalType]);

    const isDeleteEnabled = useMemo(
        () => ownershipDetails.filter(({ isDeleted }) => !isDeleted).length > 1 || !!ownershipDetails[index].isDeleted,
        [ownershipDetails, index]
    );

    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return !isEditable && !isNil(noPendingValues) ? !isEqual(newValue, noPendingValue) : undefined;
    };

    return (
        <StackPanel padding={HORIZONTAL_FORM_PADDING}>
            {!key && (
                <TextBlock styleName='heading4Style' margin={`0 0 ${SPACING.SM}px 0 `}>
                    New Owner
                </TextBlock>
            )}
            <DoubleColumnLayout isDisabled={isDeleted}>
                <StackPanel itemGap={SPACING.SM}>
                    <TextField
                        label='Ownership Name'
                        value={ownershipName}
                        version={version}
                        isEditable={isEditable}
                        onValueChanged={(newValue) => {
                            const updated = [...ownershipDetails];
                            updated[index].ownershipName = newValue;
                            setValue('ownershipDetails', updated);
                        }}
                        isPendingChange={
                            noPendingValues && isPendingChange(ownershipName, noPendingValues?.ownershipName)
                        }
                        isRequired
                        {...(isEditable && { validationError: validation.ownershipName })}
                    />
                    <FormControl className='form'>
                        <ElasticSearchField
                            label='Parent Entity'
                            labelToolTip='Child legal entity to owner legal entity'
                            searchType={EntitySubType.LegalEntity}
                            selectedEntityOId={parentEntity}
                            onEntitySelected={(result) => {
                                const updated = [...ownershipDetails];
                                updated[index].parentEntity = result?.entityOId;
                                setValue('ownershipDetails', updated);
                            }}
                            isReadOnly={!isEditable}
                            canClearSelection
                            isRequired
                            isPendingChange={
                                noPendingValues && isPendingChange(parentEntity, noPendingValues?.parentEntity)
                            }
                            {...(isEditable && { validationError: validation.parentEntity })}
                        />
                    </FormControl>
                    <Select
                        label='Interest Unit'
                        labelToolTip={{ component: 'The type of units for ownership interest in a given entity.' }}
                        itemsSource={interestUnits}
                        value={interestUnit}
                        isEditable={isEditable}
                        version={version}
                        onValueChanged={(newValue) => {
                            const updated = [...ownershipDetails];
                            updated[index].interestUnit = newValue;
                            setValue('ownershipDetails', updated);
                        }}
                        isPendingChange={
                            noPendingValues && isPendingChange(interestUnit, noPendingValues?.interestUnit)
                        }
                    />
                    <Select
                        label='Interest Class'
                        labelToolTip={{
                            component:
                                'The share class or equivalent unit type for ownership interest in a given entity',
                        }}
                        itemsSource={InterestClassType}
                        value={interestClass}
                        isEditable={isEditable}
                        version={version}
                        onValueChanged={(newValue) => {
                            const updated = [...ownershipDetails];
                            updated[index].interestClass = newValue;
                            setValue('ownershipDetails', updated);
                        }}
                        isPendingChange={
                            noPendingValues && isPendingChange(interestClass, noPendingValues?.interestClass)
                        }
                    />
                    <TextField
                        label='Number of Units'
                        labelToolTip='The units issued for ownership in a given entity'
                        value={numberOfUnits}
                        version={version}
                        isEditable={isEditable}
                        onValueChanged={(newValue) => {
                            const updated = [...ownershipDetails];
                            updated[index].numberOfUnits = newValue;
                            setValue('ownershipDetails', updated);
                        }}
                        isPendingChange={
                            noPendingValues && isPendingChange(numberOfUnits, noPendingValues?.numberOfUnits)
                        }
                        {...(isEditable && { validationError: validation.numberOfUnits })}
                    />
                    <TextField
                        label='Nominal Value'
                        value={nominalValue}
                        isEditable={isEditable}
                        version={version}
                        onValueChanged={(newValue) => {
                            const updated = [...ownershipDetails];
                            updated[index].nominalValue = newValue;
                            setValue('ownershipDetails', updated);
                        }}
                        isPendingChange={
                            noPendingValues && isPendingChange(nominalValue, noPendingValues?.nominalValue)
                        }
                    />
                </StackPanel>
                <StackPanel itemGap={SPACING.SM}>
                    <TextField
                        label='Ownership %'
                        labelToolTip='Parent legal entity’s ownership percentage'
                        value={ownerShip}
                        isEditable={isEditable}
                        version={version}
                        onValueChanged={(newValue) => {
                            const updated = [...ownershipDetails];
                            updated[index].ownerShip = newValue;
                            setValue('ownershipDetails', updated);
                        }}
                        isPendingChange={noPendingValues && isPendingChange(ownerShip, noPendingValues?.ownerShip)}
                        {...(isEditable && { validationError: validation.ownerShip })}
                    />
                    <Select
                        label='Reporting Type'
                        labelToolTip={{ component: 'Legal Entity reporting type' }}
                        itemsSource={ReportingType}
                        value={reportingType}
                        isEditable={isEditable}
                        version={version}
                        onValueChanged={(newValue) => {
                            const updated = [...ownershipDetails];
                            updated[index].reportingType = newValue;
                            setValue('ownershipDetails', updated);
                        }}
                        isPendingChange={
                            noPendingValues && isPendingChange(reportingType, noPendingValues?.reportingType)
                        }
                    />
                    <Select
                        label='Interest Type'
                        labelToolTip={{ component: 'Owner hierarchy interest type' }}
                        itemsSource={interestTypes}
                        value={interestType}
                        isEditable={isEditable}
                        version={version}
                        isRequired
                        onValueChanged={(newValue) => {
                            const updated = [...ownershipDetails];
                            updated[index].interestType = newValue;
                            setValue('ownershipDetails', updated);
                        }}
                        isPendingChange={
                            noPendingValues && isPendingChange(interestType, noPendingValues?.interestType)
                        }
                        {...(isEditable && { validationError: validation.interestType })}
                    />
                    <Select
                        label='Share Class Type'
                        itemsSource={ShareClassRefData}
                        value={shareClass}
                        isEditable={isEditable}
                        version={version}
                        onValueChanged={(newValue) => {
                            const updated = [...ownershipDetails];
                            updated[index].shareClass = newValue;
                            setValue('ownershipDetails', updated);
                        }}
                        isPendingChange={noPendingValues && isPendingChange(shareClass, noPendingValues?.shareClass)}
                    />
                    <Select
                        label='Currency'
                        itemsSource={currencyOptions}
                        value={currency}
                        isEditable={isEditable}
                        showSearchBox
                        version={version}
                        onValueChanged={(newValue) => {
                            const updated = [...ownershipDetails];
                            updated[index].currency = newValue;
                            setValue('ownershipDetails', updated);
                        }}
                        isPendingChange={noPendingValues && isPendingChange(currency, noPendingValues?.currency)}
                    />
                    {!hasElement(
                        [LegalTypes.LimitedPartnership, LegalTypes.LimitedLiabilityCompany, LegalTypes.SCSp],
                        legalType
                    ) && (
                        <BooleanField
                            label='Voting Rights'
                            labelToolTip={{
                                component: 'Whether or not this owner has voting rights',
                            }}
                            value={votingRight}
                            isEditable={isEditable}
                            version={version}
                            isPendingChange={
                                noPendingValues && isPendingChange(votingRight, noPendingValues?.votingRight)
                            }
                            onValueChanged={(newValue) => {
                                const updated = [...ownershipDetails];
                                updated[index].votingRight = newValue;
                                setValue('ownershipDetails', updated);
                            }}
                        />
                    )}
                </StackPanel>
            </DoubleColumnLayout>
            {isValidOwnership && isEditable && (
                <Text
                    horizontalAlignment='center'
                    verticalAlignment='center'
                    textAlignment='center'
                    variant='errorBold'>
                    Empty ownership can't be saved, please set at least one attribute
                </Text>
            )}
            {isEditable && <DeleteButton isEnabled={isDeleteEnabled} isDeleted={isDeleted} onClick={setIsDeleted} />}
        </StackPanel>
    );
}
