import { DISSOLUTION_STATE, DISSOLUTION_STATUS } from 'models/LegalEntityRequest/Workflow';
import { DateTimeField, Debugger, Expander, Footer, Form, Text, TextField } from 'components';
import { GridLayout, SPACING } from 'Utilities/Layout';
import React, { useEffect, useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { setRefreshCount, useAppContext } from 'context/app-context';

import ActionPanel from './components/ActionPanel/ActionPanel';
import DissolutionRejectReason from 'modules/LegalEntityCreation/Shared/DissolutionRejectReason';
import InfoBlock from 'modules/LegalEntityCreation/Shared/InfoBlock';
import { LegalEntityDissolutionDetailsProps } from './model';
import PageUpdateInfo from '../components/PageUpdateInfo';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import { WorkflowType } from 'models/LegalEntityRequest/Workflow/WorkflowTypes';
import { convertToLocalDate } from 'Utilities/date';
import useApiServices from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-api-services';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useLastCompletedTask from '../hooks/use-last-completed-task';
import { useLegalEntityDissolutionDetails } from '../../context/Provider';

export default function LegalEntityDissolutionDetails(props: LegalEntityDissolutionDetailsProps) {
    const { reloadEntityAndWorkItems } = useApiServices();
    const {
        state: {
            legalEntityOId,
            isEntityDissolvable,
            isWorkItemsRefreshing,
            legalEntityDissolutionParentWorkItem,
            isEntityDissolved,
        },
    } = useCreationViewContext();

    const context = useLegalEntityDissolutionDetails();
    const {
        getValue,
        setValue,
        state: {
            data: {
                values: { savedBy, savedAt },
            },
        },
    } = context;

    const lastCompletedTask = useLastCompletedTask({
        workItem: legalEntityDissolutionParentWorkItem,
        taskType: 'SubmitRequestType',
        saved: {
            savedBy,
            savedAt,
        },
    });

    const {
        state: {
            legalEntityCreation: { refreshCount, maxRefresh },
        },
        dispatch,
    } = useAppContext();

    const isDissolutionInProcess = useMemo(() => {
        return legalEntityDissolutionParentWorkItem?.workItemStatus === DISSOLUTION_STATUS.IN_PROCESS;
    }, [legalEntityDissolutionParentWorkItem]);

    const onDateChanged = (value?: Date | string | undefined) => {
        setValue('desiredDissolutionDate', convertToLocalDate(value));
    };

    useEffect(() => {
        if (!isWorkItemsRefreshing && refreshCount < maxRefresh && !isEntityDissolvable) {
            setRefreshCount(dispatch, refreshCount + 1);
            reloadEntityAndWorkItems();
        } else if (isEntityDissolvable) {
            setRefreshCount(dispatch, 0);
        }
    }, [isWorkItemsRefreshing, maxRefresh, refreshCount, reloadEntityAndWorkItems, dispatch, isEntityDissolvable]);

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Dissolution_Details} />
            {!isEntityDissolvable && !isDissolutionInProcess && !isEntityDissolved ? (
                <InfoBlock>
                    There are open child work items / update requests associated with this entity. For this entity to be
                    dissolved, the open child work items/update requests must be closed. Please contact
                    bxti-datagovernance@blackstone.com for assistance.
                </InfoBlock>
            ) : null}
            <Form>
                <>
                    <Expander
                        header='Legal Entity Dissolution Details'
                        requestStep={RequestStepsView.Dissolution_Details}
                        content={
                            <StackPanel itemGap={SPACING.SM} margin={`${SPACING.XL}px 0 0 0`}>
                                <GridLayout columns='2fr 1fr'>
                                    <StackPanel>
                                        <TextBlock text='Desired Dissolution Date' margin={`${SPACING.XS}px 0 0 0`} />
                                    </StackPanel>
                                    <DateTimeField
                                        {...getValue('desiredDissolutionDate')}
                                        onValueChanged={onDateChanged}
                                        isPendingChange={false}
                                    />
                                </GridLayout>
                            </StackPanel>
                        }
                    />
                    <Expander
                        header='Request Notes'
                        content={
                            <div className='text-area-hide-scrollbar'>
                                <TextField
                                    minHeight={120}
                                    maxHeight={240}
                                    variant='default'
                                    textWrapping='wrap'
                                    padding={`${SPACING.XS}px 0`}
                                    canUndo={false}
                                    {...getValue('requestNotes')}
                                    isPendingChange={false}
                                />
                            </div>
                        }
                    />
                    <DissolutionRejectReason
                        workflowType={WorkflowType.LegalEntityDissolutionParentWorkflowType}
                        currentWorkItemState={props.workItem?.workItemState}
                        workItemStateToExclude={DISSOLUTION_STATE.AWAITING_FOR_CHILD_APPROVAL}
                    />
                    {props.mode === 'Readonly' && props.isWorkItemWasCreated && (
                        <Text
                            horizontalAlignment='center'
                            verticalAlignment='center'
                            textAlignment='center'
                            variant='success'>
                            <p>Dissolution request was created successfully.</p>
                            <p>It takes some time to process it. Please click on refresh to continue!</p>
                        </Text>
                    )}
                    <PageUpdateInfo lastCompletedTask={lastCompletedTask} />
                </>
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel entityOId={legalEntityOId} {...props} />
                </Footer>
            )}
        </>
    );
}
