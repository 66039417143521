import { Address as AddressModel, isUnitedStates } from '../../../../../models';
import { DeleteButton, Select, TextField } from 'components';
import { DoubleColumnLayout, HORIZONTAL_FORM_PADDING, SPACING } from '../../../../../Utilities/Layout';
import React, { useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { isEmpty, isEqual, isNil } from 'lodash';

import { GlobalState } from 'GlobalState';
import { ValidationErrors } from '../../../context/model';
import { addressTypes } from '../../model';
import { removeAt } from 'Utilities/array';
import { useLegalEntityInformation } from 'modules/LegalEntityCreation/context/Provider';
import { useReferenceData } from '../../../../../api';

type Props = {
    index: number;
    validation: ValidationErrors<AddressModel>;
    noPendingValues?: AddressModel;
};

export default function InformationAddress({ index, validation = {}, noPendingValues }: Props) {
    const {
        state: {
            isEditable,
            data: {
                values: { addresses = [] },
            },
        },
        setValue,
    } = useLegalEntityInformation();

    const {
        data: { Country, State },
    } = useReferenceData();

    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return !isEditable && !isNil(noPendingValues) ? !isEqual(newValue, noPendingValue) : undefined;
    };

    async function setIsDeleted() {
        if (addresses[index]?.addressId) {
            addresses[index].isDeleted = !isDeleted;
            setValue('addresses', Array.of(...addresses));
        } else {
            await GlobalState.openDialog({
                variant: 'info',
                title: 'Delete Address',
                content:
                    'Are you sure you want to remove this Address?  Any information on this unsaved relationship will be lost',
            }).then((response) => {
                if (response) {
                    setValue('addresses', removeAt(addresses, index));
                }
            });
        }
    }

    const { addressId, addressTypeId, line1, line2, line3, line4, countryId, city, stateId, postalCode, isDeleted } =
        addresses[index];
    const states = useMemo(() => State?.filter(({ ParentId }) => ParentId === `${countryId}`), [State, countryId]);

    const isNewItem = !addressId || (!isEditable && !isDeleted && addressId < 0);

    const isAddressEditable = isEditable && !isDeleted;

    const isDeleteEnabled = useMemo(
        () => addresses.filter(({ isDeleted }) => !isDeleted).length > 1 || !!addresses[index].isDeleted,
        [addresses, index]
    );

    return (
        <StackPanel key={addressId} padding={HORIZONTAL_FORM_PADDING}>
            {isNewItem && (
                <TextBlock styleName='heading4Style' margin={`0 0 ${SPACING.SM}px 0 `}>
                    New Address
                </TextBlock>
            )}
            <Select
                label='Address Type'
                itemsSource={Object.values(addressTypes)}
                isEditable={isAddressEditable}
                isRequired
                isPendingChange={isPendingChange(addressTypeId, noPendingValues?.addressTypeId)}
                value={addressTypeId}
                onValueChanged={(newValue) => {
                    if (newValue || addresses[index].addressTypeId) {
                        const updated = [...addresses];
                        updated[index].addressTypeId = newValue;
                        setValue('addresses', updated);
                    }
                }}
                validationError={validation.addressTypeId}
                styleOverrides={{ opacity: addresses[index].isDeleted ? 0.5 : 1 }}
            />
            <DoubleColumnLayout isDisabled={isDeleted}>
                <StackPanel itemGap={SPACING.SM}>
                    <TextField
                        label='Line 1'
                        value={line1}
                        isEditable={isAddressEditable}
                        isPendingChange={isPendingChange(line1, noPendingValues?.line1)}
                        onValueChanged={(newValue) => {
                            if (newValue || addresses[index].line1) {
                                const updated = [...addresses];
                                updated[index].line1 = newValue;
                                setValue('addresses', updated);
                            }
                        }}
                    />
                    <TextField
                        label='Line 2'
                        value={line2}
                        isEditable={isAddressEditable}
                        isPendingChange={isPendingChange(line2, noPendingValues?.line2)}
                        onValueChanged={(newValue) => {
                            if (newValue || addresses[index].line2) {
                                const updated = [...addresses];
                                updated[index].line2 = newValue;
                                setValue('addresses', updated);
                            }
                        }}
                    />
                    <TextField
                        label='Line 3'
                        value={line3}
                        isEditable={isAddressEditable}
                        isPendingChange={isPendingChange(line3, noPendingValues?.line3)}
                        onValueChanged={(newValue) => {
                            if (newValue || addresses[index].line3) {
                                const updated = [...addresses];
                                updated[index].line3 = newValue;
                                setValue('addresses', updated);
                            }
                        }}
                    />
                    <TextField
                        label='Line 4'
                        value={line4}
                        isEditable={isAddressEditable}
                        isPendingChange={isPendingChange(line4, noPendingValues?.line4)}
                        onValueChanged={(newValue) => {
                            if (newValue || addresses[index].line4) {
                                const updated = [...addresses];
                                updated[index].line4 = newValue;
                                setValue('addresses', updated);
                            }
                        }}
                    />
                </StackPanel>
                <StackPanel itemGap={SPACING.SM}>
                    <Select
                        label='Country'
                        itemsSource={Country}
                        isEditable={isAddressEditable}
                        isRequired
                        showSearchBox
                        value={countryId}
                        isPendingChange={isPendingChange(countryId, noPendingValues?.countryId)}
                        onValueChanged={(newValue) => {
                            if (newValue || addresses[index].countryId) {
                                const updated = [...addresses];
                                updated[index].countryId = newValue;
                                updated[index].city = undefined;
                                updated[index].state = undefined;
                                updated[index].stateId = undefined;
                                updated[index].postalCode = undefined;
                                setValue('addresses', updated);
                            }
                        }}
                        validationError={validation.countryId}
                    />
                    <TextField
                        label='City'
                        value={city}
                        isEditable={isAddressEditable}
                        isPendingChange={isPendingChange(city, noPendingValues?.city)}
                        onValueChanged={(newValue) => {
                            if (newValue || addresses[index].city) {
                                const updated = [...addresses];
                                updated[index].city = newValue;
                                setValue('addresses', updated);
                            }
                        }}
                    />
                    <Select
                        label='State'
                        itemsSource={states}
                        showSearchBox
                        isEditable={isAddressEditable && !isEmpty(states)}
                        isRequired={isUnitedStates(countryId)}
                        value={stateId}
                        isPendingChange={isPendingChange(stateId, noPendingValues?.stateId)}
                        onValueChanged={(newValue) => {
                            if (newValue || addresses[index].stateId) {
                                const updated = [...addresses];
                                updated[index].stateId = newValue;
                                setValue('addresses', updated);
                            }
                        }}
                    />
                    <TextField
                        label='Postal Code'
                        value={postalCode}
                        isEditable={isAddressEditable}
                        isPendingChange={isPendingChange(postalCode, noPendingValues?.postalCode)}
                        onValueChanged={(newValue) => {
                            if (newValue || addresses[index].postalCode) {
                                const updated = [...addresses];
                                updated[index].postalCode = newValue;
                                setValue('addresses', updated);
                            }
                        }}
                    />
                </StackPanel>
            </DoubleColumnLayout>
            {isEditable && <DeleteButton isDeleted={isDeleted} onClick={setIsDeleted} isEnabled={isDeleteEnabled} />}
        </StackPanel>
    );
}
