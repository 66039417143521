import { CREATE_ENTITY_STATE, CreateEntityStateType } from '../../../models/LegalEntityRequest/Workflow';

import { constants } from '../../../constants/env-config';

export const filterScopes = (scope: string, scopes?: string[]) => {
    return (
        scopes?.reduce((results: string[], value: string) => {
            if (value.split(':')[0] === scope) results.push(value.split(':')[1]);
            return results;
        }, []) ?? []
    );
};

export const filterFRScopes = (scope: string, scopes?: string[]) => {
    return (
        scopes?.reduce((results: string[], value: string) => {
            if (value.split(':')[0] === scope && value.split(':')[1] === 'ForeignRegistration')
                results.push(value.split(':')[1]);
            return results;
        }, []) ?? []
    );
};

export const isInformationStepEnabled = (
    workitemState?: CreateEntityStateType,
    legalEntityOId?: number | undefined
) => {
    // We are showing the information page for entities created in MDM (has no workitemState, has legalEntityOID),
    // and not showing for new entity creation (no workitemState, no legalEntityOID)
    if (!workitemState) {
        return !!legalEntityOId;
    }

    const informationPageStates: CreateEntityStateType[] = [
        CREATE_ENTITY_STATE.POSTPROCESS_1,
        CREATE_ENTITY_STATE.POSTPROCESS_2,
        CREATE_ENTITY_STATE.LEGAL_ENTITY_SETUP_COMPLETE,
    ];

    // We are not showing the information page if the entity is not in the required state
    if (!informationPageStates.includes(workitemState)) {
        return false;
    }

    return true;
};

export const isDissolutionStepEnabled = (
    workitemState?: CreateEntityStateType,
    legalEntityOId?: number | undefined
) => {
    if (!legalEntityOId || constants.IS_DISSOLUTION_ENABLED !== 'true') {
        return false;
    }

    const dissolutionReadyPageStates: CreateEntityStateType[] = [
        CREATE_ENTITY_STATE.POSTPROCESS_1,
        CREATE_ENTITY_STATE.POSTPROCESS_2,
        CREATE_ENTITY_STATE.LEGAL_ENTITY_SETUP_COMPLETE,
    ];

    if (!workitemState || dissolutionReadyPageStates.includes(workitemState)) {
        return true;
    }

    return false;
};
