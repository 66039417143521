import { DateTimeField, DeleteButton, FileUpload, Select, TextField } from 'components';
import { DoubleColumnLayout, HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import React, { useEffect, useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { getDocumentTypes, getRegisteredAgents } from '../../../../LegalEntityDocumentPreparation/utils';
import { isEmpty, isEqual, isNil } from 'lodash';
import {
    removeDocumentSection,
    updateDocumentSection,
    updateStoredDocument,
} from 'modules/LegalEntityCreation/context/actions';

import { GlobalState } from 'GlobalState';
import { LegalEntityRegistrationData } from 'modules/LegalEntityCreation/LegalEntityRegistration/model';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { SECTION_NAME_SEPARATOR } from 'components/controls/FileUpload/models';
import { ValidationErrors } from 'modules/LegalEntityCreation/context/model';
import { convertToLocalDate } from 'Utilities/date';
import { isLuxembourg } from 'models';
import { removeAt } from 'Utilities/array';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useDocumentPreparationRequirements from 'hooks/use-document-preparation-requirements';
import { useLegalEntityRegistrationUpdate } from 'modules/LegalEntityCreation/context/Provider';
import { useReferenceData } from 'api';

type Props = {
    index: number;
    validation: ValidationErrors<Partial<LegalEntityRegistrationData>>;
    mode?: string;
};

export default function LegalEntityRegistration({ index, validation, mode }: Props) {
    const context = useLegalEntityRegistrationUpdate();
    const {
        state: {
            data: {
                values: { RegistrationData = [], legalType },
                noPendingValues,
            },
            documents: { sections },
            isEditable,
            version,
        },
        dispatch,
        setValue,
    } = context;

    const {
        state: { legalEntityOId },
    } = useCreationViewContext();

    const noPendingRegistrationData = noPendingValues?.RegistrationData?.[index];

    const { country, registeredAgent, registrationNumber, state, formationDate, taxRegistrationId, isDeleted } =
        RegistrationData[index];

    const {
        data: { State, RegisteredAgent, Country },
    } = useReferenceData();

    const sectionName = useMemo(
        () => `${SECTION_NAMES.FOREIGN_REGISTRATION_UPDATE}${SECTION_NAME_SEPARATOR}${index}`,
        [index]
    );
    const section = sections[sectionName] ?? { errorMessage: '' };
    const documentTypes = useMemo(() => getDocumentTypes(legalType), [legalType]);

    const { documentTypeError } = useDocumentPreparationRequirements({
        country,
        legalType,
        section,
    });

    useEffect(() => {
        updateDocumentSection(dispatch, sectionName, {
            additionalErrorMessage: !!documentTypeError ? documentTypeError : '',
            isRequired: !!documentTypeError,
        });
    }, [dispatch, documentTypeError, section.errorMessage, sectionName]);

    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return mode === 'Approval' && !isNewItem && !isNil(noPendingValues)
            ? !isEqual(newValue, noPendingValue)
            : undefined;
    };

    async function setIsDeleted() {
        section.storedDocuments?.forEach(
            ({
                document: {
                    documentOId: { value },
                },
            }) => {
                updateStoredDocument(dispatch, sectionName, value, { isDeleted: !isDeleted });
            }
        );
        if (RegistrationData[index]?.taxRegistrationId) {
            updateDocumentSection(dispatch, sectionName, {
                pendingDocuments: [],
                isDeleted: !isDeleted,
            });
            RegistrationData[index].isDeleted = !isDeleted;
            setValue('RegistrationData', Array.of(...RegistrationData));
        } else {
            await GlobalState.openDialog({
                variant: 'info',
                title: 'Delete Foreign Registration',
                content:
                    'Are you sure you want to remove this registration? Any information entered on this unsaved registration will be lost.',
            }).then((response) => {
                if (response) {
                    setValue('RegistrationData', removeAt(RegistrationData, index));
                    removeDocumentSection(dispatch, sectionName);
                }
            });
        }
    }

    const isNewItem = !taxRegistrationId || (!isEditable && !isDeleted && taxRegistrationId < 0);

    const countryHasRegisteredAgent = useMemo(() => getRegisteredAgents(country), [country]);

    const filteredStates = useMemo(() => State?.filter(({ ParentId }) => ParentId === `${country}`), [State, country]);

    return (
        <StackPanel key={taxRegistrationId} padding={HORIZONTAL_FORM_PADDING}>
            {mode === 'Approval' && isNewItem && (
                <TextBlock styleName='heading4Style' margin={`0 0 ${SPACING.SM}px 0 `}>
                    New Foreign Registration
                </TextBlock>
            )}
            <StackPanel itemGap={SPACING.SM} {...(isDeleted && { opacity: 0.5 })}>
                <Select
                    version={version}
                    label='Registered Agent'
                    labelToolTip={{ component: 'Select the foreign registered agent' }}
                    itemsSource={RegisteredAgent}
                    value={registeredAgent}
                    showSearchBox
                    isEditable={isEditable && countryHasRegisteredAgent}
                    isPendingChange={isPendingChange(registeredAgent, noPendingRegistrationData?.registeredAgent)}
                    validationError={validation?.registeredAgent}
                    onValueChanged={(newValue) => {
                        const updated = [...RegistrationData];
                        updated[index].registeredAgent = newValue;
                        setValue('RegistrationData', updated);
                    }}
                />
                <DoubleColumnLayout>
                    <StackPanel itemGap={SPACING.SM}>
                        <Select
                            version={version}
                            label='Country'
                            labelToolTip={{ component: 'Foreign Registration Country' }}
                            itemsSource={Country}
                            showSearchBox
                            value={country}
                            isEditable={isEditable}
                            isPendingChange={isPendingChange(country, noPendingRegistrationData?.country)}
                            validationError={validation?.country}
                            onValueChanged={(newValue) => {
                                const updated = [...RegistrationData];
                                updated[index].country = newValue;
                                updated[index].state = undefined;
                                if (!getRegisteredAgents(newValue)) {
                                    updated[index].registeredAgent = undefined;
                                }
                                setValue('RegistrationData', updated);
                            }}
                        />
                        <TextField
                            version={version}
                            label='Registration Number'
                            labelToolTip={
                                "Foreign Registration unique identification number for the entity that's used to verify its legal existence"
                            }
                            isRequired={!isLuxembourg(country)}
                            value={registrationNumber}
                            isEditable={isEditable}
                            validationError={validation?.registrationNumber}
                            isPendingChange={isPendingChange(
                                registrationNumber,
                                noPendingRegistrationData?.registrationNumber
                            )}
                            onValueChanged={(newValue) => {
                                if (newValue || RegistrationData[index].registrationNumber) {
                                    const updated = [...RegistrationData];
                                    updated[index].registrationNumber = newValue;
                                    setValue('RegistrationData', updated);
                                }
                            }}
                        />
                    </StackPanel>
                    <StackPanel itemGap={SPACING.SM}>
                        <Select
                            version={version}
                            label='State'
                            labelToolTip={{ component: 'Foreign Registration State' }}
                            itemsSource={filteredStates}
                            showSearchBox
                            value={state}
                            isEditable={isEditable && !isEmpty(filteredStates)}
                            isPendingChange={isPendingChange(state, noPendingRegistrationData?.state)}
                            validationError={validation?.state}
                            onValueChanged={(newValue) => {
                                const updated = [...RegistrationData];
                                updated[index].state = newValue;
                                setValue('RegistrationData', updated);
                            }}
                        />
                        <DateTimeField
                            label='Formation Date'
                            labelToolTip='Foreign Registration Formation Date'
                            value={formationDate}
                            isEditable={isEditable}
                            isPendingChange={isPendingChange(formationDate, noPendingRegistrationData?.formationDate)}
                            isRequired={!isLuxembourg(country)}
                            validationError={validation?.formationDate}
                            onValueChanged={(newValue) => {
                                const updated = [...RegistrationData];
                                updated[index].formationDate = convertToLocalDate(newValue);
                                setValue('RegistrationData', updated);
                            }}
                        />
                    </StackPanel>
                </DoubleColumnLayout>
            </StackPanel>
            {mode !== 'Readonly' && (
                <StackPanel styleOverrides={{ marginTop: `${SPACING.MD}px`, marginBottom: `${SPACING.SM}px` }}>
                    <FileUpload
                        entityId={legalEntityOId}
                        sectionName={sectionName}
                        documentTypes={documentTypes}
                        taxRegistrationId={taxRegistrationId}
                        showStoredDocuments={!!taxRegistrationId}
                        showStampedDocuments={false}
                        isDisabled={isDeleted}
                        context={context}
                        effectiveDate='required'
                    />
                </StackPanel>
            )}
            {isEditable && <DeleteButton isDeleted={isDeleted} onClick={setIsDeleted} isEnabled />}
        </StackPanel>
    );
}
