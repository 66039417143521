import {
    AdministeredBy,
    AttributeBoolean,
    AttributeDate,
    AttributeNumeric,
    AttributePicklist,
    AttributeRelationships,
    AttributeString,
    AttributeSystempicklists,
    AttributeType,
    Entity,
    EntityHierarchy,
    EntityHierarchyIdentifier,
    EntityHierarchySignatoryDetail,
    EntityHierarchyTaxDetail,
    EntityHierarchyTypes,
    LegalEntity,
    LegalEntityDTO,
    TaxRegistration,
} from '../../../models';
import { LegalEntityInformationData, addressTypes } from '../LegalEntityInformation/model';
import { RecursivePartial, isIn } from '../../../Utilities/ReflectionUtil';
import { getDocPrepAddressFromSectionData, mapRegistrationSection } from './mappers/helpers';
import {
    mapFromSectionData as mapFromCorporateTransparency,
    mapToSectionData as mapToCorporateTransparency,
} from './mappers/LegalEntityCorporateTransparency';
import {
    mapFromSectionData as mapFromForeignRegistrationUpdate,
    mapToSectionData as mapToForeignRegistrationUpdate,
} from './mappers/ForeignRegistrationUpdate';
import {
    mapFromSectionData as mapFromLegalEntityDissolutionBDG,
    mapToSectionData as mapToLegalEntityDissolutionBDG,
} from './mappers/LegalEntityDissolutionBdg';
import {
    mapFromSectionData as mapFromLegalEntityDissolutionFinance,
    mapToSectionData as mapToLegalEntityDissolutionFinance,
} from './mappers/LegalEntityDissolutionFinance';
import {
    mapFromSectionData as mapFromLegalEntityDissolutionLegal,
    mapToSectionData as mapToLegalEntityDissolutionLegal,
} from './mappers/LegalEntityDissolutionLegal';
import {
    mapFromSectionData as mapFromLegalEntityDissolutionLitigation,
    mapToSectionData as mapToLegalEntityDissolutionLitigation,
} from './mappers/LegalEntityDissolutionLitigation';
import {
    mapFromSectionData as mapFromLegalEntityDissolutionRequest,
    mapToSectionData as mapToLegalEntityDissolutionRequest,
} from './mappers/LegalEntityDissolutionDetails';
import {
    mapFromSectionData as mapFromLegalEntityDissolutionTax,
    mapToSectionData as mapToLegalEntityDissolutionTax,
} from './mappers/LegalEntityDissolutionTax';
import {
    mapFromSectionData as mapFromLegalEntityDissolutionTreasury,
    mapToSectionData as mapToLegalEntityDissolutionTreasury,
} from './mappers/LegalEntityDissolutionTreasury';
import {
    mapFromSectionData as mapFromLegalEntityInformation,
    mapToSectionData as mapToLegalEntityInformation,
} from './mappers/LegalEntityInformation';

import { CreateLegalEntityWorkflow } from '../../../models/LegalEntityRequest/Workflow';
import { LegalEntityApprovalData } from '../LegalEntityApproval/model';
import { LegalEntityCorporateTransparencyData } from '../LegalEntityCorporateTransparency/model';
import { LegalEntityDissolutionBDGData } from '../LegalEntityDissolution/LegalEntityDissolutionBdg/model';
import { LegalEntityDissolutionFinanceData } from '../LegalEntityDissolution/LegalEntityDissolutionFinance/model';
import { LegalEntityDissolutionLegalData } from '../LegalEntityDissolution/LegalEntityDissolutionLegal/model';
import { LegalEntityDissolutionLitigationData } from '../LegalEntityDissolution/LegalEntityDissolutionLitigation/model';
import { LegalEntityDissolutionRequestData } from '../LegalEntityDissolution/LegalEntityDissolutionDetails/model';
import { LegalEntityDissolutionTaxData } from '../LegalEntityDissolution/LegalEntityDissolutionTax/model';
import { LegalEntityDissolutionTreasuryData } from '../LegalEntityDissolution/LegalEntityDissolutionTreasury/model';
import { LegalEntityDocumentPreparationData } from '../LegalEntityDocumentPreparation/model';
import { LegalEntityFinanceDetailsData } from '../LegalEntityFinanceDetails/model';
import { LegalEntityOwnershipInformationData } from '../LegalEntityOwnershipInformation/model';
import { LegalEntityRegistrationData } from '../LegalEntityRegistration/model';
import { LegalEntityRegistrationUpdateData } from '../LegalEntityRegistration/LegalEntityForeignRegistrationUpdate/model';
import { LegalEntityRequestData } from '../LegalEntityRequest/model';
import { LegalEntityTaxClassificationData } from '../LegalEntityTaxClassification/model';
import { PartialOrElementPartial } from '../../../Utilities/Types';
import { isEmpty } from '../../../Utilities/Validations';
import { isNil } from 'lodash';
import { isNullOrUndefined } from 'util';

class EntityToSectionMapper {
    public mapToForeignRegistrations(taxRegistrations: TaxRegistration[]) {
        return taxRegistrations
            ?.filter((f) => !f.isDomestic)
            .reduce((acc: { [key: string]: number }, current: TaxRegistration, index: number) => {
                acc[`registrationID${index + 1}`] = current.taxRegistrationId;
                return acc;
            }, {});
    }
    public mapToApprovalSection(
        workItem?: CreateLegalEntityWorkflow,
        sections?: entitySections
    ): LegalEntityApprovalData {
        return {
            EINAndGIINCreationRequired: workItem?.customProperties?.EINCreationRequired,
            bankAccountRequired: workItem?.customProperties?.bankAccountRequired,
            consolidationRequired: workItem?.customProperties?.consolidationRequired,
            financeInformationRequired: workItem?.customProperties?.financeInfoRequired,
            ownershipInfoRequired: workItem?.customProperties?.ownershipInfoRequired,
            taxClassificationRequired: workItem?.customProperties?.taxInfoRequired,
            administeredBy: sections?.Details.administeredBy,
            structureType: sections?.Details.structureType,
            isOldStructureType: sections?.Details.isOldStructureType ?? false,
            domesticState: sections?.DocumentPreparation.domesticState,
            domesticCountry: sections?.DocumentPreparation.domesticCountry,
            isDisregardedEntity: sections?.Details.isDisregardedEntity,
        };
    }
    public mapToSections(entityDto: LegalEntityDTO): entitySections {
        const { entity, entityHierarchies } = entityDto;
        const {
            legalEntity: {
                businessUnitOId,
                subBusinessUnitOId,
                administeredBy,
                legalTypeID,
                taxEntityTypeId,
                newStructureTypeId,
                businessDriver,
                fatcaentityTypeId,
                usStateTaxClassificationTypeId,
                usTaxClassificationTypeId,
                crsClassificationId,
                crsSubClassification,
                crsSubClassificationDetailId,
                isCtbElectionApproved,
                tax8832EffectiveDate,
                isDisregardedEntity,
                chapterThreeStatusId,
            },
            primaryName,
            shortName,
            addresses,
            taxRegistrations,
            objectAttributeBooleans,
            objectAttributeStrings,
            objectAttributeDates,
            objectAttributeRelationships,
            objectAttributePicklists,
            objectAttributeSystemPicklists,
            objectAttributeNumerics,
        } = entity;
        const projectEntity = entityHierarchies?.find(EntityHierarchyTypes.project.isOfType);
        const externalAdminEntity = entityHierarchies?.find(EntityHierarchyTypes.externalAdmin.isOfType);
        const administratorHKAPACEntity = entityHierarchies?.find(EntityHierarchyTypes.administratorHKAPAC.isOfType);
        const portfolioEntity = entityHierarchies?.find(EntityHierarchyTypes.portfolio.isOfType);
        const lawFirmEntity = entityHierarchies?.find(EntityHierarchyTypes.lawFirm.isOfType);
        const taxAuditorEntity = entityHierarchies?.find(EntityHierarchyTypes.taxAuditor.isOfType);
        const associatedDeal = entityHierarchies?.filter(EntityHierarchyTypes.associatedDeal.isOfType).slice(-1)[0];
        const taxPreparerEntity = entityHierarchies?.find(EntityHierarchyTypes.taxPreparer.isOfType);
        const signatoryEntities = entityHierarchies?.filter(EntityHierarchyTypes.signatory.isOfType);
        const legalOwnershipEntities = entityHierarchies?.filter(EntityHierarchyTypes.legalOwnership.isOfType);
        const domesticTaxReg = taxRegistrations?.find(this.isActiveDomestic.bind(this, true));
        const foreignTaxRegs = taxRegistrations?.filter(this.isActiveDomestic.bind(this, false));
        const booleanAttributes =
            objectAttributeBooleans?.reduce((prev, curr) => {
                prev[curr.objectAttributeId] = curr.value;
                return prev;
            }, {} as { [attr in AttributeType]?: boolean }) ?? {};
        const stringAttributes =
            objectAttributeStrings?.reduce((prev, curr) => {
                prev[curr.objectAttributeId] = curr.value;
                return prev;
            }, {} as { [attr in AttributeType]?: string }) ?? {};
        const dateAttributes =
            objectAttributeDates?.reduce((prev, curr) => {
                prev[curr.objectAttributeId] = curr.value;
                return prev;
            }, {} as { [attr in AttributeType]?: Date }) ?? {};
        const headquarterAddress = addresses?.find(
            ({ addressTypeId }) => addressTypeId === addressTypes.headquarterAddress.Key
        );
        const registeredOfficeAddress = addresses?.find(
            ({ addressTypeId }) => addressTypeId === addressTypes.registeredOfficeAddress.Key
        );
        const isOldStructureType = isNullOrUndefined(newStructureTypeId) && !isNullOrUndefined(taxEntityTypeId);
        const structureType = isOldStructureType ? taxEntityTypeId : newStructureTypeId;
        return {
            target: entityDto,
            Details: {
                entityOid: entity.entityOId,
                legalName: primaryName,
                shortName: shortName,
                businessDriverId: businessDriver,
                businessUnitOId,
                subBusinessUnitOId,
                administeredBy,
                legalTypeId: legalTypeID,
                structureType,
                isOldStructureType,
                projectEntityOId: projectEntity?.parentEntityOId, //search in elastic search
                externalAdminOId: externalAdminEntity?.childEntityOId, //maps ref data
                administratorHKAPACOId: administratorHKAPACEntity?.childEntityOId, //maps ref data
                lawFirmEntityOId: lawFirmEntity?.childEntityOId, //maps to ref data
                portfolioId: portfolioEntity?.childEntityOId, //maps ref data
                taxAuditorOId: taxAuditorEntity?.childEntityOId, //maps ref data
                associatedDeal: associatedDeal?.parentEntityOId, //maps to ref data
                externalAdminContact: externalAdminEntity?.serviceProviderDetails?.find(
                    EntityHierarchyTypes.externalAdmin.isOfDetailType
                )?.serviceProviderContactEmailId, //map to ref data
                isBlueprintEntity: booleanAttributes[AttributeType.isBlueprintEntity],
                isOrderChop: booleanAttributes[AttributeType.isOrderChop],
                isOrderSeal: booleanAttributes[AttributeType.isOrderSeal],
                hedgehogID: stringAttributes[AttributeType.hedgehogID],
                remarks: stringAttributes[AttributeType.remarks],
                financialYearEndDate: dateAttributes[AttributeType.financialYearEndDate],
                isDisregardedEntity,
            },
            LegalEntityInformation: mapToLegalEntityInformation(entityDto),
            DocumentPreparation: {
                key: domesticTaxReg?.taxRegistrationId,
                domesticRegisteredAgent: domesticTaxReg?.registeredAgentId,
                domesticCountry: domesticTaxReg?.formationCountryId,
                domesticState: domesticTaxReg?.formationStateId,
                entityOid: entity?.entityOId,
                legalType: legalTypeID,
                foreignRegistrations: foreignTaxRegs?.map((r) => {
                    return {
                        key: r.taxRegistrationId,
                        id: r.taxRegistrationId,
                        registeredAgent: r.registeredAgentId,
                        country: r.formationCountryId,
                        state: r.formationStateId,
                        isDeleted: false,
                    };
                }),
                headquarterAddress: headquarterAddress,
                registeredOfficeAddress: registeredOfficeAddress,
                administeredBy,
                isBlueprintEntity: booleanAttributes[AttributeType.isBlueprintEntity],
            },
            FinanceInformation: {
                oracleNumber: this.safeParseInt(stringAttributes[AttributeType.oracleNumber]),
                productNumber: stringAttributes[AttributeType.productNumber],
                costCenterNumber: stringAttributes[AttributeType.costCenterNumber],
                oracleLineOfBusinessId: objectAttributeSystemPicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.oracleLineOfBusinessId
                )?.systemPicklistItemId,
                oracleLocationCodeId: objectAttributeSystemPicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.oracleLocationCodeId
                )?.systemPicklistItemId,
                investranId: objectAttributePicklists?.find((a) => a.objectAttributeId === AttributeType.investranId)
                    ?.picklistItemId,
                primaryLedgerId: objectAttributePicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.primaryLedgerId
                )?.picklistItemId,
                consolidatingStatusInOracleId: objectAttributePicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.consolidatingStatusInOracleId
                )?.picklistItemId,
                repaymentGroupId: objectAttributePicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.repaymentGroupId
                )?.picklistItemId,
                wssPaymentTypeId: objectAttributePicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.wssPaymentTypeId
                )?.picklistItemId,
                investranParentFund: objectAttributeRelationships?.find(
                    (a) => a.objectAttributeId === AttributeType.investranParentFund
                )?.relatedObjectId,
                investranFundFamily: objectAttributePicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.investranFundFamily
                )?.picklistItemId,
                oracleGSO: objectAttributePicklists?.find((a) => a.objectAttributeId === AttributeType.oracleGSO)
                    ?.picklistItemId,
                investranDomain: objectAttributeSystemPicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.investranDomain
                )?.systemPicklistItemId,
                billingOracleNumber: stringAttributes[AttributeType.billingOracleNumber],
                paymentsToWss: booleanAttributes[AttributeType.paymentsToWss],
                isAgisRequired: booleanAttributes[AttributeType.isAgisRequired],
                pushToOracle: booleanAttributes[AttributeType.pushToOracle],
                entityController: objectAttributeRelationships?.find(
                    (a) => a.objectAttributeId === AttributeType.entityController
                )?.relatedObjectId,
                billingProductNumber: stringAttributes[AttributeType.billingProductNumber],
                billingCostCenterNumber: stringAttributes[AttributeType.billingCostCenterNumber],
                billingProjectCode: stringAttributes[AttributeType.billingProjectCode],
                treasuryLineOfBusinessId: objectAttributePicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.treasuryLineOfBusinessId
                )?.picklistItemId,
                gpInterestType: objectAttributePicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.gpInterestType
                )?.picklistItemId,
                gpEntityTier: objectAttributePicklists?.find((a) => a.objectAttributeId === AttributeType.gpEntityTier)
                    ?.picklistItemId,
                investranGPFundFamily: objectAttributeSystemPicklists?.find(
                    (a) => a.objectAttributeId === AttributeType.investranGPFundFamily
                )?.systemPicklistItemId,
                businessUnitOId,
                subBusinessUnitOId,
                administeredBy,
            },
            TaxClassification: {
                fatcaClassificationId: fatcaentityTypeId,
                crsClassificationId,
                crsSubClassificationDetailId,
                crsSubClassificationId: crsSubClassification,
                isCtbElectionApproved: isCtbElectionApproved,
                tax8832EffectiveDate: tax8832EffectiveDate,
                isGIINRequired: booleanAttributes[AttributeType.isGIINRequired],
                usStateTaxClassificationTypeId,
                usTaxClassificationTypeId,
                legalName: primaryName,
                structureType,
                isOldStructureType,
                domesticState: domesticTaxReg?.formationStateId,
                domesticCountry: domesticTaxReg?.formationCountryId,
                domesticFormationDate: domesticTaxReg?.formationDate,
                businessUnitOId,
                entityOId: entity?.entityOId,
                chapterThreeStatusId: chapterThreeStatusId,
                taxPreparerEntityOId: taxPreparerEntity?.childEntityOId, //maps to ref data
                isPortCoManaged: !taxPreparerEntity?.childEntityOId, // only UI prop
            },
            DomesticRegistration: {
                ...mapRegistrationSection(domesticTaxReg, entity?.entityOId ?? 0),
                legalType: legalTypeID,
            },
            CorporateTransparency: mapToCorporateTransparency(entityDto),
            ForeignRegistration:
                foreignTaxRegs?.map((registration: TaxRegistration) => {
                    return {
                        ...mapRegistrationSection(registration, entity?.entityOId ?? 0),
                        legalType: legalTypeID,
                    };
                }) ?? [],
            ForeignRegistrationUpdate: mapToForeignRegistrationUpdate(entityDto),
            OwnershipInformation: {
                domesticKey: domesticTaxReg?.taxRegistrationId ?? 0,
                domesticCountry: domesticTaxReg?.formationCountryId ?? 0,
                id: entity?.entityOId ?? 0,
                legalType: legalTypeID,
                authorizedPerson:
                    signatoryEntities?.flatMap(
                        (hierarchy) =>
                            hierarchy.signatoryDetails?.map((sig) => {
                                return {
                                    key: {
                                        hierarchyId: hierarchy.entityHierarchyOId as number,
                                        signatoryId: sig.signatoryDetailId,
                                    },
                                    name: hierarchy.childEntityOId,
                                    title: sig.signatoryBlockTitleId,
                                    appointmentDate: sig.appointmentDate,
                                    resignationDate: sig.resignationDate,
                                    isDeleted: false,
                                };
                            }) ?? []
                    ) ?? [],
                ownershipDetails:
                    legalOwnershipEntities?.map((hierarchy) => {
                        return {
                            key: hierarchy.entityHierarchyOId as number,
                            parentEntity: hierarchy.parentEntityOId,
                            ownershipName: hierarchy?.ownershipName,
                            interestUnit: hierarchy.taxOwnershipDetail?.interestUnitTypeId,
                            interestClass: hierarchy.taxOwnershipDetail?.interestClassTypeId,
                            numberOfUnits: hierarchy.taxOwnershipDetail?.numberOfUnits,
                            ownerShip: hierarchy.taxOwnershipDetail?.economicOwnership,
                            reportingType: hierarchy.taxOwnershipDetail?.reportingTypeId,
                            interestType: hierarchy.taxOwnershipDetail?.interestTypeId,
                            votingRight: hierarchy.taxOwnershipDetail?.votingRight,
                            shareClass: hierarchy.taxOwnershipDetail?.shareClass,
                            nominalValue: hierarchy.taxOwnershipDetail?.nominalValue,
                            currency: hierarchy.taxOwnershipDetail?.currency,
                        };
                    }) ?? [],
            },
            DissolutionRequest: mapToLegalEntityDissolutionRequest(entityDto),
            DissolutionLitigation: mapToLegalEntityDissolutionLitigation(entityDto),
            DissolutionTax: mapToLegalEntityDissolutionTax(entityDto),
            DissolutionFinance: mapToLegalEntityDissolutionFinance(entityDto),
            DissolutionLegal: mapToLegalEntityDissolutionLegal(entityDto),
            DissolutionTreasury: mapToLegalEntityDissolutionTreasury(entityDto),
            DissolutionBDG: mapToLegalEntityDissolutionBDG(entityDto),
        };
    }

    public mapToDto<K extends keyof entitySections, T extends PartialOrElementPartial<entitySections[K]>>(
        source: LegalEntityDTO | undefined,
        sectionType: K,
        section: T
    ): RecursivePartial<LegalEntityDTO> {
        const toReturn = {} as Partial<LegalEntityDTO>;

        switch (sectionType) {
            case 'Details':
                return this.mapFromDetails(source, section as Partial<entitySections[K]>);
            case 'LegalEntityInformation':
                return mapFromLegalEntityInformation(source, section as Partial<entitySections[K]>);
            case 'DocumentPreparation':
                return this.mapFromDocumentPreparation(source, section as Partial<entitySections[K]>);
            case 'FinanceInformation':
                return this.mapFromFinanceInformation(source, section as Partial<entitySections[K]>);
            case 'TaxClassification':
                return this.mapFromTaxClassification(source, section as Partial<entitySections[K]>);
            case 'DomesticRegistration':
                return this.mapFromRegistration(source, section as Partial<entitySections[K]>);
            case 'CorporateTransparency':
                return mapFromCorporateTransparency(source, section as Partial<entitySections[K]>);
            case 'ForeignRegistration':
                return this.mapFromRegistration(
                    source,
                    (section as Partial<entitySections['ForeignRegistration'][number]>[])[0]
                );
            case 'ForeignRegistrationUpdate':
                return mapFromForeignRegistrationUpdate(section as entitySections['ForeignRegistrationUpdate']);
            case 'OwnershipInformation':
                return this.mapFromOwnershipInformation(source, section as Partial<entitySections[K]>);
            case 'DissolutionRequest':
                return mapFromLegalEntityDissolutionRequest(source, section as Partial<entitySections[K]>);
            case 'DissolutionLitigation':
                return mapFromLegalEntityDissolutionLitigation(source, section as Partial<entitySections[K]>);
            case 'DissolutionTax':
                return mapFromLegalEntityDissolutionTax(source, section as Partial<entitySections[K]>);
            case 'DissolutionFinance':
                return mapFromLegalEntityDissolutionFinance(source, section as Partial<entitySections[K]>);
            case 'DissolutionLegal':
                return mapFromLegalEntityDissolutionLegal(source, section as Partial<entitySections[K]>);
            case 'DissolutionTreasury':
                return mapFromLegalEntityDissolutionTreasury(source, section as Partial<entitySections[K]>);
            case 'DissolutionBDG':
                return mapFromLegalEntityDissolutionBDG(source, section as Partial<entitySections[K]>);
        }
        return toReturn;
    }

    public mapToWorkItem(approvalSection: LegalEntityApprovalData): RecursivePartial<CreateLegalEntityWorkflow> {
        const toReturn = {} as RecursivePartial<CreateLegalEntityWorkflow>;
        const customProps = {} as Partial<CreateLegalEntityWorkflow['customProperties']>;
        this.setIfDefined(approvalSection, 'EINAndGIINCreationRequired', (v) => (customProps.EINCreationRequired = v));
        this.setIfDefined(approvalSection, 'bankAccountRequired', (v) => (customProps.bankAccountRequired = v));
        this.setIfDefined(approvalSection, 'consolidationRequired', (v) => (customProps.consolidationRequired = v));
        this.setIfDefined(approvalSection, 'financeInformationRequired', (v) => (customProps.financeInfoRequired = v));
        this.setIfDefined(approvalSection, 'ownershipInfoRequired', (v) => (customProps.ownershipInfoRequired = v));
        this.setIfDefined(approvalSection, 'taxClassificationRequired', (v) => (customProps.taxInfoRequired = v));
        if (Object.keys(customProps).length > 0) {
            toReturn.customProperties = customProps;
        }
        return toReturn;
    }

    private mapFromDocumentPreparation(
        source: LegalEntityDTO | undefined,
        section: Partial<LegalEntityDocumentPreparationData>
    ): RecursivePartial<LegalEntityDTO> {
        const toReturn = {} as RecursivePartial<LegalEntityDTO>;
        toReturn.entity = {};

        const domesticTaxReg: Partial<TaxRegistration> = {};
        const taxRegistrationsToDelete: LegalEntityDTO['removedTaxRegistrations'] = [];
        const addressesToDelete: LegalEntityDTO['removedAddresses'] = [];

        this.setIfDefined(section, 'domesticRegisteredAgent', (v) => (domesticTaxReg.registeredAgentId = v));
        this.setIfDefined(section, 'domesticCountry', (v) => (domesticTaxReg.formationCountryId = v));
        this.setIfDefined(section, 'domesticState', (v) => (domesticTaxReg.formationStateId = v));
        const registrations: Partial<TaxRegistration>[] =
            section.foreignRegistrations
                ?.map((r) => {
                    if (r.isDeleted) {
                        !isNil(r.key) && taxRegistrationsToDelete.push(r.key);
                        return null;
                    }
                    const taxRegistration: Partial<TaxRegistration> = {};
                    this.setIfDefined(r, 'country', (v) => (taxRegistration.formationCountryId = v));
                    this.setIfDefined(r, 'state', (v) => (taxRegistration.formationStateId = v));
                    this.setIfDefined(r, 'registeredAgent', (v) => (taxRegistration.registeredAgentId = v));
                    if (Object.keys(taxRegistration).length === 0) return null;
                    taxRegistration.isDomestic = false;
                    if (!isNullOrUndefined(r.key)) taxRegistration.taxRegistrationId = r.key;
                    return taxRegistration;
                })
                .filter((r): r is Partial<TaxRegistration> => r !== null) ?? [];
        if (Object.keys(domesticTaxReg).length > 0) {
            const domesticRegistrationId = source?.entity.taxRegistrations?.find(
                this.isActiveDomestic.bind(this, true)
            )?.taxRegistrationId;
            if (!isNullOrUndefined(domesticRegistrationId)) domesticTaxReg.taxRegistrationId = domesticRegistrationId;
            domesticTaxReg.isDomestic = true;
            registrations.unshift(domesticTaxReg);
        }

        const { processedAddress: processedHeadquarterAddress, hasInputData: hasHeadquarterAddressInputData } =
            getDocPrepAddressFromSectionData(section.headquarterAddress);

        if (hasHeadquarterAddressInputData || processedHeadquarterAddress.addressId) {
            processedHeadquarterAddress.addressTypeId = addressTypes.headquarterAddress.Key;
            toReturn.entity.addresses = [processedHeadquarterAddress];
        }

        const {
            processedAddress: processedRegisteredOfficeAddress,
            hasInputData: hasRegisteredOfficeAddressInputData,
        } = getDocPrepAddressFromSectionData(section.registeredOfficeAddress);

        if (hasRegisteredOfficeAddressInputData) {
            processedRegisteredOfficeAddress.addressTypeId = addressTypes.registeredOfficeAddress.Key;
            toReturn.entity.addresses
                ? toReturn.entity.addresses.push(processedRegisteredOfficeAddress)
                : (toReturn.entity.addresses = [processedRegisteredOfficeAddress]);
        }
        if (processedRegisteredOfficeAddress.addressId && !hasRegisteredOfficeAddressInputData) {
            addressesToDelete.push(processedRegisteredOfficeAddress.addressId);
        }

        if (registrations.length > 0) {
            toReturn.entity.taxRegistrations = registrations;
        }
        toReturn.removedTaxRegistrations = taxRegistrationsToDelete;
        toReturn.removedAddresses = addressesToDelete;
        return toReturn;
    }

    private isActiveDomestic(domestic: boolean, registration: TaxRegistration) {
        return isNullOrUndefined(registration.dissolutionDate) && registration.isDomestic === domestic;
    }

    private mapFromDetails(original: LegalEntityDTO | undefined, section: Partial<LegalEntityRequestData>) {
        const toReturn = {} as RecursivePartial<LegalEntityDTO>;
        const legalEntity = {} as Partial<LegalEntity>;
        const entityHierarchiesToDelete: LegalEntityDTO['removedEntityHierarchies'] = [];
        const entityHierarchies: RecursivePartial<EntityHierarchy>[] = [];
        const booleanAttributes: Partial<AttributeBoolean>[] = [];
        const stringAttributes: Partial<AttributeString>[] = [];
        const dateAttributes: Partial<AttributeDate>[] = [];
        const addressesToDelete: LegalEntityDTO['removedAddresses'] = [];
        const associatedDealsToDelete: LegalEntityDTO['removedAssociatedDeals'] = [];
        const registeredOfficeAddress = original?.entity.addresses?.find(
            ({ addressTypeId }) => addressTypeId === addressTypes.registeredOfficeAddress.Key
        );

        const createOrUpdateBoolean = (attributeType: AttributeType, value: boolean | undefined) => {
            const inSource = original?.entity.objectAttributeBooleans?.find(
                (a) => a.objectAttributeId === attributeType
            );
            if (inSource) {
                booleanAttributes.push({
                    objectAttributeInstanceId: inSource.objectAttributeInstanceId,
                    value: value,
                    objectAttributeId: inSource.objectAttributeId,
                });
            } else {
                !isEmpty(value) && booleanAttributes.push({ objectAttributeId: attributeType, value: value });
            }
        };
        const createOrUpdateString = (attributeType: AttributeType, value: string | undefined) => {
            const inSource = original?.entity.objectAttributeStrings?.find(
                (a) => a.objectAttributeId === attributeType
            );
            if (inSource) {
                stringAttributes.push({
                    objectAttributeInstanceId: inSource.objectAttributeInstanceId,
                    value: value?.toString(),
                    objectAttributeId: inSource.objectAttributeId,
                });
            } else {
                !isEmpty(value) && stringAttributes.push({ objectAttributeId: attributeType, value: value });
            }
        };
        const createOrUpdateDate = (attributeType: AttributeType, value: Date | undefined) => {
            const inSource = original?.entity.objectAttributeDates?.find((a) => a.objectAttributeId === attributeType);
            if (inSource) {
                dateAttributes.push({
                    objectAttributeInstanceId: inSource.objectAttributeInstanceId,
                    value,
                    objectAttributeId: inSource.objectAttributeId,
                });
            } else {
                !isEmpty(value) && dateAttributes.push({ objectAttributeId: attributeType, value });
            }
        };
        !!AttributeType.isBlueprintEntity
            ? this.setIfDefined(section, 'isBlueprintEntity', (v) =>
                  createOrUpdateBoolean(AttributeType.isBlueprintEntity, v)
              )
            : this.setIfDefined(section, 'isBlueprintEntity', () => false);
        this.setIfDefined(section, 'isOrderChop', (v) => createOrUpdateBoolean(AttributeType.isOrderChop, v));
        this.setIfDefined(section, 'isOrderSeal', (v) => createOrUpdateBoolean(AttributeType.isOrderSeal, v));
        this.setIfDefined(section, 'isDisregardedEntity', (v) => (legalEntity.isDisregardedEntity = v));
        this.setIfDefined(section, 'legalName', (ln) => (toReturn.entity = { primaryName: ln?.trim() }));
        if (toReturn?.entity) {
            if (isIn(section, 'shortName')) {
                toReturn.entity.shortName = section.shortName?.toString();
            }
        } else {
            this.setIfDefined(section, 'shortName', (sn) => (toReturn.entity = { shortName: sn?.trim() }));
        }
        this.setIfDefined(section, 'businessDriverId', (v) => (legalEntity.businessDriver = v));
        this.setIfDefined(section, 'businessUnitOId', (v) => (legalEntity.businessUnitOId = v));
        this.setIfDefined(section, 'remarks', (v) => createOrUpdateString(AttributeType.remarks, v));
        this.setIfDefined(section, 'financialYearEndDate', (v) =>
            createOrUpdateDate(AttributeType.financialYearEndDate, v)
        );
        this.setIfDefined(section, 'hedgehogID', (v) => createOrUpdateString(AttributeType.hedgehogID, v));
        this.setIfDefined(section, 'subBusinessUnitOId', (v) => (legalEntity.subBusinessUnitOId = v));
        this.setIfDefined(section, 'administeredBy', (v) => (legalEntity.administeredBy = v));
        this.setIfDefined(section, 'legalTypeId', (v) => (legalEntity.legalTypeID = v));
        this.setIfDefined(section, 'structureType', (v) => (legalEntity.newStructureTypeId = v));
        const setEntityOId = (type: EntityHierarchyIdentifier, entityOId: number | undefined) => {
            const existingHierarchy = original?.entityHierarchies?.find(type.isOfType);
            if (isNullOrUndefined(entityOId)) {
                if (existingHierarchy?.entityHierarchyOId === undefined) return;
                const { entityHierarchyOId } = existingHierarchy;
                entityHierarchiesToDelete.push({ entityHierarchyOId });
                return;
            }

            let entityHierarchyUpdate: RecursivePartial<EntityHierarchy> = { [type.entityOIdField]: entityOId };
            if (!isNullOrUndefined(existingHierarchy)) {
                entityHierarchyUpdate.entityHierarchyOId = existingHierarchy.entityHierarchyOId;
                entityHierarchyUpdate.entityHierarchyTypeId = existingHierarchy.entityHierarchyTypeId;
                entityHierarchyUpdate.serviceProviderDetails = existingHierarchy.serviceProviderDetails;
            } else {
                entityHierarchyUpdate = type.generateHierarchy(entityHierarchyUpdate);
            }
            entityHierarchies.push(entityHierarchyUpdate);
        };
        if (EntityHierarchyTypes.project)
            this.setIfDefined(section, 'projectEntityOId', setEntityOId.bind(null, EntityHierarchyTypes.project));
        this.setIfDefined(section, 'externalAdminOId', setEntityOId.bind(null, EntityHierarchyTypes.externalAdmin));
        this.setIfDefined(section, 'portfolioId', setEntityOId.bind(null, EntityHierarchyTypes.portfolio));
        this.setIfDefined(section, 'lawFirmEntityOId', setEntityOId.bind(null, EntityHierarchyTypes.lawFirm));
        this.setIfDefined(section, 'taxAuditorOId', setEntityOId.bind(null, EntityHierarchyTypes.taxAuditor));
        this.setIfDefined(
            section,
            'administratorHKAPACOId',
            setEntityOId.bind(null, EntityHierarchyTypes.administratorHKAPAC)
        );
        const setAssociatedDeal = (type: EntityHierarchyIdentifier, entityOId: number | undefined) => {
            const existingHierarchy = original?.entityHierarchies?.find(type.isOfType);
            if (isNil(entityOId)) {
                if (existingHierarchy?.entityHierarchyOId === undefined) {
                    return;
                }

                const { entityHierarchyOId } = existingHierarchy;
                associatedDealsToDelete.push(entityHierarchyOId);
                return;
            }

            let entityHierarchyUpdate: RecursivePartial<EntityHierarchy> = { [type.entityOIdField]: entityOId };
            if (!isNil(existingHierarchy)) {
                entityHierarchyUpdate.entityHierarchyTypeId = existingHierarchy.entityHierarchyTypeId;
                entityHierarchyUpdate.entityHierarchyOId = existingHierarchy.entityHierarchyOId;
            } else {
                entityHierarchyUpdate = type.generateHierarchy(entityHierarchyUpdate);
            }
            entityHierarchies.push(entityHierarchyUpdate);
        };
        this.setIfDefined(section, 'associatedDeal', setAssociatedDeal.bind(null, EntityHierarchyTypes.associatedDeal));

        const setContact = (hierarchyType: EntityHierarchyIdentifier, v: number | undefined) => {
            const existingHierarchy = original?.entityHierarchies?.find(hierarchyType.isOfType);
            const existingDetail = existingHierarchy?.serviceProviderDetails?.find(hierarchyType.isOfDetailType);
            let updateHierarchy = entityHierarchies.find(
                (e) =>
                    (existingHierarchy !== undefined &&
                        e.entityHierarchyOId === existingHierarchy.entityHierarchyOId) ||
                    (e.entityHierarchyTypeId !== undefined &&
                        hierarchyType.isOfType(e as { entityHierarchyTypeId: number; serviceProviderDetails: [] }))
            );
            const hierarchyPendingDelete = entityHierarchiesToDelete.find(
                (h) => h.entityHierarchyOId === existingHierarchy?.entityHierarchyOId
            );
            if (hierarchyPendingDelete && (hierarchyPendingDelete.serviceProviderDetailIds?.length ?? 0) === 0) return;
            if (isNullOrUndefined(v) && !existingDetail) return;
            if (!updateHierarchy) {
                if (!existingHierarchy) {
                    return console.warn(`Could not find a hierarchy for contact ${v}. Ignoring.`);
                }
                updateHierarchy = { entityHierarchyOId: existingHierarchy.entityHierarchyOId };
                entityHierarchies.push(updateHierarchy);
            }

            if (updateHierarchy) {
                let updateDetail = updateHierarchy.serviceProviderDetails?.find(hierarchyType.isOfDetailType);
                if (!updateDetail) {
                    updateHierarchy.serviceProviderDetails = updateHierarchy.serviceProviderDetails || [];
                    if (existingDetail) {
                        updateDetail = {
                            serviceProviderContactEmailId: v,
                            serviceProviderDetailId: existingDetail.serviceProviderDetailId,
                        };
                    } else {
                        updateDetail = {
                            serviceProviderTypeId: hierarchyType.serviceProviderType,
                            serviceProviderContactEmailId: v,
                        };
                    }
                    updateHierarchy.serviceProviderDetails.push(updateDetail);
                } else {
                    updateDetail.serviceProviderContactEmailId = v;
                }
            }
        };
        this.setIfDefined(section, 'externalAdminContact', setContact.bind(null, EntityHierarchyTypes.externalAdmin));
        if (Object.keys(legalEntity).length > 0) {
            toReturn.entity = toReturn.entity ?? {};
            toReturn.entity.legalEntity = legalEntity;
            if (booleanAttributes.length > 0 && toReturn.entity)
                toReturn.entity.objectAttributeBooleans = booleanAttributes;
            if (stringAttributes.length > 0 && toReturn.entity)
                toReturn.entity.objectAttributeStrings = stringAttributes;
            if (dateAttributes.length > 0 && toReturn.entity) toReturn.entity.objectAttributeDates = dateAttributes;
        } else {
            toReturn.entity = toReturn.entity ?? {};
            if (booleanAttributes.length > 0 && toReturn.entity)
                toReturn.entity.objectAttributeBooleans = booleanAttributes;
            if (stringAttributes.length > 0 && toReturn.entity)
                toReturn.entity.objectAttributeStrings = stringAttributes;
            if (dateAttributes.length > 0 && toReturn.entity) toReturn.entity.objectAttributeDates = dateAttributes;
        }
        if (
            registeredOfficeAddress?.addressId &&
            (section.isBlueprintEntity === false ||
                (section.administeredBy && section.administeredBy !== AdministeredBy.Asia))
        ) {
            addressesToDelete.push(registeredOfficeAddress.addressId);
        }

        if (entityHierarchiesToDelete.length > 0) toReturn.removedEntityHierarchies = entityHierarchiesToDelete;
        if (entityHierarchies.length > 0) toReturn.entityHierarchies = entityHierarchies;
        if (addressesToDelete.length > 0) toReturn.removedAddresses = addressesToDelete;
        if (associatedDealsToDelete.length > 0) toReturn.removedAssociatedDeals = associatedDealsToDelete;
        if (toReturn?.entity && !toReturn?.entity?.shortName) {
            delete toReturn?.entity?.shortName;
        }
        return toReturn;
    }

    private mapFromFinanceInformation(
        source: LegalEntityDTO | undefined,
        section: LegalEntityFinanceDetailsData
    ): RecursivePartial<LegalEntityDTO> {
        const entity: RecursivePartial<
            Pick<
                Entity,
                | 'objectAttributeStrings'
                | 'objectAttributeBooleans'
                | 'objectAttributeDates'
                | 'objectAttributeNumerics'
                | 'objectAttributePicklists'
                | 'objectAttributeSystemPicklists'
                | 'objectAttributeRelationships'
            >
        > = {};
        const booleanAttributes: Partial<AttributeBoolean>[] = [];
        const createOrUpdateBoolean = (attributeType: AttributeType, value: boolean | undefined) => {
            const inSource = source?.entity.objectAttributeBooleans?.find((a) => a.objectAttributeId === attributeType);
            if (inSource) {
                booleanAttributes.push({
                    objectAttributeInstanceId: inSource.objectAttributeInstanceId,
                    value: value,
                    objectAttributeId: inSource.objectAttributeId,
                });
            } else {
                booleanAttributes.push({ objectAttributeId: attributeType, value: value });
            }
        };
        this.setIfDefined(section, 'paymentsToWss', (v) => createOrUpdateBoolean(AttributeType.paymentsToWss, v));
        this.setIfDefined(section, 'isAgisRequired', (v) => createOrUpdateBoolean(AttributeType.isAgisRequired, v));
        this.setIfDefined(section, 'pushToOracle', (v) => createOrUpdateBoolean(AttributeType.pushToOracle, v));
        const stringAttributes: Partial<AttributeString>[] = [];
        const createOrUpdateString = (attributeType: AttributeType, value: string | undefined) => {
            const inSource = source?.entity.objectAttributeStrings?.find((a) => a.objectAttributeId === attributeType);
            if (inSource) {
                stringAttributes.push({
                    objectAttributeInstanceId: inSource.objectAttributeInstanceId,
                    value: value?.toString(),
                    objectAttributeId: inSource.objectAttributeId,
                });
            } else {
                stringAttributes.push({ objectAttributeId: attributeType, value: value });
            }
        };
        const numericAttributes: Partial<AttributeNumeric>[] = [];
        const createOrUpdateNumeric = (attributeType: AttributeType, value: number | undefined) => {
            const inSource = source?.entity.objectAttributeNumerics?.find((a) => a.objectAttributeId === attributeType);
            if (inSource) {
                numericAttributes.push({
                    objectAttributeInstanceId: inSource.objectAttributeInstanceId,
                    value: value,
                    objectAttributeId: inSource.objectAttributeId,
                });
            } else {
                numericAttributes.push({ objectAttributeId: attributeType, value: value });
            }
        };
        this.setIfDefined(section, 'productNumber', (v) => createOrUpdateString(AttributeType.productNumber, v));
        this.setIfDefined(section, 'costCenterNumber', (v) => createOrUpdateString(AttributeType.costCenterNumber, v));
        this.setIfDefined(section, 'billingOracleNumber', (v) =>
            createOrUpdateString(AttributeType.billingOracleNumber, v)
        );
        this.setIfDefined(section, 'billingProjectCode', (v) =>
            createOrUpdateString(AttributeType.billingProjectCode, v)
        );
        this.setIfDefined(section, 'billingProductNumber', (v) =>
            createOrUpdateString(AttributeType.billingProductNumber, v)
        );
        this.setIfDefined(section, 'billingCostCenterNumber', (v) =>
            createOrUpdateString(AttributeType.billingCostCenterNumber, v)
        );

        const relationAttributes: Partial<AttributeRelationships>[] = [];
        const createOrUpdateRelationList = (attributeType: AttributeType, value: number | undefined) => {
            const inSource = source?.entity.objectAttributeRelationships?.find(
                (a) => a.objectAttributeId === attributeType
            );
            if (inSource) {
                relationAttributes.push({
                    objectAttributeInstanceId: inSource.objectAttributeInstanceId,
                    relatedObjectId: value,
                    objectAttributeId: inSource.objectAttributeId,
                });
            } else {
                relationAttributes.push({ objectAttributeId: attributeType, relatedObjectId: value });
            }
        };
        this.setIfDefined(section, 'entityController', (v) =>
            createOrUpdateRelationList(AttributeType.entityController, v)
        );
        this.setIfDefined(section, 'investranParentFund', (v) =>
            createOrUpdateRelationList(AttributeType.investranParentFund, v)
        );
        const systemPickListAttributes: Partial<AttributeSystempicklists>[] = [];
        const createOrUpdateSystemPickList = (attributeType: AttributeType, value: number | undefined) => {
            const inSource = source?.entity.objectAttributeSystemPicklists?.find(
                (a) => a.objectAttributeId === attributeType
            );
            if (inSource) {
                systemPickListAttributes.push({
                    objectAttributeInstanceId: inSource.objectAttributeInstanceId,
                    systemPicklistItemId: value,
                    objectAttributeId: inSource.objectAttributeId,
                });
            } else {
                systemPickListAttributes.push({ objectAttributeId: attributeType, systemPicklistItemId: value });
            }
        };
        this.setIfDefined(section, 'investranDomain', (v) =>
            createOrUpdateSystemPickList(AttributeType.investranDomain, v)
        );
        this.setIfDefined(section, 'oracleLineOfBusinessId', (v) =>
            createOrUpdateSystemPickList(AttributeType.oracleLineOfBusinessId, v)
        );
        this.setIfDefined(section, 'oracleLocationCodeId', (v) =>
            createOrUpdateSystemPickList(AttributeType.oracleLocationCodeId, v)
        );
        const pickListAttributes: Partial<AttributePicklist>[] = [];
        const createOrUpdatePickList = (attributeType: AttributeType, value: number | undefined) => {
            const inSource = source?.entity.objectAttributePicklists?.find(
                (a) => a.objectAttributeId === attributeType
            );
            if (inSource) {
                pickListAttributes.push({
                    objectAttributeInstanceId: inSource.objectAttributeInstanceId,
                    picklistItemId: value,
                    objectAttributeId: inSource.objectAttributeId,
                });
            } else {
                pickListAttributes.push({ objectAttributeId: attributeType, picklistItemId: value });
            }
        };
        this.setIfDefined(section, 'investranId', (v) => createOrUpdatePickList(AttributeType.investranId, v));
        this.setIfDefined(section, 'primaryLedgerId', (v) => createOrUpdatePickList(AttributeType.primaryLedgerId, v));
        this.setIfDefined(section, 'consolidatingStatusInOracleId', (v) =>
            createOrUpdatePickList(AttributeType.consolidatingStatusInOracleId, v)
        );
        this.setIfDefined(section, 'repaymentGroupId', (v) =>
            createOrUpdatePickList(AttributeType.repaymentGroupId, v)
        );
        this.setIfDefined(section, 'wssPaymentTypeId', (v) =>
            createOrUpdatePickList(AttributeType.wssPaymentTypeId, v)
        );
        this.setIfDefined(section, 'treasuryLineOfBusinessId', (v) =>
            createOrUpdatePickList(AttributeType.treasuryLineOfBusinessId, v)
        );
        this.setIfDefined(section, 'investranFundFamily', (v) =>
            createOrUpdatePickList(AttributeType.investranFundFamily, v)
        );
        this.setIfDefined(section, 'oracleGSO', (v) => createOrUpdatePickList(AttributeType.oracleGSO, v));

        this.setIfDefined(section, 'gpInterestType', (v) => createOrUpdatePickList(AttributeType.gpInterestType, v));

        this.setIfDefined(section, 'investranGPFundFamily', (v) =>
            createOrUpdateSystemPickList(AttributeType.investranGPFundFamily, v)
        );

        this.setIfDefined(section, 'gpEntityTier', (v) => createOrUpdatePickList(AttributeType.gpEntityTier, v));
        if (booleanAttributes.length > 0) entity.objectAttributeBooleans = booleanAttributes;
        if (relationAttributes.length > 0) entity.objectAttributeRelationships = relationAttributes;
        if (systemPickListAttributes.length > 0) entity.objectAttributeSystemPicklists = systemPickListAttributes;
        if (stringAttributes.length > 0) entity.objectAttributeStrings = stringAttributes;
        if (pickListAttributes.length > 0) entity.objectAttributePicklists = pickListAttributes;
        if (numericAttributes.length > 0) entity.objectAttributeNumerics = numericAttributes;

        return { entity };
    }

    private mapFromTaxClassification(
        source: LegalEntityDTO | undefined,
        section: LegalEntityTaxClassificationData
    ): RecursivePartial<LegalEntityDTO> {
        const toReturn = {} as RecursivePartial<LegalEntityDTO>;
        const legalEntity: Partial<LegalEntity> = {};
        const entity: RecursivePartial<Pick<Entity, 'objectAttributeBooleans'>> = {};
        const entityHierarchiesToDelete: LegalEntityDTO['removedEntityHierarchies'] = [];
        const entityHierarchies: RecursivePartial<EntityHierarchy>[] = [];

        const setEntityOId = (type: EntityHierarchyIdentifier, entityOId: number | undefined) => {
            const existingHierarchy = source?.entityHierarchies?.find(type.isOfType);
            if (isNullOrUndefined(entityOId)) {
                if (existingHierarchy?.entityHierarchyOId === undefined) return;
                const { entityHierarchyOId } = existingHierarchy;
                entityHierarchiesToDelete.push({ entityHierarchyOId });
                return;
            }

            let entityHierarchyUpdate: RecursivePartial<EntityHierarchy> = { [type.entityOIdField]: entityOId };
            if (!isNullOrUndefined(existingHierarchy)) {
                entityHierarchyUpdate.entityHierarchyOId = existingHierarchy.entityHierarchyOId;
                entityHierarchyUpdate.entityHierarchyTypeId = existingHierarchy.entityHierarchyTypeId;
                entityHierarchyUpdate.serviceProviderDetails = existingHierarchy.serviceProviderDetails;
            } else {
                entityHierarchyUpdate = type.generateHierarchy(entityHierarchyUpdate);
            }
            entityHierarchies.push(entityHierarchyUpdate);
        };

        let booleanAttributes: Partial<AttributeBoolean>[] = [];
        const createOrUpdateBoolean = (attributeType: AttributeType, value: boolean | undefined) => {
            const inSource = source?.entity.objectAttributeBooleans?.find((a) => a.objectAttributeId === attributeType);
            if (inSource) {
                booleanAttributes.push({
                    objectAttributeInstanceId: inSource.objectAttributeInstanceId,
                    value: value,
                    objectAttributeId: inSource.objectAttributeId,
                });
            } else {
                booleanAttributes.push({ objectAttributeId: attributeType, value: value });
            }
        };
        this.setIfDefined(section, 'crsClassificationId', (v) => (legalEntity.crsClassificationId = v));
        this.setIfDefined(
            section,
            'crsSubClassificationDetailId',
            (v) => (legalEntity.crsSubClassificationDetailId = v)
        );
        this.setIfDefined(section, 'crsSubClassificationId', (v) => (legalEntity.crsSubClassification = v));
        this.setIfDefined(section, 'fatcaClassificationId', (v) => (legalEntity.fatcaentityTypeId = v));
        this.setIfDefined(section, 'isCtbElectionApproved', (v) => (legalEntity.isCtbElectionApproved = v));
        this.setIfDefined(section, 'tax8832EffectiveDate', (v) => (legalEntity.tax8832EffectiveDate = v));
        this.setIfDefined(section, 'isGIINRequired', (v) => createOrUpdateBoolean(AttributeType.isGIINRequired, v));
        if (Object.keys(booleanAttributes)) entity.objectAttributeBooleans = booleanAttributes;
        this.setIfDefined(
            section,
            'usStateTaxClassificationTypeId',
            (v) => (legalEntity.usStateTaxClassificationTypeId = v)
        );
        this.setIfDefined(section, 'usTaxClassificationTypeId', (v) => (legalEntity.usTaxClassificationTypeId = v));
        this.setIfDefined(section, 'chapterThreeStatusId', (v) => (legalEntity.chapterThreeStatusId = v));
        this.setIfDefined(section, 'taxPreparerEntityOId', setEntityOId.bind(null, EntityHierarchyTypes.taxPreparer));

        toReturn.entity = { legalEntity, objectAttributeBooleans: entity.objectAttributeBooleans };

        if (entityHierarchies.length > 0) toReturn.entityHierarchies = entityHierarchies;
        if (entityHierarchiesToDelete.length > 0) toReturn.removedEntityHierarchies = entityHierarchiesToDelete;

        return toReturn;
    }

    private mapFromRegistration(
        source: LegalEntityDTO | undefined,
        section: Partial<LegalEntityRegistrationData>
    ): RecursivePartial<LegalEntityDTO> {
        const existingRegistrations = source?.entity.taxRegistrations ?? [];

        const existingReg = existingRegistrations.find((r) => r.taxRegistrationId === section.key);
        if (!existingReg) {
            console.warn(`could not find existing registration with id ${section.key}`, section);
            return {};
        }
        const regUpdate: Partial<TaxRegistration> = {};
        this.setIfDefined(section, 'registrationNumber', (v) => (regUpdate.formationNumber = v));
        this.setIfDefined(section, 'formationDate', (v) => (regUpdate.formationDate = v));
        if (Object.keys(regUpdate).length > 0) {
            regUpdate.taxRegistrationId = section.key;
            return { entity: { taxRegistrations: [regUpdate] } };
        }
        return {};
    }

    private mapFromOwnershipInformation(
        source: LegalEntityDTO | undefined,
        section: Partial<LegalEntityOwnershipInformationData>
    ): RecursivePartial<LegalEntityDTO> {
        const entityHierarchiesToDelete: LegalEntityDTO['removedEntityHierarchies'] = [];
        const entityHierarchyUpdates: (RecursivePartial<EntityHierarchy> &
            Pick<EntityHierarchy, 'entityHierarchyTypeId'>)[] = [];
        section.authorizedPerson?.forEach((personRow) => {
            let updateSignatory: Partial<EntityHierarchySignatoryDetail> = {};
            this.setIfDefined(personRow, 'appointmentDate', (v) => (updateSignatory.appointmentDate = v));
            this.setIfDefined(personRow, 'resignationDate', (v) => (updateSignatory.resignationDate = v));
            this.setIfDefined(personRow, 'title', (v) => (updateSignatory.signatoryBlockTitleId = v));

            if (personRow.key) {
                if (personRow.isDeleted) {
                    //if isDeleted flag is true, hierarchy was removed
                    let deleteHierarchy = { entityHierarchyOId: personRow.key.hierarchyId };
                    entityHierarchiesToDelete.push(deleteHierarchy);
                } else {
                    const { key } = personRow;
                    const originalHierarchy = source?.entityHierarchies?.find(
                        (h) => h.entityHierarchyOId === key.hierarchyId
                    );
                    if (originalHierarchy === undefined) {
                        console.error("couldn't find hierarchy to update", key);
                        return;
                    }

                    const originalSignatory = originalHierarchy.signatoryDetails?.find(
                        (s) => s.signatoryDetailId === key.signatoryId
                    );
                    if (!originalSignatory) {
                        console.error(`couldn't find signatoryDetail to update`, key);
                        return;
                    }
                    if (
                        isIn(personRow, 'name') &&
                        personRow.name !== undefined &&
                        personRow.name !== originalHierarchy.childEntityOId
                    ) {
                        // need to move to hierarchy
                        updateSignatory = Object.assign({}, originalSignatory, updateSignatory); //copy over all props from original, and overwrite with the updates
                        delete updateSignatory.signatoryDetailId; //denote that this is a new signatory
                        let targetHierarchy = entityHierarchyUpdates.find(
                            (h) => EntityHierarchyTypes.signatory.isOfType(h) && h.childEntityOId === personRow.name
                        );
                        if (!targetHierarchy) {
                            const sourceNewHierarchy = source!.entityHierarchies!.find(
                                (h) => EntityHierarchyTypes.signatory.isOfType(h) && h.childEntityOId === personRow.name
                            );
                            if (sourceNewHierarchy) {
                                targetHierarchy = EntityHierarchyTypes.signatory.generateHierarchy({
                                    childEntityOId: personRow.name,
                                    entityHierarchyOId: sourceNewHierarchy.entityHierarchyOId,
                                });
                            } else {
                                targetHierarchy = EntityHierarchyTypes.signatory.generateHierarchy({
                                    childEntityOId: personRow.name,
                                });
                            }
                            entityHierarchyUpdates.push(targetHierarchy);
                        }
                        targetHierarchy.signatoryDetails = targetHierarchy.signatoryDetails?.concat(
                            updateSignatory
                        ) ?? [updateSignatory];
                        let deleteHierarchy = entityHierarchiesToDelete.find(
                            (h) => h.entityHierarchyOId === key.hierarchyId
                        );
                        if (!deleteHierarchy) {
                            deleteHierarchy = { entityHierarchyOId: key.hierarchyId };
                            entityHierarchiesToDelete.push(deleteHierarchy);
                        }
                        deleteHierarchy.signatoryDetailIds = deleteHierarchy.signatoryDetailIds?.concat(
                            key.signatoryId
                        ) || [key.signatoryId];
                    } else if (Object.keys(updateSignatory).length > 0) {
                        let targetHierarchy = entityHierarchyUpdates.find(
                            (h) => h.entityHierarchyOId === key.hierarchyId
                        );
                        if (!targetHierarchy) {
                            targetHierarchy = EntityHierarchyTypes.signatory.generateHierarchy({
                                entityHierarchyOId: key.hierarchyId,
                            });
                            targetHierarchy.childEntityOId = originalHierarchy?.childEntityOId;
                            entityHierarchyUpdates.push(targetHierarchy);
                        }
                        updateSignatory.signatoryDetailId = key.signatoryId;
                        targetHierarchy.signatoryDetails = targetHierarchy.signatoryDetails?.concat(
                            updateSignatory
                        ) ?? [updateSignatory];
                    }
                }
            } else {
                if (isNullOrUndefined(personRow.name)) {
                    console.error("'name' was not set. Can't add to a hierarchy.", personRow);
                    return;
                }

                let updateHierarchy = entityHierarchyUpdates.find(
                    (h) => EntityHierarchyTypes.signatory.isOfType(h) && h.childEntityOId === personRow.name
                );
                if (!updateHierarchy) {
                    const existingHierarchy = source?.entityHierarchies?.find(
                        (h) => EntityHierarchyTypes.signatory.isOfType(h) && h.childEntityOId === personRow.name
                    );
                    if (existingHierarchy) {
                        updateHierarchy = EntityHierarchyTypes.signatory.generateHierarchy({
                            entityHierarchyOId: existingHierarchy.entityHierarchyOId,
                        });
                    } else {
                        updateHierarchy = EntityHierarchyTypes.signatory.generateHierarchy({
                            childEntityOId: personRow.name,
                        });
                    }
                    entityHierarchyUpdates.push(updateHierarchy);
                }
                updateHierarchy.signatoryDetails = updateHierarchy.signatoryDetails?.concat(updateSignatory) ?? [
                    updateSignatory,
                ];
            }
        });
        // check if any hierarchies need to be deleted for lack of signatories
        entityHierarchiesToDelete.forEach((pendingDelete) => {
            const existingSignatoryIds =
                source?.entityHierarchies
                    ?.find((h) => h.entityHierarchyOId === pendingDelete.entityHierarchyOId)
                    ?.signatoryDetails?.map((sig) => sig.signatoryDetailId) ?? [];
            const pendingDeleteSignatoryIds = pendingDelete.signatoryDetailIds ?? [];
            if (existingSignatoryIds.some((e) => !pendingDeleteSignatoryIds.includes(e))) return;
            delete pendingDelete.signatoryDetailIds;
        });

        section.ownershipDetails?.forEach((ownershipRow) => {
            const updateTaxOwnership: Partial<EntityHierarchyTaxDetail> = {};
            this.setIfDefined(ownershipRow, 'interestUnit', (v) => (updateTaxOwnership.interestUnitTypeId = v));
            this.setIfDefined(ownershipRow, 'interestClass', (v) => (updateTaxOwnership.interestClassTypeId = v));
            this.setIfDefined(ownershipRow, 'numberOfUnits', (v) => (updateTaxOwnership.numberOfUnits = v));
            this.setIfDefined(ownershipRow, 'ownerShip', (v) => (updateTaxOwnership.economicOwnership = v));
            this.setIfDefined(ownershipRow, 'reportingType', (v) => (updateTaxOwnership.reportingTypeId = v));
            this.setIfDefined(ownershipRow, 'interestType', (v) => (updateTaxOwnership.interestTypeId = v));
            this.setIfDefined(ownershipRow, 'votingRight', (v) => (updateTaxOwnership.votingRight = v));
            this.setIfDefined(ownershipRow, 'shareClass', (v) => (updateTaxOwnership.shareClass = v));
            this.setIfDefined(ownershipRow, 'nominalValue', (v) => (updateTaxOwnership.nominalValue = v));
            this.setIfDefined(ownershipRow, 'currency', (v) => (updateTaxOwnership.currency = v));
            const hierarchyToUpdate = EntityHierarchyTypes.legalOwnership.generateHierarchy({});
            this.setIfDefined(ownershipRow, 'parentEntity', (v) => (hierarchyToUpdate.parentEntityOId = v));
            this.setIfDefined(ownershipRow, 'ownershipName', (v) => (hierarchyToUpdate.ownershipName = v));
            if (!isNullOrUndefined(ownershipRow.key) && ownershipRow.isDeleted) {
                let deleteHierarchy = { entityHierarchyOId: ownershipRow.key };
                entityHierarchiesToDelete.push(deleteHierarchy);
            } else {
                if (Object.keys(updateTaxOwnership).length > 0) {
                    hierarchyToUpdate.taxOwnershipDetail = updateTaxOwnership;
                }
                if (Object.keys(hierarchyToUpdate).length === 0) return;
                if (!isNullOrUndefined(ownershipRow.key)) {
                    hierarchyToUpdate.entityHierarchyOId = ownershipRow.key;
                }
                entityHierarchyUpdates.push(hierarchyToUpdate);
            }
        });
        return {
            entity: {},
            entityHierarchies: entityHierarchyUpdates,
            removedEntityHierarchies: entityHierarchiesToDelete,
        };
    }

    private setIfDefined<S, K extends keyof S>(
        section: Partial<S>,
        key: K,
        action: (incomingValue: S[K] | undefined) => void
    ) {
        if (isIn(section, key)) action(section[key]);
    }

    private safeParseInt(str: string | undefined) {
        return parseInt(str as string) || undefined;
    }
}

export const entitySectionMapper = new EntityToSectionMapper();

export const emptyEntitySections: Readonly<entitySections> = {
    Details: {},
    LegalEntityInformation: {},
    DocumentPreparation: {},
    DomesticRegistration: {
        key: 0,
        country: -1,
        registeredAgent: -1,
        state: -1,
        entityOid: 0,
    },
    CorporateTransparency: {},
    ForeignRegistration: [],
    FinanceInformation: {},
    TaxClassification: {},
    OwnershipInformation: {
        domesticKey: 0,
        domesticCountry: 0,
        authorizedPerson: [],
        ownershipDetails: [],
        id: 0,
        legalType: 0,
    },
    DissolutionRequest: {},
    DissolutionLitigation: {},
    DissolutionTax: {},
    DissolutionFinance: {},
    DissolutionLegal: {},
    DissolutionTreasury: {},
    DissolutionBDG: {},
};

export type entitySections = {
    target?: LegalEntityDTO;
    Details: LegalEntityRequestData;
    LegalEntityInformation: LegalEntityInformationData;
    DocumentPreparation: LegalEntityDocumentPreparationData;
    DomesticRegistration: LegalEntityRegistrationData;
    CorporateTransparency: LegalEntityCorporateTransparencyData;
    ForeignRegistration: LegalEntityRegistrationData[];
    ForeignRegistrationUpdate?: LegalEntityRegistrationUpdateData;
    FinanceInformation: LegalEntityFinanceDetailsData;
    TaxClassification: LegalEntityTaxClassificationData;
    OwnershipInformation: LegalEntityOwnershipInformationData;
    DissolutionRequest: LegalEntityDissolutionRequestData;
    DissolutionLitigation: LegalEntityDissolutionLitigationData;
    DissolutionTax: LegalEntityDissolutionTaxData;
    DissolutionFinance: LegalEntityDissolutionFinanceData;
    DissolutionLegal: LegalEntityDissolutionLegalData;
    DissolutionTreasury: LegalEntityDissolutionTreasuryData;
    DissolutionBDG: LegalEntityDissolutionBDGData;
};
