import { Border, HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import { Button, StackPanel, TextBlock } from '@bxgrandcentral/controls';
import React, { Fragment } from 'react';

import AuthorizedPerson from './AuthorizedPerson';
import { Expander } from 'components';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { isEmpty } from 'Utilities/Validations';
import useAuthorizedPersonValidation from 'modules/LegalEntityCreation/validation/use-authorized-person-validation';
import useColors from 'api/hooks/use-theme';
import { useLegalEntityOwnerShipInformation } from 'modules/LegalEntityCreation/context/Provider';

export default function AuthorizedPersons() {
    const {
        state: {
            data: {
                values: { authorizedPerson = [] },
                noPendingValues,
            },
            isEditable,
        },
        setValue,
    } = useLegalEntityOwnerShipInformation();

    const { normalControlBorderColor } = useColors();

    function addAuthorizedPerson() {
        setValue('authorizedPerson', [...authorizedPerson.concat({ isDeleted: false })]);
    }

    const noPendingRegistrationData = noPendingValues?.authorizedPerson;
    const { validations } = useAuthorizedPersonValidation();

    return (
        <Expander
            header='Authorized Persons'
            requestStep={RequestStepsView.Ownership_Information}
            padding={0}
            content={
                <StackPanel itemGap={SPACING.XL}>
                    {isEmpty(authorizedPerson) ? (
                        <TextBlock styleName='captionStyle' padding={HORIZONTAL_FORM_PADDING}>
                            No authorized person added
                        </TextBlock>
                    ) : (
                        authorizedPerson.map((_, index) => (
                            <Fragment key={index}>
                                {index > 0 && <Border color={normalControlBorderColor} />}
                                <AuthorizedPerson
                                    index={index}
                                    validation={validations[index]}
                                    noPendingValues={noPendingRegistrationData?.[index]}
                                />
                            </Fragment>
                        ))
                    )}
                </StackPanel>
            }
            {...(isEditable && {
                footer: <Button content='Add Authorized Person' isEnabled={isEditable} onClick={addAuthorizedPerson} />,
            })}
        />
    );
}
