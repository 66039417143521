import { AdministeredBy as AdministeredByModel, KeyValueData } from '../../../../../models';
import { AdministeredByTooltip, StructureTypeTooltip } from 'modules/LegalEntityCreation/Shared/Tooltips';
import { BooleanField, DateTimeField, ElasticSearchField, Expander, Select, TextField } from 'components';
import { DoubleColumnLayout, SPACING } from '../../../../../Utilities/Layout';
import React, { useEffect, useMemo } from 'react';
import { isExternalAdmin, isPortfolioCompany } from '../../../utils';
import { isHedgeFund, shouldHaveSubBusinessUnit } from '../../../utils';

import AssociatedDealSelect from 'components/controls/AssociatedDealSelect/AssociatedDealSelect';
import { EntitySubType } from '../../../../../models/LegalEntity/EntitySubType';
import { FormControl } from '../../../../../components/controls/controls.styled';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { StackPanel } from '@bxgrandcentral/controls';
import { convertToLocalDate } from '../../../../../Utilities/date';
import { isEmpty } from 'lodash';
import { useLegalEntityRequest } from '../../../context/Provider';
import { useReferenceData } from 'api';

export default function LegalEntityDetails() {
    const {
        state: {
            isEditable,
            data: {
                values: { administeredBy, businessUnitOId, externalAdminOId, isBlueprintEntity, projectEntityOId },
                storedValues: { associatedDeal },
            },
        },
        getValue,
        setValue,
    } = useLegalEntityRequest();

    const {
        data: {
            AdministeredBy,
            AdministratorHKAPAC,
            BusinessUnit,
            ContactEmail,
            ExternalAdmin,
            LawFirm,
            LegalType,
            NewStructureType,
            Portfolio,
            SubBusinessUnit,
            TaxAuditor,
        },
    } = useReferenceData();

    const subBusinessUnits = useMemo(
        () => SubBusinessUnit?.filter(({ ParentId }) => ParentId === `${businessUnitOId}`),
        [SubBusinessUnit, businessUnitOId]
    );

    const structureTypes = useMemo(
        () =>
            businessUnitOId === KeyValueData.BTO
                ? NewStructureType
                : NewStructureType?.filter(({ Key }) => Key !== KeyValueData.Sleeve),
        [NewStructureType, businessUnitOId]
    );

    const externalAdminContacts = useMemo(() => {
        const externalAdmin = ExternalAdmin?.find(({ Key }) => Key === externalAdminOId);
        return externalAdmin ? ContactEmail?.filter(({ ParentId }) => `${externalAdmin.Key}` === ParentId) : [];
    }, [ContactEmail, ExternalAdmin, externalAdminOId]);

    const isExternalAdminSelected = isExternalAdmin(administeredBy);
    const isExternalAdminDropdownEnabled =
        isExternalAdmin(administeredBy) || administeredBy === AdministeredByModel.Asia;
    const isPortfolioCompanySelected = isPortfolioCompany(administeredBy);
    const isAdministratorHKAPACEnabled = isBlueprintEntity && administeredBy === AdministeredByModel.Asia;
    const isAssociatedDealEnabled = isBlueprintEntity && administeredBy === AdministeredByModel.Asia;

    useEffect(() => {
        if (!isAssociatedDealEnabled) {
            setValue('associatedDeal', undefined);
        }
    }, [isAssociatedDealEnabled, setValue]);

    const onBusinessUnitIdChanged = (value?: number) => {
        setValue('businessUnitOId', value);
        setValue('subBusinessUnitOId', undefined);
        setValue('projectEntityOId', undefined);
        setValue('hedgehogID', undefined);
    };

    const onAdministeredByChanged = (value?: number) => {
        setValue('administeredBy', value);
        setValue('externalAdminOId', undefined);
        setValue('administratorHKAPACOId', undefined);
    };

    const onExternalAdminIdChanged = (value?: number) => {
        setValue('externalAdminOId', value);
        setValue('externalAdminContact', undefined);
    };

    const onIsBlueprintEntityChanged = (value?: boolean) => {
        setValue('isBlueprintEntity', value);
        setValue('isOrderChop', value ? false : undefined);
        setValue('isOrderSeal', value ? false : undefined);
        setValue('remarks', undefined);
        setValue('financialYearEndDate', undefined);
        setValue('administratorHKAPACOId', undefined);
    };

    const onFinancialYearEndDateChanged = (value?: Date | string | undefined) => {
        setValue('financialYearEndDate', convertToLocalDate(value));
    };

    return (
        <Expander
            header='Legal Entity Details'
            requestStep={RequestStepsView.Request}
            content={
                <DoubleColumnLayout>
                    <StackPanel itemGap={SPACING.SM}>
                        <Select
                            label='Business Unit'
                            labelToolTip={{ component: "Legal entity's business unit that governs the entity" }}
                            itemsSource={BusinessUnit}
                            {...getValue('businessUnitOId')}
                            isRequired
                            showSearchBox
                            onValueChanged={onBusinessUnitIdChanged}
                        />
                        {!isEmpty(subBusinessUnits) && (
                            <Select
                                label='Sub-Business Unit'
                                labelToolTip={{
                                    component:
                                        "Legal entity's sub-business unit if BU = Credit, Hedge Fund Solutions, Real Estate, and General Partners Accounting",
                                }}
                                showSearchBox
                                itemsSource={subBusinessUnits}
                                isRequired={shouldHaveSubBusinessUnit(businessUnitOId)}
                                {...getValue('subBusinessUnitOId')}
                            />
                        )}
                        <FormControl className='form'>
                            <ElasticSearchField
                                label='Project'
                                labelToolTip="Apologies for the inconvenience. 'Project' is currently unavailable. If you need to tag a project to the entity, please contact Data Governance for assistance."
                                isEnabled={false}
                                searchType={EntitySubType.Project}
                                restrictTo={{
                                    businessUnitIds: !businessUnitOId ? undefined : [businessUnitOId],
                                }}
                                selectedEntityOId={projectEntityOId}
                                onEntitySelected={(result) => setValue('projectEntityOId', result?.entityOId)}
                                isReadOnly={!isEditable || !businessUnitOId}
                            />
                        </FormControl>
                        {isHedgeFund(businessUnitOId) && (
                            <TextField
                                label='Hedgehog ID'
                                labelToolTip='ID that will go into Hedgehog if BU = Hedge Fund Solutions'
                                {...getValue('hedgehogID')}
                            />
                        )}
                        <BooleanField
                            label='Is Blueprint Entity'
                            labelToolTip={{
                                component: 'Indicates whether entity exists in Blueprint. Will create if not',
                            }}
                            {...getValue('isBlueprintEntity')}
                            onValueChanged={(newValue) => onIsBlueprintEntityChanged(newValue)}
                        />
                        {isBlueprintEntity && (
                            <>
                                <BooleanField
                                    label='Order chop'
                                    labelToolTip={{
                                        component: 'Has a registered Chop been requested',
                                    }}
                                    {...getValue('isOrderChop')}
                                />
                                <BooleanField
                                    label='Order Seal'
                                    labelToolTip={{
                                        component: 'Has a registered Seal been requested',
                                    }}
                                    {...getValue('isOrderSeal')}
                                />
                                <TextField
                                    label='Remarks'
                                    labelToolTip='Commentary on Chop or Seal registration and location'
                                    {...getValue('remarks')}
                                />
                                <DateTimeField
                                    labelToolTip='Ending date of financial year of an entity'
                                    label='Financial Year End (FYE Date)'
                                    {...getValue('financialYearEndDate')}
                                    onValueChanged={onFinancialYearEndDateChanged}
                                />
                            </>
                        )}
                        <BooleanField
                            label='Is Disregarded Entity'
                            labelToolTip={{
                                component:
                                    "A disregarded entity is a business with a single owner that is not separate from the owner for federal income tax purposes. This means taxes owed by this type of business are paid as part of the owner's income tax return.",
                                options: { showDuration: 10000 },
                            }}
                            {...getValue('isDisregardedEntity')}
                        />
                    </StackPanel>
                    <StackPanel itemGap={SPACING.SM}>
                        <Select
                            label='Legal Type'
                            labelToolTip={{ component: 'Business legal structure of the entity' }}
                            itemsSource={LegalType}
                            isRequired
                            showSearchBox
                            {...getValue('legalTypeId')}
                        />
                        <Select
                            label='Structure Type'
                            labelToolTip={{ component: StructureTypeTooltip(), options: { showDuration: 60000 } }}
                            itemsSource={structureTypes}
                            isRequired
                            showSearchBox
                            {...getValue('structureType')}
                        />
                        <Select
                            label='Administered / Managed By'
                            labelToolTip={{ component: AdministeredByTooltip() }}
                            itemsSource={AdministeredBy}
                            {...getValue('administeredBy')}
                            isRequired
                            onValueChanged={onAdministeredByChanged}
                        />
                        {isAdministratorHKAPACEnabled && (
                            <Select
                                label='Administrator - HK APAC'
                                itemsSource={AdministratorHKAPAC}
                                {...getValue('administratorHKAPACOId')}
                            />
                        )}
                        {isExternalAdminDropdownEnabled && (
                            <Select
                                label='External Admin'
                                labelToolTip={{
                                    component:
                                        'When the entity is managed by an external financial institution and is not a BX Entity',
                                }}
                                itemsSource={ExternalAdmin}
                                {...getValue('externalAdminOId')}
                                isRequired={isExternalAdminSelected}
                                onValueChanged={onExternalAdminIdChanged}
                            />
                        )}
                        {isPortfolioCompanySelected && (
                            <Select
                                label='External Admin'
                                labelToolTip={{
                                    component:
                                        'When the entity is managed by an external financial institution and is not a BX Entity',
                                }}
                                itemsSource={Portfolio}
                                {...getValue('portfolioId')}
                            />
                        )}
                        {isExternalAdminSelected ||
                            (isPortfolioCompanySelected && (
                                <Select
                                    label='External Admin-Contact'
                                    itemsSource={externalAdminContacts}
                                    {...getValue('externalAdminContact')}
                                    isEditable={isEditable && !!externalAdminOId}
                                />
                            ))}
                        {isAssociatedDealEnabled && (
                            <AssociatedDealSelect
                                label='Associated Deal'
                                {...getValue('associatedDeal')}
                                isReadOnly={!isEditable}
                                storedValue={associatedDeal}
                            />
                        )}
                        <Select
                            label='Law Firm'
                            labelToolTip={{ component: 'Law Firm representing the legal entity' }}
                            itemsSource={LawFirm}
                            showSearchBox
                            {...getValue('lawFirmEntityOId')}
                            isRequired={!isExternalAdmin(administeredBy)}
                        />
                        <Select
                            label='Tax Auditor'
                            labelToolTip={{ component: 'Select third party tax auditor' }}
                            itemsSource={TaxAuditor}
                            showSearchBox
                            {...getValue('taxAuditorOId')}
                        />
                    </StackPanel>
                </DoubleColumnLayout>
            }
        />
    );
}
